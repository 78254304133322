import React, { useEffect, useState } from 'react'
import { Modal, Form } from 'antd'

import moment from 'moment'
import { useRecoilValue } from 'recoil'

import { RenderSchema } from '../FormRenderer';
import { check, Loader, CardHeader, PortalNotification } from '../../helpers/Functions'
import { firestore } from '../../firebase/firebaseConfig';

import { DriverFormSchema } from '../FormSchemas'
import '../../App.css'
import { baseLocationsAtom, activeBusinessCodeAtom } from '../../recoil/atoms';

const DriverModal = (props) => {
    const { visible, action, data } = props.driverModal

    const [form] = Form.useForm()
    const baseLocations = useRecoilValue(baseLocationsAtom)
    const activeBusinessCode = useRecoilValue(activeBusinessCodeAtom)

    const [loading, setLoading] = useState(false)

    useEffect(()=> {
        if(visible && Object.values(data).length>0) {
            form.setFieldsValue({
                firstName: data.profile.firstName,
                lastName: data.profile.lastName,
                phoneNumber: parseInt(data.primaryPhoneNumber.replace('+92', '')),
                vehicleCategory: [data.vehicle.vehicleType, data.vehicle.vehicleSubtype],
                vehicleVariant: data.vehicle.vehicleVariant,
                manufacturerName: data.vehicle.manufacturerName,
                registrationNumber : data.vehicle.registrationNumber,
                baseLocation: data.baseLocationId
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.driverModal])
    
    const onFinish = async (values) => {
        setLoading(true)

        values.phoneNumber=`+92${values.phoneNumber}`
        // console.log({values})

        if(action === 'add') {
            const createDriverQuery = await fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/createUserWithPhoneNumber`, {
                                            method: 'POST',
                                            headers: { 'Content-Type': 'application/json;charset=utf-8' },
                                            body: JSON.stringify({ phoneNumber: values.phoneNumber})
                                        })
            const createDriverQueryResponse = await createDriverQuery.json()
            // console.log(createDriverQueryResponse)
            if (createDriverQueryResponse.code!==undefined && createDriverQueryResponse.code === "auth/phone-number-already-exists") {
                PortalNotification('error', 'This phone number is already registered. Kindly contact BridgeLinx support.', 'Error')
            } else {
                const batch = firestore.batch()

                const VehicleDocument = await firestore.collection('vehicles').doc()
                const DriverDocument = await firestore.collection('drivers').doc()

                const ProfileObject = {
                    profileId: createDriverQueryResponse.uid,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumber: values.phoneNumber,
                    joiningDate: moment().format('x'),
                    userType: 'driver',
                    driverId: DriverDocument.id,
                }

                const DriverObject = {
                    _id: DriverDocument.id,
                    baseLocationId: values.baseLocation,
                    vehicleId: VehicleDocument.id,
                    cashInHand: '0',
                    cashLimit: '1000000',
                    currentJob: null,
                    createdAt: moment().format('x'),
                    driverBlocked: false,
                    driverType: 'corporate',
                    isOnline: true,
                    primaryPhoneNumber: values.phoneNumber,
                    profileInfo: createDriverQueryResponse.uid,
                    canHandleCash: true,
                    businessCode: activeBusinessCode,
                    onBoardingDate: moment().format('YYYY-MM-DD'),
                }

                const VehicleObject = {
                    _id: VehicleDocument.id,
                    manufacturerName: values.manufacturerName,
                    registrationNumber: values.registrationNumber,
                    vehicleVariant: values.vehicleVariant,
                    vehicleType: values.vehicleCategory[0],
                    vehicleSubtype: values.vehicleCategory[1] ? values.vehicleCategory[1] : '',
                    businessCode: activeBusinessCode,
                    createdAt: moment().format('x')
                }

                const ProfileDocRef = firestore.collection('profiles').doc(createDriverQueryResponse.uid)
                const DriverDocRef = firestore.collection('drivers').doc(DriverDocument.id)
                const VehicleDocRef = firestore.collection('vehicles').doc(VehicleDocument.id)
                
                batch.set(ProfileDocRef, {...ProfileObject} , {merge:true})
                batch.set(DriverDocRef, {...DriverObject} , {merge:true})
                batch.set(VehicleDocRef, {...VehicleObject} , {merge:true})

                // console.log({ProfileObject})
                // console.log({DriverObject})
                // console.log({VehicleObject})
                await batch.commit()

                PortalNotification('success', 'Driver and Vehicle Added', 'Success')
                onCancel()
            }
        } else if(action === 'edit' && check(data) && Object.values(data).length>0) {
            const batch = firestore.batch()

            const ProfileObject = {
                firstName: values.firstName,
                lastName: values.lastName,
            }

            const DriverObject = {
                baseLocationId: values.baseLocation,
            }

            const VehicleObject = {
                manufacturerName: values.manufacturerName,
                registrationNumber: values.registrationNumber,
                vehicleVariant: values.vehicleVariant,
                vehicleType: values.vehicleCategory[0],
                vehicleSubtype: values.vehicleCategory[1] ? values.vehicleCategory[1] : '',
                createdAt: moment().format('x')
            }

            const ProfileDocRef = firestore.collection('profiles').doc(data.profileInfo)
            const DriverDocRef = firestore.collection('drivers').doc(data.id)
            const VehicleDocRef = firestore.collection('vehicles').doc(data.vehicleId)

            batch.set(ProfileDocRef, {...ProfileObject} , {merge:true})
            batch.set(DriverDocRef, {...DriverObject} , {merge:true})
            batch.set(VehicleDocRef, {...VehicleObject} , {merge:true})

            // console.log({ProfileObject, DriverObject, VehicleObject})
            await batch.commit()
        }
        
        setLoading(false)
        onCancel()
    }

    const onCancel = async () => {
        form.resetFields()
        props.setDriverModal({...props.driverModal, visible: false})
    }

    return (
        <Modal
            key={`Modal_${action}Driver`}
            visible= {visible}
            title= {CardHeader(action==='add' ? 'Register New Driver' : 'Edit Driver Details', action==='add' ? 'Add new driver and vehicle.' : 'Edit existing driver and vehicle details.')}
            closable={false}
            className='SquareModal'
            okText={'SUBMIT'}
            cancelText='CANCEL'
            okButtonProps={{className: 'Button ModalFooterButton SubmitButton', onClick: () => form.submit()}}
            cancelButtonProps={{className: 'Button ModalFooterButton CancelButton', onClick: onCancel}}
            getContainer={false}
        >
            {loading && <Loader/>}
            <div className='Padding'>
                <Form
                    key={`Form_${action}Driver`}
                    layout={'vertical'}
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstErrors
                >
                    {RenderSchema(DriverFormSchema({baseLocations: baseLocations}))}
                </Form>
            </div>
        </Modal>
    )
}

export default DriverModal