import React, { useState, useEffect } from 'react'
import { Table, Layout, Button } from 'antd'
import { firestore } from '../firebase/firebaseConfig'

import SearchBar from '../components/SearchBar'
import DriverModal from '../components/Drivers/DriverModal'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import {dynamicTableSelector} from '../recoil/selectors'

import { check, Loader, SidebarEnum, CurrentCollection} from './Functions'
import { loaderAtom, activeBusinessCodeAtom, localOrdersAtom, importOrdersAtom, exportOrdersAtom, longHaulOrdersAtom, driversAtom } from '../recoil/atoms'

import {OrderNumber, LocalLocation, LongHaulLocation, LocalDriverDetails, VehicleDetails, LocalJobStatus, ImportExportLocation, ImportExportDriver, ImportExportContainer, ImportExportStatus, DriversDetails, DriversStatus, ShipmentDetails, VehicleCategory} from '../helpers/TableSchema'

import Sprite from '../assets/icons/Sprite.svg'

const { Header, Content } = Layout

const DynamicTable = (props)  => {
	const [filterData, setFilterData] = useState({data: [], collection: ''})
	const collection = CurrentCollection(props.location.pathname)

    const loader = useRecoilValue(loaderAtom)
	const collectionData = useRecoilValue(dynamicTableSelector(collection))
	const activeBusinessCode = useRecoilValue(activeBusinessCodeAtom)

	const setLocalOrdersAtom = useSetRecoilState(localOrdersAtom)
	const setImportOrdersAtom = useSetRecoilState(importOrdersAtom)
	const setExportOrdersAtom = useSetRecoilState(exportOrdersAtom)
	const setLongHaulOrdersAtom = useSetRecoilState(longHaulOrdersAtom)
	const setDriversAtom = useSetRecoilState(driversAtom)

	const [driverModal, setDriverModal] = useState({visible: false, action: 'add', data: {}})

	const LocalOrderColumns = [OrderNumber('orders'), LocalLocation('pickup'), LocalLocation('dropoff'), LocalDriverDetails, VehicleDetails('orders'), LocalJobStatus]
	const ImportOrderColumns = [OrderNumber('imports'), ImportExportLocation('imports', 'pickup'), ImportExportLocation('imports', 'dropoff'), ImportExportDriver, ImportExportContainer, ImportExportStatus]
	const ExportOrderColumns = [OrderNumber('exports'), ImportExportLocation('exports', 'pickup'), ImportExportLocation('exports', 'dropoff'), ImportExportDriver, ImportExportStatus]
	const LongHaulOrderColumns = [OrderNumber('bulk'), LongHaulLocation('pickup'), LongHaulLocation('dropoff'), ImportExportDriver, ShipmentDetails, ImportExportStatus]
	const DriversColumns = [DriversDetails(setDriverModal), VehicleDetails('drivers'), DriversStatus]
	const SubOrderColumns = [OrderNumber(collection), ImportExportDriver, ImportExportContainer, ImportExportStatus]
	const LongHaulSubOrderColumns = [OrderNumber(collection), ImportExportDriver, ShipmentDetails, VehicleCategory, ImportExportStatus]
	
	useEffect(() => {
		if (collection==='drivers') {
			const unsubscribe = firestore.collection('drivers').where('businessCode', '==', activeBusinessCode).onSnapshot(querySnapshot => {
				const DriversData = querySnapshot.docs.map( doc => ({...doc.data()}) )
				setDriversAtom(DriversData)
			}, err => { console.log(`Encountered error: ${err}`) })
			return () => unsubscribe()
		} else if (collection==='orders') {
			const unsubscribe = firestore.collection('jobs').where('businessCode', '==', activeBusinessCode).orderBy('orderNumber', 'desc').limit(1000).onSnapshot(querySnapshot => {
				const LocalOrdersData = querySnapshot.docs.map( doc => ({...doc.data()}) )
				setLocalOrdersAtom(LocalOrdersData)
			}, err => { console.log(`Encountered error: ${err}`) })
			return () => unsubscribe()
		} else if (collection==='imports') {
			const unsubscribe = firestore.collection('import_export').where('businessCode', '==', activeBusinessCode).where('type', '==', 'import').where('createdAt', '>', '1638298800000').onSnapshot(querySnapshot => {
				const ImportOrdersData = querySnapshot.docs.map( doc => ({...doc.data()}) )
				GetPendingOrders('import').then(pendingImportOrders => {
					const MergedOrders = [...pendingImportOrders, ...ImportOrdersData]
					const UniqueOrders = MergedOrders?.filter((item, index, array) => array.findIndex(item2 => item2.orderNumber===item.orderNumber && item2.subOrderNumber===item.subOrderNumber) === index ) || []
					setImportOrdersAtom(UniqueOrders)
				})
			}, err => { console.log(`Encountered error: ${err}`) })
			return () => unsubscribe()
		} else if (collection==='exports') {
			const unsubscribe = firestore.collection('import_export').where('businessCode', '==', activeBusinessCode).where('type', '==', 'export').where('createdAt', '>', '1638298800000').onSnapshot(querySnapshot => {
				const ExportOrdersData = querySnapshot.docs.map( doc => ({...doc.data()}) )
				setExportOrdersAtom(ExportOrdersData)
			}, err => { console.log(`Encountered error: ${err}`) })
			return () => unsubscribe()
		} else if (collection==='bulk') {
			const unsubscribe = firestore.collection('long_haul').where('businessCode', '==', activeBusinessCode).where('createdAt', '>', '1638298800000').onSnapshot(querySnapshot => {
				const LongHaulOrdersData = querySnapshot.docs.map( doc => ({...doc.data()}) )
				GetPendingBulkOrders().then(pendingOrders => {
					const MergedOrders = [...pendingOrders, ...LongHaulOrdersData]
					const UniqueOrders = MergedOrders?.filter((item, index, array) => array.findIndex(item2 => item2?.orderNumber===item?.orderNumber && item2.subOrderNumber===item.subOrderNumber) === index ) || []
					setLongHaulOrdersAtom(UniqueOrders)
				})
			}, err => { console.log(`Encountered error: ${err}`) })
			return () => unsubscribe()
		} 
	},[props.location.pathname, activeBusinessCode]) // eslint-disable-line

	useEffect(() => {
		if (collection==='imports') {
			const unsubscribe = firestore.collection('long_haul').where('businessCode', '==', activeBusinessCode).where('specialRequests.orderCategory', 'in', ['shifting', 'destuffing']).onSnapshot(querySnapshot => {
				const LongHaulOrdersData = querySnapshot.docs.map( doc => ({...doc.data()}) )
				setLongHaulOrdersAtom(LongHaulOrdersData)
			}, err => { console.log(`Encountered error: ${err}`) })
			return () => unsubscribe()
		} 
	},[props.location.pathname, activeBusinessCode]) // eslint-disable-line

	const GetPendingOrders = async (orderType) => await firestore.collection('pending_import_export').where('businessCode', '==', activeBusinessCode).where('type', '==', orderType).where('orderStatus.status', '==', 'orderPending').get().then(data => data.docs.map(doc=>({...doc.data()})))
	const GetPendingBulkOrders = async () => await firestore.collection('pending_long_haul').where('businessCode', '==', activeBusinessCode).where('orderStatus.status', '==', 'orderPending').where('specialRequests.lotOrder', '==', 'yes').get().then(data => data.docs.map(doc=>({...doc.data()})))

	const TableColumnsEnum = { 
		orders: LocalOrderColumns,
		imports: ImportOrderColumns,
		bulk: LongHaulOrderColumns,
		exports: ExportOrderColumns,
		drivers: DriversColumns,
	}

	const FilterProperties = {
		orders: ['orderNumber', ['driver','profile','firstName'], ['driver', 'profile', 'lastName'], ['driver', 'primaryPhoneNumber']],
		imports: ['orderNumber'],
		bulk: ['orderNumber'],
		exports: ['orderNumber'],
		drivers: ['primaryPhoneNumber'],
	}

	const LocalOrderExpandable = (record) => {
		const SortedPickups = Object.values(record.clientCoordinates.pickUp).sort((a,b) => a.number - b.number)
		const SortedDropoffs = Object.values(record.clientCoordinates.dropOff).sort((a,b) => a.number - b.number)
		return 	<>
					<div className='HalfMarginBottom'>
						<p className='BoldFont'>Pickup Details</p>
						{	check(SortedPickups) && SortedPickups.map( (location, index) => LocationDataRow(index, location) )}
					</div>
					<div>
						<p className='BoldFont'>Dropoff Details</p>
						{	check(SortedDropoffs) && SortedDropoffs.map( (location, index) => LocationDataRow(index, location) )}
					</div>
				</>
	}

	const ImportExportOrderExpandable = (record) => {
		if(record.subOrders) {
			return	<Table
						columns={collection==='bulk' ? LongHaulSubOrderColumns:SubOrderColumns}
						dataSource={record.subOrders}
						pagination={false}
						expandable={{expandedRowRender: record => ImportExportOrderExpandable(record)}}
						rowKey={record => record._id}
						size='small'
						bordered
					/>
		} else {
			const SortedLoadings = Object.values(record.loading).sort((a,b) => a.number - b.number)
			const SortedDropoffs = Object.values(record.dropOff).sort((a,b) => a.number - b.number)
			return 	<>
						<div className='HalfMarginBottom'>
							<p className='BoldFont'>Loading Details</p>
							{	check(SortedLoadings) && SortedLoadings.map( (location, index) => LocationDataRow(index, location) )}
						</div>
						<div>
							<p className='BoldFont'>Dropoff Details</p>
							{	check(SortedDropoffs) && SortedDropoffs.map( (location, index) => LocationDataRow(index, location) )}
						</div>
					</>
		}
	}

	// const LongHaulOrderExpandable = (record) => {
	// 	if(record.subOrders) {
	// 		return	<Table
	// 					columns={SubOrderColumns}
	// 					dataSource={record.subOrders}
	// 					pagination={false}
	// 					expandable={{expandedRowRender: record => ImportExportOrderExpandable(record)}}
	// 					rowKey={record => record._id}
	// 					size='small'
	// 					bordered
	// 				/>
	// 	} else {
	// 		const SortedLoadings = Object.values(record.loading).sort((a,b) => a.number - b.number)
	// 		const SortedDropoffs = Object.values(record.dropOff).sort((a,b) => a.number - b.number)
	// 		return 	<>
	// 					<div className='HalfMarginBottom'>
	// 						<p className='BoldFont'>Loading Details</p>
	// 						{	check(SortedLoadings) && SortedLoadings.map( (location, index) => LocationDataRow(index, location) )}
	// 					</div>
	// 					<div>
	// 						<p className='BoldFont'>Dropoff Details</p>
	// 						{	check(SortedDropoffs) && SortedDropoffs.map( (location, index) => LocationDataRow(index, location) )}
	// 					</div>
	// 				</>
	// 	}
	// }

	const LocationDataRow = (index, location) => (
		<div className='FlexRow ExpandedRow'>
			<p className='ThreeFourthWidth'>{location.formattedAddress || 'To be provided.'}</p>
			<div className='QuarterWidth FlexRowSpace'>
				{	location.contactName &&
					<div className='FlexRow HalfWidth HalfMarginRight'>
						<svg className='MiniIcon HalfMarginRight'><use href={Sprite + "#Avatar"}/></svg>
						<p className='RegularFont'>{location.contactName}</p>
					</div>
				}
				{	location.contactPhone &&
					<div className='FlexRow HalfWidth'>
						<svg className='MiniIcon HalfMarginRight'><use href={Sprite + "#Phone"}/></svg>
						<p className='RegularFont'>{location.contactPhone}</p>
					</div>
				}
			</div>
		</div>
	)

	return (
        <Layout>
			<Header>
				<p className='Heading Capitalize'>{collection && SidebarEnum[collection].name}</p>
                {
					['orders', 'imports', 'exports', 'bulk'].includes(collection) ? 
					<Button className='Button PrimaryButton' onClick={() => props.history.push(`/${collection}/create`)}> Create Order </Button> :
					<Button className='Button PrimaryButton' onClick={() => setDriverModal({visible: true, action: 'add', data: {}})}> Add Driver </Button>
				}
			</Header>
			{loader ? <Loader/> :
				<Content>
					<SearchBar
						data={collectionData} 
						collection = {collection}
						setFilterData={setFilterData} 
						filterProperty={check(FilterProperties[collection]) ? FilterProperties[collection] : ''}
					/>
					{	collection===filterData.collection &&
						<Table
							columns={TableColumnsEnum[collection]}
							dataSource={filterData.data}
							pagination={{ position:['bottomCenter'] }}
							expandable={ ['orders', 'imports', 'exports', 'bulk'].includes(collection) && {
								expandedRowRender: record => collection==='orders' ? LocalOrderExpandable(record) : ImportExportOrderExpandable(record)
							}}
							rowKey={record => record._id}
							bordered
						/>
					}
					{	collection==='drivers' &&
						<DriverModal
							driverModal = {driverModal}
							setDriverModal={setDriverModal}
							user={props.user}
						/>
					}
				</Content>
			}
		</Layout>
	)
}

export default DynamicTable