import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Steps } from 'antd'

import { FormRender } from './FormRenderer';
import { CardHeader, ContainerDimensionsEnum} from '../helpers/Functions'

import { RenderSchema } from './FormRenderer';

import { ContainerDetailsSchema } from './FormSchemas'

import '../App.css'
import Sprite from '../assets/icons/Sprite.svg'

const ContainerModal = (props) => {
    const { data, setdata, targetIndex, action, totalSteps, deleteOption, collection } = props
    const [form] = Form.useForm()
    const [currentStep, setCurrentStep] = useState(0)
    const [stepsData, setStepsData] = useState({})

    useEffect(()=> {
        if(props.viewModal===true && action==='edit' && data[targetIndex]) {
            form.setFieldsValue(data[targetIndex])
            setStepsData(data[targetIndex])
        }
    }, [props.viewModal]) // eslint-disable-line

    const StepMaterial = [
        {
            title: 'Select Container Dimensions',
            content:    
                FormRender(
                    {
                        type: 'radioGroup',
                        name: 'containerDimensions',
                        required: true,
                        message: 'Please select a vehicle type.',
                        dataObject: ContainerDimensionsEnum,
                        radioButtonView: true
                    },
                    'edit',
                    'containerDimensions'
                )
        },
        {
            title: 'Add Container(s) Information',
            content: RenderSchema(ContainerDetailsSchema({selectedDimension: stepsData?.containerDimensions, type: collection}))
        }
    ]
    
    const onFinish = (values) => {
        const NewStepsData = {...stepsData, ...values}
        setStepsData(NewStepsData)
        if(currentStep<totalSteps-1) {
            setCurrentStep(prevState => prevState + 1)
        } else {
            const DataCopy = [...data]
            DataCopy.splice(targetIndex ?? data.length, 1, NewStepsData)
            setdata(DataCopy)
            // console.log(NewStepsData)
            onCancel()
        }
        
        // DataCopy.splice(currentStep, 1, NewData)
        // if(currentStep<totalSteps-1 && VehiclesEnum[form.getFieldValue('vehicleType')]) {
        //     if(action==='edit' && DataCopy[0].vehicleType !== stepsData[0].vehicleType) {
        //         // console.log(DataCopy[0], stepsData[0])
        //         form.setFieldsValue({'vehicleSubtype': ''})
        //     }
        //     setStepsData(DataCopy)
        //     setCurrentStep(prevState => prevState + 1)
        // } else {
        //     if(type==='vehicle' && DataCopy[0].vehicleType==='superx') {
        //         DataCopy.splice(1,1)
        //     }
        //     setdata(DataCopy)
        //     onCancel()
        // }
    }

    const onCancel = async () => {
        props.setViewModal(false)
        setCurrentStep(0)
        setStepsData({})
        form.resetFields()
    }

    const onDelete = () => {
        const DataCopy = [...data]
        DataCopy.splice(targetIndex, 1)
        setdata(DataCopy)
        onCancel()
    }

    const ModalFooter = () => (
		<div className='FullWidth FlexRowSpace'>
            <div className='FlexRow'>
                {   deleteOption &&
                    <Button
                        className='FullWidthHeight Button DeleteButton CancelButton FlexRowBox' 
                        icon={<svg className='MiniIcon' style={{fill: '#F44336'}}><use href={Sprite + '#Delete'}/></svg>}
                        onClick={onDelete}
                    ></Button>
                }
                {   currentStep > 0 ?
                    <Button className='Button ModalFooterButton PreviousButton' onClick={() => setCurrentStep(prevState => prevState - 1)}>BACK</Button> :
                    <div></div>
                }
            </div>
            <div>
                <Button className='Button ModalFooterButton CancelButton' onClick={() => onCancel()}> CANCEL </Button>
                {   currentStep!==totalSteps-1 ?
                    <Button className='Button ModalFooterButton PrimaryButton' onClick={() => form.submit()}> NEXT </Button> : 
                    <Button className='Button ModalFooterButton SubmitButton' onClick={() => form.submit()}> SUBMIT </Button>
                }
            </div>
        </div>
    )

    return (
        <Modal
            visible= {props.viewModal}
            title= {CardHeader("Add Container Specifics", 'Add Information of single container')}
            footer={<ModalFooter/>}
            closable={false}
            className='StepsModal'
        >
            <>
                <Steps
                    current={currentStep}
                    size='small'
                >
                    {StepMaterial.map(item => (
                        <Steps.Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <div div className='Padding OverflowScroll'>
                    <Form
                        layout={'vertical'}
                        form={form}
                        onFinish={onFinish}
                    >
                        {StepMaterial[currentStep].content}
                    </Form>
                </div>
            </>
        </Modal>
    )
}

export default ContainerModal