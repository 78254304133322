import React, { useEffect, useState } from 'react'
import { Timeline, Layout, Card,Divider, Collapse, Row, Col, Image, Statistic, Button } from 'antd'
import { Breadcrumb } from 'antd'
import moment from 'moment'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { firestore } from '../../firebase/firebaseConfig'

import { useRecoilState, useRecoilValue } from "recoil"

import { CaretRightOutlined, FileTwoTone } from '@ant-design/icons'
import { check, Loader, CurrentPageId, CurrentCollection, ExportOrderStatusEnum, ReadableString, CardHeader, ContainerDimensionsEnum, IconDescription, BoundsZoomLevel, VehiclesEnum, OrderCategoryEnum } from '../../helpers/Functions'
import { exportOrdersAtom, importOrdersAtom, loaderAtom } from '../../recoil/atoms'

import { importExportSingleOrderSelector } from '../../recoil/selectors';

import Sprite from '../../assets/icons/Sprite.svg'

const { Header, Content, Sider } = Layout

const ViewExportOrder = (props)  => {
    const JobId = CurrentPageId(props.location.pathname)
	const collection = CurrentCollection(props.location.pathname)
    const loader = useRecoilValue(loaderAtom)

    const JobData = useRecoilValue(importExportSingleOrderSelector({collection: collection, job: JobId}))
    const [importOrders, setImportOrdersAtom] = useRecoilState(importOrdersAtom)
    const [exportOrders, setExportOrdersAtom] = useRecoilState(exportOrdersAtom)

	const [loadingLocations, setLoadingLocations] = useState([])
	const [dropoffLocations, setDropoffLocations] = useState([])
    const [terminal, setTerminal] = useState()
    const [containerInfo, setContainerInfo] = useState([])

    const bounds = new window.google.maps.LatLngBounds()
	const [boundsCenter, setBoundsCenter] = useState({lat: 31.5204489, lng : 74.3586184, zoomlevel: 10})

    useEffect ( ()=> {
        const unsubscribe = firestore.collection('import_export').doc(JobId).onSnapshot(querySnapshot => {
            if(querySnapshot.data()) {
                const AtomCopy = collection==='imports' ? [...importOrders] : [...exportOrders]
                const IndexToReplace = AtomCopy.findIndex(order=> order._id===JobId)
                AtomCopy[IndexToReplace] = querySnapshot.data()
                collection==='imports' ? setImportOrdersAtom(AtomCopy) : setExportOrdersAtom(AtomCopy)
            }
        }, err => { console.log(`Encountered error: ${err}`) })
        return () => unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
	useEffect ( () => {
        const AllLoadingCoordinates= Object.values(JobData?.loading)
        AllLoadingCoordinates.sort((a,b) => a.number - b.number)
        setLoadingLocations(AllLoadingCoordinates)

        const AllDropoffCoordinates = Object.values(JobData?.dropOff)
        AllDropoffCoordinates.sort((a,b) => a.number - b.number)
        setDropoffLocations(AllDropoffCoordinates)

        if(collection==='imports') {
            if(JobData?.linkedImportOrders){
                const containerInfo = JobData?.linkedImportOrders?.map(id=> (importOrders.find(importOrder=> importOrder?._id === id)?.containerInfo))
                setContainerInfo(containerInfo)
                setTerminal(Object.values(containerInfo)[0].emptyContainer)  
            } else{
                const containerInfo = Object.values(JobData?.containerInfo)
                setContainerInfo(containerInfo)
                setTerminal(containerInfo[0].emptyContainer)
            }
        }

        if(collection==='exports' && JobData?.containerInfo?.containerDimensions) {
            const containerInfo = [JobData?.containerInfo]
            setContainerInfo(containerInfo)
            setTerminal(containerInfo[0].releaseTerminal)
        } else if (collection==='exports' && JobData?.containerInfo) {
            const containerInfo = Object.values(JobData?.containerInfo)
            setContainerInfo(containerInfo)
            setTerminal(containerInfo[0].releaseTerminal)
        }
        bounds.extend({ lat: parseFloat(JobData?.driver?.driverCurrentCoordinates?.coordinates?.lat) || 0, lng: parseFloat(JobData?.driver?.driverCurrentCoordinates?.coordinates?.lng) || 0})
        if(bounds.getCenter().lat()!==0 &&  bounds.getCenter().lng()!==-180) {
			setBoundsCenter({lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: BoundsZoomLevel(bounds)})
		}

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JobData])

    const TimelineItem = (location, index, type, multiple) => (
        <Timeline.Item key={index} color={`${type==='dropoff' ? '#009298' : '#15588D'}`}>
            <Divider className={type==='dropoff' ? 'SecondarySectionTitle' : 'SectionTitle'} orientation='left' plain style={{paddingTop: 2}}>
                { type==='dropoff' ? 
                    multiple ? `Dropoff Location # ${index+1}`: 'Dropoff Location' :
                    multiple ? `Pickup Location # ${index+1}`: 'Loading Location'
                }
            </Divider>
            <p className='RegularFont'>{location.formattedAddress ? location.formattedAddress : 'To be provided.'}</p>
            {location.additionalDetails && <p className='TitleDescription'>{location.additionalDetails}</p>}
            {location.contactName && IconDescription('#Avatar', `${location.contactName}`)}
            {location.contactPhone && IconDescription('#Phone', `${location.contactPhone}`)}
        </Timeline.Item>
    )

    const LocationsSummary = (loadingLocations, dropoffLocations) => (
        <Timeline>
            {   check(loadingLocations) && loadingLocations.length>0 &&
                loadingLocations.map ((location, index) =>
                    TimelineItem(location, index, 'loading', loadingLocations.length>1)) 
            }
            {   check(dropoffLocations) && dropoffLocations.length>0 &&
                dropoffLocations.map ((location, index) =>
                    TimelineItem(location, index, 'dropoff', dropoffLocations.length>1)) 
            }
        </Timeline>
    )

	const LocationsCard = () => (
		<Card 
			title={CardHeader('Loading and Dropoff Locations')} 
			className='FullWidth MarginBottom'
		>
            <div>
                <div className='DisplayFlex'>
                    <div className='HalfWidth SquareRatio'>
                        <div className='SolidifyRatio Padding OverflowScroll'>
                            {LocationsSummary(loadingLocations, dropoffLocations)}
                            {   JobData.type==='import' && ['destuffing', 'shifting'].includes(JobData.specialRequests?.orderCategory) &&
                                <div className='FullWidth'>
                                    <Divider className='SectionTitle HalfPaddingTop' orientation='left' plain>Empty Container Information</Divider>
                                        {   terminal?.formattedAddress ?
                                            <>
                                                <p><span className='BoldFont'>Location: </span>{terminal?.formattedAddress}</p>
                                                { terminal?.additionalDetails && <p><span className='BoldFont'>Additional Details: </span>{terminal.additionalDetails}</p> }
                                                { terminal?.returnDT && <p><span className='BoldFont'>Return Deadline: </span>{moment(terminal.returnDT, 'x').format('DD-MM-YYYY hh:mm A')}</p> }
                                            </> : 
                                            <p>Will be updated shortly.</p>
                                        }
                                </div>
                            }
                        </div>
                    </div>
                    <div className='HalfWidth SquareRatio'>
                        <div className='SolidifyRatio'>
                            <GoogleMap
                                bounds={bounds}
                                zoom={(boundsCenter?.zoomlevel-8)>2 ? boundsCenter?.zoomlevel-8 : 10}
                                center={{ lat: boundsCenter?.lat, lng: boundsCenter?.lng }}
                                options={{ disableDefaultUI: true }}
                                mapContainerClassName='FullWidthHeight'
                            >
                                {JobData?.driver?.driverCurrentCoordinates &&
                                    <Marker
                                        position={{lat: parseFloat(JobData?.driver?.driverCurrentCoordinates?.coordinates?.lat) || 0, lng: parseFloat(JobData?.driver?.driverCurrentCoordinates?.coordinates?.lng) || 0}}
                                        icon={{ url: "https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FGroup%2015%20(1).png?alt=media&token=0d77c9fb-cfc9-4b55-a557-c8a4fc0dcfb4" }}
                                    />
                                }
                            </GoogleMap>
                        </div>
                    </div>
                </div>
            </div>
		</Card>
	)
	
	const ContainerInfoCard = () => (
		<Card 
			title={CardHeader('Container Information')}
            className='FullWidth MarginBottom'
		>
			<div className='FullWidth DisplayFlex'>
                {   !JobData.linkedImportOrders && 
                    <div className='HalfWidth'>
                        <div className='FullHeight Padding OverflowScroll'>
                            {   collection==='exports' &&
                                <>
                                    <Divider className='SectionTitle' orientation='left' plain>Release Terminal Information</Divider>
                                    <p><span className='BoldFont'>Terminal Location: </span>{terminal?.formattedAddress}</p>
                                    { terminal?.additionalDetails && <p><span className='BoldFont'>Additional Details: </span>{terminal.additionalDetails}</p> }
                                    { terminal?.contactName && IconDescription('#Avatar', `${terminal?.contactName}`) }
                                    { terminal?.contactPhone && IconDescription('#Phone', `${terminal?.contactPhone}`) }
                                </>
                            }
                            <>
                                {   containerInfo?.[0] &&
                                    <>
                                        <Divider className={`SectionTitle ${collection==='exports' ? 'HalfPaddingTop' : ''}`} orientation='left'>{containerInfo.length===1 ? 'Container Information' : 'First Container Information'}</Divider>
                                        <p><span className='BoldFont'>Container Dimensions: </span>{containerInfo?.[0]?.containerDimensions!=='xl2020ft' ? ContainerDimensionsEnum[containerInfo[0].containerDimensions].long : '20 Feet'}</p>
                                        {collection ==='exports' && <p><span className='BoldFont'>CRO Number: </span>{containerInfo?.[0]?.croNumber ? containerInfo[0].croNumber : 'To be provided.'}</p>}
                                        <p><span className='BoldFont'>Shipping Line: </span>{containerInfo?.[0]?.shippingLine}</p>
                                        <p><span className='BoldFont'>Cargo Type: </span>{containerInfo?.[0]?.freightType || 'Will be updated shortly.'}</p>
                                        <p><span className='BoldFont'>Cargo Weight: </span>{containerInfo?.[0]?.freightWeight ? containerInfo?.[0]?.freightWeight+' Tons': 'Will be updated shortly.'}</p>
                                        { JobData?.specialRequests?.reefer && <p><span className='BoldFont'>Container Type: </span>Reefer</p> }
                                    </>
                                }
                                {   containerInfo?.[1] &&
                                    <>
                                        <Divider className='SectionTitle HalfPaddingTop' orientation='left'>Second Container Information</Divider>
                                        <p><span className='BoldFont'>Container Dimensions: </span>{containerInfo[1].containerDimensions!=='xl2020ft' ? ContainerDimensionsEnum[containerInfo[1].containerDimensions].long : '20 Feet'}</p>
                                        {collection ==='exports' && <p><span className='BoldFont'>CRO Number: </span>{containerInfo[1].croNumber ? containerInfo[1].croNumber : 'To be provided.'}</p>}
                                        <p><span className='BoldFont'>Shipping Line: </span>{containerInfo[1].shippingLine}</p>
                                        <p><span className='BoldFont'>Cargo Type: </span>{containerInfo?.[1]?.freightType || 'Will be updated shortly.'}</p>
                                        <p><span className='BoldFont'>Cargo Weight: </span>{containerInfo?.[1]?.freightWeight ? containerInfo?.[1]?.freightWeight+' Tons': 'Will be updated shortly.'}</p>
                                        { JobData?.specialRequests?.reefer && <p><span className='BoldFont'>Container Type: </span>Reefer</p> }
                                    </>
                                }
                            </>
                            

                            {   (collection==='imports' && !JobData?.linkedImportOrders) &&
                                <>
                                    <Divider className='SectionTitle HalfPaddingTop' orientation='left' plain>Empty Container Information</Divider>
                                    {   terminal?.formattedAddress ?
                                        <>
                                            <p><span className='BoldFont'>Location: </span>{terminal?.formattedAddress}</p>
                                            { terminal?.additionalDetails && <p><span className='BoldFont'>Additional Details: </span>{terminal.additionalDetails}</p> }
                                            { terminal?.returnDT && <p><span className='BoldFont'>Return Deadline: </span>{moment(terminal.returnDT, 'x').format('DD-MM-YYYY hh:mm A')}</p> }
                                        </> : 
                                        <p>Will be updated shortly.</p>
                                    }
                                </>
                            }
                            {   JobData?.specialRequests && 
                                <>
                                    <Divider className='SectionTitle HalfPaddingTop' orientation='left' plain>Additional Requests</Divider>
                                    { JobData?.specialRequests?.orderCategory && <p><span className='BoldFont'>Order Category: </span>{OrderCategoryEnum[JobData?.specialRequests?.orderCategory]?.text}</p>}
                                    { JobData?.specialRequests?.localMovement!==undefined && <p><span className='BoldFont'>Local Movement: </span>{JobData?.specialRequests?.localMovement ? 'Yes' : 'No'}</p>}
                                    { JobData?.specialRequests?.reefer!==undefined && <p><span className='BoldFont'>Container Type: </span>{JobData?.specialRequests?.reefer ? 'Reefer' : 'Normal'}</p>}
                                    { JobData?.specialRequests?.fastLane!==undefined && <p><span className='BoldFont'>Fast Lane Delivery: </span>{JobData?.specialRequests?.fastLane ? 'Yes' : 'No'}</p>}
                                    { JobData?.specialRequests?.doIssuance!==undefined && <p><span className='BoldFont'>Do Issuance By: </span>{JobData?.specialRequests?.doIssuance ? 'Bridgelinx' : 'Client'}</p>}
                                    <p><span className='BoldFont'>Other Requests: </span>{JobData?.specialRequests?.otherRequests || '-'}</p>
                                </>
                            }

                        </div>
                    </div>
                }
                {   JobData.linkedImportOrders ? 
                    <div className='MapContainer FullWidth'>
                        {containerInfo?.length>0 && 
                        <div className='Padding FlexRowBox'>
                            <p className='FontSize18'><span className='BoldFont'>Shipping Line: </span>{Object?.values(Object?.values(containerInfo)[0])[0]?.shippingLine}</p>
                        </div>
                        }
                        <Divider/>
                        <div className='FlexColumnBox'>
                            <div className='FlexRowSpace'>
                                {Object.values(containerInfo)?.map((container)=> 
                                <div>
                                    <div className='FullHeight FlexColumnBox'>
                                        <svg className='VehicleIcon'>
                                            <use href={
                                                Sprite + ContainerDimensionsEnum[Object.values(container)[0].containerDimensions].icon
                                            }/>
                                        </svg>
                                        <div className='MarginTop CenterAlign'>
                                            <p className='BoldFont FontSize18 Capitalize'>
                                                { Object.values(container)[0] && ContainerDimensionsEnum[Object.values(container)[0].containerDimensions].long }
                                            </p>
                                            <div className='MarginTop'>
                                                <p className='Heading'>Container Number(s):</p>
                                                {   (Object.values(container)[0].containerNumber) ?
                                                    <p className='ContainerNumber'>{Object.values(container)[0].containerNumber}</p> :
                                                    <p className='TitleDescription'>Will be updated shortly.</p>
                                                }
                                            </div>
                                            <div className='MarginTop'>
                                                <p className='Heading'>Weight: </p>
                                                {   (Object.values(container)[0].freightWeight) ?
                                                    <p className='PrimaryColor FontSize18 BoldFont'>{Object.values(container)[0].freightWeight} Tons</p> :
                                                    <p className='TitleDescription'>Will be updated shortly.</p>
                                                }
                                            </div>
                                            <div className='MarginTop'>
                                                <p className='Heading'>Type: </p>
                                                {   (Object.values(container)[0].freightType) ?
                                                    <p className='PrimaryColor FontSize18 BoldFont'>{Object.values(container)[0].freightType}</p> :
                                                    <p className='TitleDescription'>Will be updated shortly.</p>
                                                }
                                            </div>
                                            { JobData?.specialRequests?.reefer && <p><span className='BoldFont'>Container Type: </span>Reefer</p> }
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='FlexColumnBox PaddingTop PaddingBottom'>
                            <p className='BoldFont FontSize18'>Shifted to: </p>
                            <p className='Capitalize FontSize16'>{ContainerDimensionsEnum[JobData?.vehicleInfo?.vehicleType]?.long+' - '+JobData?.vehicleInfo?.vehicleSubtype}</p>
                            {JobData?.vehicleInfo?.vehicleType && JobData?.vehicleInfo?.vehicleSubtype &&
                                <svg className='VehicleIcon MarginTop'>
                                    <use href={
                                        Sprite + VehiclesEnum[JobData?.vehicleInfo?.vehicleType][JobData?.vehicleInfo?.vehicleSubtype].icon
                                    }/>
                                </svg>
                            }
                        </div>
                    </div>
                    : 
                    <div className='HalfWidth'>
                        <div className='FullHeight Padding FlexColumnBox' style={{backgroundColor: '#FAFAFA'}}>
                            <svg className='VehicleIcon'>
                                <use href={
                                    containerInfo.length>0 &&
                                    Sprite + ContainerDimensionsEnum[containerInfo[0].containerDimensions].icon
                                }/>
                            </svg>
                            <div className='MarginTop CenterAlign'>
                                <p className='BoldFont FontSize18 Capitalize'>
                                    { containerInfo?.[0] && ContainerDimensionsEnum[containerInfo[0].containerDimensions].long }
                                </p>
                                <div className='MarginTop'>
                                    <p className='Heading'>Container Number(s):</p>
                                    {   (containerInfo?.[0]?.containerNumber || containerInfo?.[1]?.containerNumber) ?
                                        <p className='ContainerNumber'>{containerInfo?.[0]?.containerNumber}<br/>{containerInfo?.[1]?.containerNumber}</p> :
                                        <p className='TitleDescription'>Will be updated shortly.</p>
                                    }
                                </div>
                                {   JobData?.vesselCutoffDT &&
                                    <p className='ErrorColor'>
                                        <span className='BoldFont'>Vessel Cutt-off Date:</span> { moment(JobData?.vesselCutoffDT, 'x').format('DD-MM-YYYY hh:mm A') }
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                }
			</div>
		</Card>
	)

    const ClearingAgentsEmailsCard = () => (
		<Card
			title={CardHeader('Clearing Agents and Emails')} 
			className='HalfWidth HalfMarginRight'
		>
            <div className='HalfSquareRatio'>
                <div className='SolidifyRatio FullHeight Padding OverflowScroll'>
                {   JobData &&
                    <>
                        <Divider className='SectionTitle' orientation='left'>Clearing Agents</Divider>
                        <div className='SummaryTable'>
                            {   JobData?.clearingAgents &&  
                                JobData.clearingAgents.map ((clearingAgent) =>
                                    <div className='FlexRow'>
                                        {IconDescription('#Avatar', clearingAgent.contactName, 'HalfWidth')}
                                        {IconDescription('#Phone', clearingAgent.contactPhone, 'HalfWidth')}
                                    </div>
                                )
                            }
                        </div>
                        <Divider className='SectionTitle PaddingTop' orientation='left'>Emails</Divider>
                        <div className='SummaryTable'>
                            {   JobData?.clientEmail && 
                                JobData.clientEmail.map (Email => IconDescription('#Email', Email, ''))
                            }
                        </div>
                    </>
                }
                </div>
            </div>
		</Card>
	)

	const DocumentsCard = () => {
        const ShipmentDocument = collection==='imports' ? containerInfo?.[0]?.blDocument : containerInfo?.[0]?.croDocument
		return <Card
			title={CardHeader('Documents')} 
			className='HalfWidth HalfMarginLeft'
		>
            <div className='HalfSquareRatio'>
                <div className='SolidifyRatio FullHeight Padding OverflowScroll'>
                {   JobData &&
                    <div className='CollapsePanels'>
                        <Collapse accordion expandIconPosition={"right"} expandIcon={ ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                            <Collapse.Panel header='Shipment Documents' key='shipmentDocuments' className='CollapseHeader'> 
                                <Row className='HalfPadding'>
                                    {   ShipmentDocument && 
                                        <div className='UploadImageBox'>
                                            <Col style={{margin: '0px 1em'}}>
                                                {   /.jpg|.jpeg|.jpe .jif|.jfif|.jfi|.png|.gif|.webp|.raw|.arw|.cr2|.nrw|.k25|.bmp|.dib|.jp2|.j2k|.jpf|.jpx|.jpm|.mj2/.test(ShipmentDocument) ?
                                                    <Image width={70} height={70} src={ShipmentDocument} className='UploadImage'/> :
                                                    <FileTwoTone className='CursorPointer' style={{ fontSize: '70px' }} twoToneColor="#1C75BC" onClick={()=>  window.open(ShipmentDocument, '_blank').focus()}/>
                                                }
                                            </Col>
                                        </div>
                                    }
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </div>
                }
                </div>
            </div>
		</Card>
    }

    const MovingStatusPanelHeader = (status, type) => (
        <div className='FlexColumn'>
            <p className={`BoldFont ${type==='Moving' ? 'PrimaryShadeColor' : 'SecondaryShadeColor'}`}>{ReadableString(status.status.replace('driver', ''))}</p>
            {   type==='Moving' && 
                parseInt(status.time)>moment().format('x') ?
                    <>
                        <div className='FlexRow'>
                            <svg className='MiniIcon MarginRight8'><use href={Sprite + '#EstimatedTime'}/></svg>
                            <p className='BoldFont BlackColor'>ETA:</p>
                        </div>
                        <p className='GrayColor MarginLeft'>
                            {moment(parseInt(status.time),'x').format('DD-MM-YYYY hh:mm A')}
                        </p>
                        <div className='FlexRow'>
                            <svg className='MiniIcon MarginRight8'><use href={Sprite + '#RemainingTime'}/></svg>
                            <p className='BoldFont BlackColor'>Remaining Time:</p>
                        </div>
                        <p className='GrayColor MarginLeft'>
                            <Statistic.Countdown className='StatusTimer' format='D 𝖣𝖺𝗒𝗌 HH 𝖧𝗈𝗎𝗋𝗌 mm 𝖬𝗂𝗇' value={moment(status.time, 'x')}/>
                        </p>
                    </> :
                    <>
                        <div className='FlexRow'>
                            <svg className='MiniIcon MarginRight8'><use href={Sprite + '#ActualTime'}/></svg>
                            <p className='BoldFont BlackColor'>Reached Time:</p>
                        </div>
                        <p className='GrayColor MarginLeft'>
                            {moment(parseInt(status.time),'x').format('DD-MM-YYYY hh:mm A')}
                        </p>
                    </>
            }
        </div>
    )

    const DriverCard = () => (
		<Card
			title={CardHeader('Driver')} 
			className='HalfWidth MarginTop'
		>
            <div className='HalfSquareRatio'>
                <div className='SolidifyRatio FullHeight Padding'>
                    <div className='SummaryTable MarginBottom'>
                        <p className='FontSize14'><span className='BoldFont'>Name: </span>{JobData?.driver?.driverName}</p>
                        <p className='FontSize14'><span className='BoldFont'>Phone: </span>{JobData?.driver?.driverPhone}</p>
                        <p className='FontSize14'><span className='BoldFont'>Vehicle Registration: </span>{JobData?.driver?.driverVehicleRegistration}</p>
                    </div>
                </div>
            </div>
		</Card>
	)

    const DriverTrackingPanel = () => {
		return JobData?.driverStatusHistory?.length>0 ? 
			<div className='LiveTrackingPanels Padding'>
				<Collapse defaultActiveKey={JobData.driverStatusHistory.length-1} accordion expandIconPosition={'right'} expandIcon={ ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
					{   JobData.driverStatusHistory.map( (status, index) => 
                            <Collapse.Panel
                                key={index} 
                                header={MovingStatusPanelHeader(status, status.status.includes('MovingTo') ? 'Moving' : 'Reached')}
                                className={status.status.includes('MovingTo') ? 'PrimaryCollapsePanel' : 'SecondaryCollapsePanel'}
                                collapsible={!status?.statusJourney?.filter(subStatus=> subStatus.type!=='breakdown').length>0 && 'disabled'}
                                showArrow={status?.statusJourney?.filter(subStatus=> subStatus.type!=='breakdown').length>0 ? true : false}
                            >
                                <Timeline>
                                    {	status?.statusJourney?.filter(subStatus=> subStatus.type!=='breakdown')?.map(subStatus =>
                                            subStatus.type!=='breakdown' &&
                                            <Timeline.Item color={'#15588D'}>
                                                <p className='BoldFont Capitalize PrimaryShadeColor'> {ReadableString(subStatus.status)} </p>
                                                <p className='TitleDescription'>{moment(parseInt(subStatus.time)).format('DD-MM-YYYY hh:mm A')}</p>
                                            </Timeline.Item>
                                        )
                                    }
                                </Timeline> 
                            </Collapse.Panel>
                    )}
				</Collapse>
			</div>  :
			<p className='TitleDescription Padding CenterAlign'>Driver status will<br/>be updated shortly.</p>
	}

	return (
        <Layout>
			<Header>
                <Breadcrumb separator='>'>
                    <Breadcrumb.Item className='Heading'><a href={`/${collection}`}>{collection==='imports' ? 'Import Orders' : 'Export Orders'}</a></Breadcrumb.Item>
                    <Breadcrumb.Item className='Heading'>Order No. {collection==='imports' ? 'I' : 'E'}-{JobData.subOrderNumber || JobData.orderNumber}</Breadcrumb.Item>
                </Breadcrumb>
                {   parseInt(JobData.createdAt)>1632214800000 && // 21 September 2021 04:00 PM Onwards
                    <Button
                        className='Button DuplicateOrderButton' 
                        onClick={() => 
                            props.history.push({
                                pathname: `/${collection}/create`,
                                state: { DuplicateData: JobData }
                            })
                        }
                    > Duplicate Order </Button>
                }
			</Header>
			{	loader ? <Loader/> :
                <Layout>
                    <Layout>
                        <Header className='NoPadding'>
                            {   JobData?.orderStatus?.status && 
                                <div className='FullWidthHeight FlexRowSpace PaddingLeft PaddingRight' 
                                    style={{backgroundColor: ExportOrderStatusEnum[JobData.orderStatus.status].background}}
                                >
                                    <p 
                                        className='OrderStatusStyle' 
                                        style={{
                                            color: ExportOrderStatusEnum[JobData.orderStatus.status].color, 
                                            WebkitTextStroke: `1px ${ExportOrderStatusEnum[JobData.orderStatus.status].color}`,
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        {ExportOrderStatusEnum[JobData.orderStatus.status].text}
                                    </p>
                                    <div className='FlexRow'>
                                        {   JobData?.finalizedRate &&
                                            <div className='OrderStickyInfo HalfMarginRight' style={{border: `1px solid ${ExportOrderStatusEnum[JobData.orderStatus.status].color}`}}>
                                                <div className='FullWidthHeight FlexColumnBox'>
                                                    <p className='BoldFont' style={{color: ExportOrderStatusEnum[JobData.orderStatus.status].color}}>Cost</p>
                                                    <p className='TitleDescription'>Rs. {JobData.finalizedRate}</p>
                                                </div>
                                            </div>
                                        }
                                        <div className='OrderStickyInfo' style={{border: `1px solid ${ExportOrderStatusEnum[JobData.orderStatus.status].color}`}}>
                                            <div className='FullWidthHeight FlexColumnBox'>
                                                <p className='BoldFont' style={{color: ExportOrderStatusEnum[JobData.orderStatus.status].color}}>Creation Date</p>
                                                <p className='TitleDescription'>{moment(JobData?.createdAt,'x').format('DD-MM-YYYY hh:mm A')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Header>
                        <Content>
                            {ContainerInfoCard()}
                            {LocationsCard()}
                            <div className='DisplayFlex'>
                                {ClearingAgentsEmailsCard()}
                                {DocumentsCard()}
                            </div>
                            {JobData?.driver && JobData?.driver?.driverPhone && JobData?.driver?.driverVehicleRegistration && DriverCard()}
                        </Content>
                    </Layout>
                    <Sider width={288}>
                        <div className='DriverTrackingHeader BorderBottom'>
                            <div className='FullWidthHeight FlexRow'>
                                <p className='BoldFont PrimaryColor FontSize16'>Driver Tracking</p>
                            </div>
                        </div>
                        {DriverTrackingPanel()}
                    </Sider>
                </Layout>
			}
		</Layout>
	)
}

export default ViewExportOrder