import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { Layout } from 'antd'

import Sidebar from './components/Sidebar';

import Register from './containers/Auth/Register';
import Login from './containers/Auth/Login';

import {firestore, Auth} from './firebase/firebaseConfig';
// import BridgeLinxLogo from './logo.png';

import './App.css';
import Dashboard from './containers/Dashboard';

import { useSetRecoilState } from 'recoil'
import {userAtom, activeBusinessCodeAtom} from './recoil/atoms'

import DynamicTable from './helpers/DynamicTable'
import NewLocalOrder from './containers/LocalOrders/NewOrder'
import ViewLocalOrder from './containers/LocalOrders/ViewOrder'
import NewImportExportOrder from './containers/ImportExportOrders/NewOrder'
import ViewImportExportOrder from './containers/ImportExportOrders/ViewOrder'
import NewBulkOrder from './containers/BulkOrders/NewOrder'
import ViewBulkOrder from './containers/BulkOrders/ViewOrder'


const App = (props) => {
	const [isSignedIn,setIsSignedIn] = useState(false)
	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(false)

	const setUserData = useSetRecoilState(userAtom)
	const setActiveBusinessCode = useSetRecoilState(activeBusinessCodeAtom)

	useEffect(()=> {
		Auth.onAuthStateChanged(async (user) => {
			setLoading(true);
			if(window.location.pathname !== '/signup') {
				if (user !== null) {
					const response = await firestore
						.collection("profiles")
						.doc(user.uid)
						.get();
						
					if(response.data() !== undefined){
						if(response.data().userType !== 'driver'){
						const responseClient = await firestore
						.collection("clients")
						.where('profileInfo','==',user.uid)
						.get();

						const clientData = responseClient.docs.map((doc,index) => ({...doc.data()}));
						
						if(clientData[0] !== undefined && clientData[0].businessCode !== null && clientData[0].businessCode !== undefined){
							setUser({...response.data(), email: user.email, ...clientData[0]});
							const businessCode = typeof clientData[0].businessCode === 'object' ? clientData[0].businessCode[0] : clientData[0].businessCode
							setUserData({...response.data(), ...clientData[0]})
							setActiveBusinessCode(businessCode)
							setIsSignedIn(!!user)
							setLoading(false);
						} else {
							setIsSignedIn(false);
							setLoading(false)
							alert('You are not authorized to access this Portal, Kindly contact Support!');
						}
						} else {
						setIsSignedIn(false);
						setLoading(false)
						alert('You are not authorized to access this Portal, Kindly contact Support!');
						}
					} else {
						window.location.href=`/signup?smsSent=true&profileInfoFields=true&user=${JSON.stringify(user)}` 
					}
				} else {
					setIsSignedIn(false)
					setLoading(false)
				}
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return (
    isSignedIn === false ?
        <Router>
			<Switch>
				<Route path="/signup" render={(props) => <Register {...props} />} exact />
				<Route path="/signin" render={(props) => <Login {...props} loading={loading} />} exact />
				<Route path='/'><Redirect to="/signin" /></Route>
			</Switch>
		</Router>
	:
		<Router>
			<Layout className='Layout'>
				<Sidebar/>
				<Switch>
					{/* <Route path='/dashboard' render={(props) => <Dashboard {...props} user={user} />} exact /> */}

					<Route path={['/orders']} render={(props) => <DynamicTable {...props} user={user} />} exact />
					{/* <Route path={['/orders', '/imports', '/exports', '/drivers', '/bulk']} render={(props) => <DynamicTable {...props} user={user} />} exact /> */}

					<Route path='/orders/create' render={(props) => <NewLocalOrder {...props} user={user} />} exact />
					{/* <Route path='/exports/create' render={(props) => <NewImportExportOrder {...props} user={user} />} exact />
					<Route path='/imports/create' render={(props) => <NewImportExportOrder {...props} user={user} />} exact />
					<Route path='/bulk/create' render={(props) => <NewBulkOrder {...props} user={user} />} exact /> */}

					<Route path='/orders/:id/view' render={(props) => <ViewLocalOrder {...props} user={user} />} exact />
					{/* <Route path={['/exports/:id/view', '/imports/:id/view']} render={(props) => <ViewImportExportOrder {...props} user={user} />} exact />
					<Route path={['/bulk/:id/view']} render={(props) => <ViewBulkOrder {...props} user={user} />} exact /> */}

					<Redirect to='/orders' />
					<Route path='/signin' exact />
				</Switch>
			</Layout>
		</Router>
	)
}

export default App
