import { Select, Form } from 'antd'
import { ContainerDimensionsEnum, range } from '../helpers/Functions'
import moment from 'moment'

/* ============================================================================================= */
/* ==================================== LOCAL ORDER SCHEMAS ==================================== */
/* ============================================================================================= */
const addonAfterSelect = (
    <Form.Item
        key={'freightWeightUnit'}
        name='freightWeightUnit'
        initialValue='kg'
        noStyle
    >
        <Select defaultValue='kg'>
            <Select.Option value='kg'>Kg</Select.Option>
            <Select.Option value='tonne'>Ton</Select.Option>
        </Select>
    </Form.Item>
)

export const LocalPickupSchema = (props) => {
    return [
        {
            title: 'Location Information',
            description: 'Fill in the following details related to your loading location',
            fields: [
                {
                    type: 'autocomplete',
                    label: 'Selected Location Address',
                    name: 'formattedAddress',
                    formReference: props?.formReference,
                    locationCoordinates: props?.locationCoordinates,
                    setLocationCoordinates: props?.setLocationCoordinates,
                    required: true,
                    message: 'Location is required.',
                },
                {
                    type: 'input',
                    label: 'Additional Details',
                    name: 'additionalDetails',
                    inputType: 'text',
                },
            ]
        },
        {
            title: 'Contact Information',
            description: 'Fill in the following details related to your loading location',
            fields: [
                {
                    type: 'checkbox',
                    label: 'I am the contact person for this location.',
                    name: 'contactCheck',
                    formReference: props?.formReference,
                    fillData: props?.fillData,
                    clearData: props?.clearData
                },
                [
                    {
                        type: 'input',
                        label: 'Name',
                        name: 'contactName',
                        required: true,
                        message: 'Name is required.',
                        inputType: 'text',
                    },
                    {
                        type: 'phoneNumber',
                        label: 'Phone Number',
                        name: 'contactPhone',
                        required: true,
                        message: 'Phone number is required.',
                        addonBefore: '+92'
                    },
                ]
            ]
        },
        {
            title: 'Freight Information',
            description: 'Fill in the following details related to freight',
            fields: [
                [
                    {
                        type: 'select',
                        label: 'Freight Type',
                        name: 'freightDetails',
                        required: true,
                        message: 'Freight type is required.',
                        options: [
                            { text: 'Cement', id: 'cement'},
                            { text: 'Coal', id: 'coal'},
                            { text: 'Wood', id: 'wood'},
                            { text: 'Baggas', id: 'baggas'},
                            { text: 'Steel', id: 'steel'},
                            { text: 'Others', id: 'others'},
                        ],
                        displayProperty: 'text',
                        valueProperty: 'id',
                    },
                    {
                        type: 'select',
                        label: 'Packaging Type',
                        name: 'typeOfFreight',
                        required: true,
                        message: 'Name is required.',
                        options: [
                            { text: 'Bags', id: 'bags'},
                            { text: 'Boxes', id: 'boxes'},
                            { text: 'Pallets', id: 'pallets'},
                            { text: 'Others', id: 'others'},
                        ],
                        displayProperty: 'text',
                        valueProperty: 'id',
                    }
                ],
                [
                    {
                        type: 'input',
                        label: 'Count (No. of Units)',
                        name: 'noOfUnits',
                        inputType: 'number',
                        required: true,
                        message: 'Count (No. of Units) is required.',
                    },
                    {
                        type: 'input',
                        label: 'Weight',
                        name: 'freightWeight',
                        inputType: 'number',
                        addonAfter: addonAfterSelect
                    },
                ]
            ]
        },
        {
            title: 'Upload Documents',
            description: 'Fill in the following details related to freight',
            fields: [
                {
                    type: 'upload',
                    name: 'clientUpload',
                    uploadLink: props?.documentId
                },
            ]
        }
    ]
}

export const LocalDropoffSchema = (props) => {
    return [
        {
            title: 'Location Information',
            description: 'Fill in the following details related to your loading location',
            fields: [
                {
                    type: 'autocomplete',
                    label: 'Selected Location Address',
                    name: 'formattedAddress',
                    formReference: props?.formReference,
                    locationCoordinates: props?.locationCoordinates,
                    setLocationCoordinates: props?.setLocationCoordinates,
                },
                {
                    type: 'input',
                    label: 'Additional Details',
                    name: 'additionalDetails',
                    inputType: 'text',
                },
            ]
        },
        {
            title: 'Contact Information',
            description: 'Fill in the following details related to your loading location',
            fields: [
                {
                    type: 'checkbox',
                    label: 'I am the contact person for this location.',
                    name: 'contactCheck',
                    formReference: props?.formReference,
                    fillData: props?.fillData,
                    clearData: props?.clearData
                },
                [
                    {
                        type: 'input',
                        label: 'Name',
                        name: 'contactName',
                        required: true,
                        message: 'Name is required.',
                        inputType: 'text',
                    },
                    {
                        type: 'phoneNumber',
                        label: 'Phone Number',
                        name: 'contactPhone',
                        required: true,
                        message: 'Phone number is required.',
                        addonBefore: '+92'
                    },
                ]
            ]
        },
        {
            title: 'Freight Information',
            description: 'Fill in the following details related to freight',
            fields: [
                [
                    {
                        type: 'input',
                        label: 'Count (No. of Units)',
                        name: 'noOfUnits',
                        inputType: 'number',
                        required: true,
                        message: 'Count (No. of Units) is required.',
                    },
                    {
                        type: 'input',
                        label: 'Weight',
                        name: 'freightWeight',
                        inputType: 'number',
                        addonAfter: addonAfterSelect 
                    },
                ]
            ]
        }
    ]
}

/* ============================================================================================= */
/* =================================== IMPORT EXPORT SCHEMAS =================================== */
/* ============================================================================================= */


export const ExportDropoffSchema = (props) => {
    return  [
        // {
        //     title: 'Recent Locations',
        //     description: 'Select from locations to fill data automatically.',
        //     fields: [
        //         {
        //             type: 'recentSelect',
        //             name: 'recent',
        //             options: props?.options,
        //             displayProperty: 'formattedAddress',
		// 			formReference: props?.formReference,
		// 			setFields: ['formattedAddress', 'additionalDetails'],
        //             showSearch: true
        //         },
        //     ]
        // },
        {
            title: 'Location Information',
            description: 'Fill in the following details related to your loading location',
            fields: [
                {
                    type: 'recentSelect',
                    name: 'formattedAddress',
                    options: props?.options,
                    displayProperty: 'formattedAddress',
                    selfValueProperty: 'formattedAddress',
					formReference: props?.formReference,
					setFields: ['additionalDetails'],
                    showSearch: true,
                    required: true,
                    message: 'Location address is required.',
                },
                {
                    type: 'input',
                    label: 'Additional Details',
                    name: 'additionalDetails',
                    inputType: 'text',
                },
            ]
        }
    ]
}

export const ClearingAgentFormSchema = (props) => {
    return  [
        {
            title: 'Recently Added Clearing Agents',
            description: 'Select from recent clearing agents to fill data automatically.',
            fields: [
                {
                    type: 'recentSelect',
                    name: 'recent',
                    options: props?.options,
                    displayProperty: 'contactName',
					formReference: props?.formReference,
					setFields: ['contactName', 'contactPhone'],
                    showSearch: true
                },
            ]
        },
        {
            title: 'Contact Information',
            description: 'Fill in the following details related to your loading location',
            fields: [
                [
                    {
                        type: 'input',
                        label: 'Name',
                        name: 'contactName',
                        required: true,
                        message: `Name is required.`,
                        inputType: 'text',
                    },
                    {
                        type: 'phoneNumber',
                        label: 'Phone Number',
                        name: 'contactPhone',
                        required: true,
                        message: `Phone number is required.`,
                        inputType: 'text',
                        addonBefore: '+92'
                    },
                ]
            ]
        },
        {
            ...(    props?.collection==='exports' &&
                    {
                        title: 'Linked Loading Location',
                        description: 'Fill in the following details related to your location',
                        fields: [
                            {
                                type: 'select',
                                label: 'Loading Location',
                                name: 'loadingLocation',
                                required: true,
                                message: 'Location is required.',
                                options: props?.locations,
                                displayProperty: 'formattedAddress',
                                valueProperty: '_id',
                            },
                        ]
                    }
                )
        },
    ]
}

export const ContainerDimensionsFormSchema = () => {
    return [
        {
            type: 'radioGroup',
            name: 'containerDimensions',
            dataObject: ContainerDimensionsEnum,
            required: true,
            message: 'Container dimension is required.',
            radioButtonView: true
        }
    ]
}

export const EmptyContainerFormSchema = () => {
    return {
        title: 'Empty Container Information',
        description: 'Fill in the following details related to your release terminal location',
        fields: [
            {
                type: 'input',
                label: 'Location Address',
                name: 'formattedAddress',
                inputType: 'text',
            },
            {
                type: 'input',
                label: 'Additional Details',
                name: 'additionalDetails',
                inputType: 'text',
            },
            {
                type: 'dateTime',
                label: 'Empty Container Return Date',
                name: 'returnDT',
                format: 'DD-MM-YYYY HH:mm',
                disabledDate: current => current && current < moment().subtract(1,'days').endOf('day'),
                disabledTime: date => { return {
                    disabledHours: () => range(0, 24).splice(0, (!date || date.isSame(moment(), 'day')) ? moment().hours() : 0),
                    disabledMinutes: () => range(0, 60).splice(0, (!date || (date.isSame(moment(), 'day') && date.isSame(moment(), 'hours'))) ? moment().minutes() : 0)
                }},
            },
        ]
    }
}

export const ReleaseTerminalFormSchema = () => {
    return [
        {
            title: 'Location Information',
            description: 'Fill in the following details related to your release terminal location',
            fields: [
                {
                    type: 'input',
                    label: 'Location Address',
                    name: 'formattedAddress',
                    inputType: 'text',
                    required: true,
                    message: 'Location address is required.',
                },
                {
                    type: 'input',
                    label: 'Additional Details',
                    name: 'additionalDetails',
                    inputType: 'text',
                }
            ]
        },
        {
            title: 'Emergency Contact Information',
            description: 'Fill in the following details related to your release terminal location',
            fields: [
                {
                    type: 'dynamicFields',
                    label: 'Emergency Contact',
                    name: 'pointOfContact',
                    minEntries: 0,
                    fields: [
                        {
                            type: 'input',
                            label: 'Emergency Contact Name',
                            name: 'contactName',
                            required: true,
                            message: 'Contact name is required.',
                            inputType: 'text'
                        },
                        {
                            type: 'phoneNumber',
                            label: 'Phone Number',
                            name: 'contactPhone',
                            required: true,
                            message: 'Phone number is required.',
                            addonBefore: '+92'
                        }
                    ],
                }
            ]
        }
    ]
}



/* ============================================================================================== */
/* ================================== CORPORATE DRIVERS SCHEMA ================================== */
/* ============================================================================================== */
const CascaderOptions = [ 
    {
        value: 'small',
        label: 'Low Capacity',
        children: [
            { value: 'open', label: 'Open' },
            { value: 'closed', label: 'Closed' }
        ]
    },
    {
        value: 'medium',
        label: 'Medium Capacity',
        children: [
            { value: 'open', label: 'Open' },
            { value: 'closed', label: 'Closed' }
        ]
    },
    {
        value: 'large',
        label: 'Large Capacity',
        children: [
            { value: 'open', label: 'Open' },
            { value: 'closed', label: 'Closed' }
        ]
    },
    {
        value: 'superx',
        label: 'Super Capacity',
    },
    {
        value: 'xl20ft',
        label: 'XL20 Feet',
        children: [
            { value: 'flatbed', label: 'Flatbed' },
            { value: 'framed', label: 'Framed' },
            { value: 'containerized', label: 'Containerized' }
        ]
    },
    {
        value: 'xl40ft',
        label: 'XL40 Feet',
        children: [
            { value: 'flatbed', label: 'Flatbed' },
            { value: 'framed', label: 'Framed' },
            { value: 'containerized', label: 'Containerized' }
        ]
    },
]

export const DriverFormSchema = (props) => {
    return [
        {
            title: 'Driver Information',
            description: 'Fill in the following details related to your loading location',
            fields: [
                [
                    {
                        type: 'input',
                        label: 'First Name',
                        name: 'firstName',
                        inputType: 'text',
                        required: true,
                        message: 'First name is required.',
                    },
                    {
                        type: 'input',
                        label: 'Last Name',
                        name: 'lastName',
                        inputType: 'text',
                        required: true,
                        message: 'Last name is required.',
                    },
                ],
                [
                    {
                        type: 'phoneNumber',
                        label: 'Phone Number',
                        name: 'phoneNumber',
                        required: true,
                        message: 'Phone number is required.',
                        addonBefore: '+92'
                    },
                    {
                        type: 'select',
                        label: 'Base Location',
                        name: 'baseLocation',
                        required: true,
                        message: 'Base location is required.',
                        options: props && props.baseLocations,
                        displayProperty: 'name',
                        valueProperty: '_id',
                    }
                ]
            ]
        },
        {
            title: 'Assigned Vehicle Information',
            description: `Fill in the following details related to driver's vehicle`,
            fields: [
                {
                    type: 'cascader',
                    label: 'Vehicle Category',
                    name: 'vehicleCategory',
                    required: true,
                    message: 'Vehicle Category is required.',
                    options: CascaderOptions
                },
                [
                    {
                        type: 'input',
                        label: 'Manufacturer Name',
                        name: 'manufacturerName',
                        inputType: 'text',
                        required: true,
                        message: 'Manufacturer name is required.',
                    },
                    {
                        type: 'input',
                        label: 'Vehicle Variant',
                        name: 'vehicleVariant',
                        inputType: 'text',
                        required: true,
                        message: 'Vehicle variant is required.',
                        tooltip: `e.g Shehzore, Mazda, Pickup`,
                    },
                ],
                {
                    type: 'input',
                    label: 'Registration Number',
                    name: 'registrationNumber',
                    inputType: 'text',
                    required: true,
                    message: 'Registration number is required.',
                    tooltip: `Vehicle's license plate number is required.`,
                },
            ]
        }
    ]
}

export const ContainerDetailsSchema = (props) => {
    if(props.selectedDimension==='xl2020ft') {
        return [
            {
                title: 'First Container Information',
                description: 'Fill in the following details related to your loading location',
                fields: [
                    ...props.type==='imports' ? [{
                        type: 'input',
                        label: 'Container Number',
                        name: 'containerNumber',
                        required: true,
                        message: 'Container number is required.',
                        inputType: 'text',
                    }] : [],
                    [
                        {
                            type: 'input',
                            label: 'Cargo Type',
                            name: 'freightType',
                            required: true,
                            message: 'Cargo type is required.',
                            inputType: 'text',
                        },
                        {
                            type: 'number',
                            label: 'Cargo Weight',
                            name: 'freightWeight',
                            required: true,
                            message: 'Cargo weight is required.',
                            addonAfter: 'Tons',
                            minValue: 1,
                        }
                    ]
                ]
            },
            {
                title: 'Second Container Information',
                description: 'Fill in the following details related to your loading location',
                fields: [
                    ...props.type==='imports' ? [{
                        type: 'input',
                        label: 'Container Number',
                        name: 'containerNumber2',
                        required: true,
                        message: 'Container number is required.',
                        inputType: 'text',
                    }] : [],
                    [
                        {
                            type: 'input',
                            label: 'Cargo Type',
                            name: 'freightType2',
                            required: true,
                            message: 'Cargo type is required.',
                            inputType: 'text',
                        },
                        {
                            type: 'number',
                            label: 'Cargo Weight',
                            name: 'freightWeight2',
                            required: true,
                            message: 'Cargo weight is required.',
                            addonAfter: 'Tons',
                            minValue: 1,
                        }
                    ]
                ]
            }
        ]
    } else {
        return [
            ...props.type==='imports' ? [{
                type: 'input',
                label: 'Container Number',
                name: 'containerNumber',
                required: true,
                message: 'Container number is required.',
                inputType: 'text',
            }] : [],
            {
                type: 'input',
                label: 'Cargo Type',
                name: 'freightType',
                required: true,
                message: 'Cargo type is required.',
                inputType: 'text',
            },
            {
                type: 'number',
                label: 'Cargo Weight',
                name: 'freightWeight',
                required: true,
                message: 'Cargo weight is required.',
                addonAfter: 'Tons',
                minValue: 1,
            }
        ]
    }
}

// {...(props.collection==='exports' && ReleaseTerminalFormSchema())},

export const PortLocationSchema = (props) => {
    return [
        {
            title: 'Port Information',
            description: 'Select port location and type additional details (if any)',
            fields: [
                {
                    type: 'recentSelect',
                    label: 'Port Name',
                    name: 'formattedAddress',
                    required: true,
                    message: 'Port name is required.',
                    options: props?.options,
                    displayProperty: 'formattedAddress',
                    selfValueProperty: 'formattedAddress',
					formReference: props?.formReference,
					setFields: ['additionalDetails'],
                    showSearch: true
                },
                {
                    type: 'input',
                    label: 'Additional Details',
                    name: 'additionalDetails',
                    inputType: 'text',
                },
            ]
        },
        {
            title: 'Clearing Agents',
            description: "Add clearing agents' name and contact number present at port",
            fields: [
                {
                    type: 'dynamicFields',
                    label: 'Clearing Agent',
                    name: 'pointOfContact',
                    initialValue: [{contactName: '', contactPhone: ''}],
                    minEntries: 1,
                    fields: [
                        {
                            type: 'input',
                            label: 'Clearing Agent Name',
                            name: 'contactName',
                            required: true,
                            message: 'Clearing agent name is required.',
                            inputType: 'text'
                        },
                        {
                            type: 'phoneNumber',
                            label: 'Phone Number',
                            name: 'contactPhone',
                            required: true,
                            message: 'Phone number is required.',
                            addonBefore: '+92'
                        }
                    ],
                }
            ]
        }
    ]
}

export const LocationFormSchema = (props) => {
    return [
        {
            title: 'Location Information',
            description: `Fill in the following details related to your ${(props.collection==='imports' || props.collection==='bulk') ? 'dropoff' : 'loading'} location`,
            fields: [
                {
                    type: 'recentSelect',
                    label: 'Address',
                    name: 'formattedAddress',
                    required: true,
                    message: 'Address is required.',
                    options: props?.options,
                    displayProperty: 'formattedAddress',
                    selfValueProperty: 'formattedAddress',
					formReference: props?.formReference,
					setFields: ['additionalDetails'],
                    showSearch: true
                },
                {
                    type: 'dependency',
                    independent: 'formattedAddress',
                    condition: ['Other'],
                    successSchema: [
                        [
                            {
                                type: 'input',
                                label: 'Mill Name',
                                name: 'millName',
                                required: true,
                                message: 'Mill name is required.',
                                inputType: 'text',
                            },
                            {
                                type: 'input',
                                label: 'Mill Address',
                                name: 'millAddress',
                                required: true,
                                message: 'Mill address is required.',
                                inputType: 'text',
                            },
                        ]
                    ],
                    failureSchema: [
                        {
                            type: 'input',
                            label: 'Additional Details',
                            name: 'additionalDetails',
                            inputType: 'text',
                        }
                    ]
                }
            ]
        },
        {
            title: 'Contact Information',
            description: `Add Point of Contact (POC)'s name and contact number present at ${props.collection==='imports' ? 'dropoff' : 'loading'} location`,
            fields: [
                {
                    type: 'dynamicFields',
                    label: 'POC (Point of Contact)',
                    name: 'pointOfContact',
                    initialValue: [{contactName: '', contactPhone: ''}],
                    minEntries: 1,
                    fields: [
                        {
                            type: 'input',
                            label: 'Contact Person Name',
                            name: 'contactName',
                            required: true,
                            message: 'Contact person name is required.',
                            inputType: 'text'
                        },
                        {
                            type: 'phoneNumber',
                            label: 'Phone Number',
                            name: 'contactPhone',
                            required: true,
                            message: 'Phone number is required.',
                            addonBefore: '+92'
                        }
                    ],
                }
            ]
        }
    ]
}

export const ContainersRelatedFormSchema = (props) => {
    return [
        {
            title: 'Shipping Documents',
            description: `Fill in information related to ${props.collection==='imports' ? "Bill of Lading " : 'Container Release Order.'}`,
            fields: [
                [
                    {
                        type: 'input',
                        label: props.collection==='imports' ? "Bill of Lading (B/L) Number" : 'CRO / Booking Number',
                        name: props.collection==='imports' ? 'blNumber' : 'croNumber',
                        required: true,
                        message: props.collection==='imports' ? 'BL number is required.' : 'CRO/Booking number is required.',
                        inputType: 'text',
                    },
                    {
                        type: 'input',
                        label: 'Shipping Line Name',
                        name: 'shippingLine',
                        required: true,
                        message: 'Shipping line is required.',
                        inputType: 'text',
                    },
                ],
                {
                    type: 'upload',
                    label: props.collection==='imports' ? 'Bill of Lading Document' : 'CRO Document',
                    name: props.collection==='imports' ? 'blDocument' : 'croDocument',
                    uploadLink: props?.documentId,
                    // required: true,
                    // message: props.collection==='imports' ? 'BL document is required.' : 'CRO/Booking document is required.',
                }
            ]
        },
        TimesFormSchema({collection: props.collection}),
        {
            ...(props.collection==='exports' && {
                title: 'Cargo Information',
                description: 'Fill in information related to freight/cargo.',
                fields: [
                    [
                        {
                            type: 'input',
                            label: 'Cargo Type',
                            name: 'freightType',
                            required: true,
                            message: 'Freight type is required.',
                            inputType: 'text',
                        },
                        {
                            type: 'number',
                            label: 'Total Shipment Weight',
                            name: 'freightWeight',
                            addonAfter: 'Tons',
                            minValue: 1,
                            required: true,
                            message: 'Total cargo weight is required.',
                            tooltip: 'Enter total/cumulative freight weight. This weight will be divided according to container size.'
                        }
                    ]
                ]
            })
        },
    ]
}

export const BulkShipmentFormSchema = (props) => {
    return [ {
                title: 'Cargo Information',
                description: 'Fill in information related to freight/cargo.',
                fields: [
                    [
                        {
                            type: 'select',
                            label: 'Freight Type',
                            name: ['shipmentInfo', 'freightType'],
                            required: true,
                            message: 'Freight type is required.',
                            options: [
                                { text: 'Cement', id: 'cement'},
                                { text: 'Coal', id: 'coal'},
                                { text: 'Wood', id: 'wood'},
                                { text: 'Baggas', id: 'baggas'},
                                { text: 'Steel', id: 'steel'},
                                { text: 'Others', id: 'others'},
                            ],
                            displayProperty: 'text',
                            valueProperty: 'id',
                        },
                        {
                            type: 'number',
                            label: 'Freight Weight / Count',
                            dynamicAddOn: true,
                            name: ['shipmentInfo', 'freightValue'],
                            addonAfter: freightAddOnAfterSelect,
                            minValue: 1,
                            required: true,
                            message: 'A value for freight weight / count is required.',
                            tooltip: 'Select either weight or count for freight.'
                        },
                    ],
                    [
                        // {
                        //     type: 'number',
                        //     label: 'Freight Count',
                        //     name: ['shipmentInfo', 'freightCount'],
                        //     minValue: 1,
                        //     tooltip: 'Enter count of freight packaging'
                        // },
                        {
                            type: 'dateTime',
                            label: 'Expected Loading Date',
                            name:  ['shipmentInfo', 'loadingDT'],
                            required: true,
                            message: 'Loading date is required.',
                            format: 'DD-MM-YYYY',
                            showTime: false,
                            disabledDate: current => current && current < moment().subtract(1,'days').endOf('day'),
                            disabledTime: date => { return {
                                disabledHours: () => range(0, 24).splice(0, (!date || date.isSame(moment(), 'day')) ? moment().hours() : 0),
                                disabledMinutes: () => range(0, 60).splice(0, (!date || (date.isSame(moment(), 'day') && date.isSame(moment(), 'hours'))) ? moment().minutes() : 0)
                            }},
                        },
                    ]
                ]
            }
        
    ]
}

const freightAddOnAfterSelect = (
    <Form.Item
        key={'freightCategory'}
        name='freightCategory'
        initialValue='weight'
        noStyle
    >
        <Select defaultValue='weight'>
            <Select.Option value='weight'>Weight (Tons)</Select.Option>
            <Select.Option value='count'>Count</Select.Option>
        </Select>
    </Form.Item>
)

export const TimesFormSchema = (props) => {
    const Schema = {
        title: 'Shipment Times',
        description: `Enter Loading Time and ${props.collection==='imports' ? 'Number of Free Days.' : 'Vessel Cutoff Date'}`,
        fields: [
            [
                {
                    type: 'dateTime',
                    label: props.collection==='imports' ? 'Expected Clearance/Loading Date' : 'Expected Loading Date',
                    name: 'loadingTime',
                    required: true,
                    message: 'Loading date is required.',
                    format: props.collection==='imports' ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm' ,
                    showTime: props.collection!=='imports',
                    disabledDate: current => current && current < moment().subtract(1,'days').endOf('day'),
                    disabledTime: date => { return {
                        disabledHours: () => range(0, 24).splice(0, (!date || date.isSame(moment(), 'day')) ? moment().hours() : 0),
                        disabledMinutes: () => range(0, 60).splice(0, (!date || (date.isSame(moment(), 'day') && date.isSame(moment(), 'hours'))) ? moment().minutes() : 0)
                    }},
                },
            ]
        ]
    }
    props.collection==='imports' && Schema.fields[0].push(
        {
            type: 'number',
            label: 'Number of Free Days',
            name: 'freeDays',
            // required: true,
            // message: 'Number of free days are required.',
            addonAfter: 'Days',
            minValue: 1,
        }
    )
    props.collection==='exports' && Schema.fields[0].push(
        {
            type: 'dateTime',
            label: 'Vessel Cut-Off Time',
            name: 'vesselCutoffDT',
            required: true,
            message: 'Vessel cut-off is required.',
            format: 'DD-MM-YYYY HH:mm',
            disabledDate: current => current && current < moment().subtract(1,'days').endOf('day'),
            disabledTime: date => { return {
                disabledHours: () => range(0, 24).splice(0, (!date || date.isSame(moment(), 'day')) ? moment().hours() : 0),
                disabledMinutes: () => range(0, 60).splice(0, (!date || (date.isSame(moment(), 'day') && date.isSame(moment(), 'hours'))) ? moment().minutes() : 0)
            }},
        }
    )
    return Schema
}

export const AdditionalRequestsSchema = (props) => {
    return [
        {
            type: 'emails', 
            name: 'selectedEmails', 
            label: 'Emails',
            required: true,
            message: 'Atleast 1 email is required.',
            placeholder: 'Select email addresses or type to add new',
            options: props.emails,
            mode: 'tags',
            tokenSeparators: [',', ' ']
        },
        {
            title: 'Special Requests',
            description: 'Answer questions related to special requests.',
            fields: [
                {...((props.collection!=='bulk' || props.vehicleCategory?.[1]?.vehicleSubtype==='containerized') && {
                    type: 'radioGroup',
                    label: 'Please select type of container?',
                    name: 'reefer',
                    required: true,
                    message: 'Answer is required.',
                    dataObject: {
                        normal: {key: 'normal', long: 'Normal'},
                        reefer: {key: 'reefer', long: 'Reefer (Refrigerated container)'}
                    },
                    layout: 'horizontal'})
                },
                {
                    ...(props.collection!=='bulk' && {
                    type: 'radioGroup',
                    label: 'Do you want fast lane delivery (Inaam Delivery)?',
                    name: 'fastLane',
                    required: true,
                    message: 'Answer is required.',
                    dataObject: {
                        yes: {key: 'yes', long: 'Yes'},
                        no: {key: 'no', long: 'No'}
                    },
                    layout: 'horizontal'})
                },
                {
                    ...((props.collection==='imports') && {
                        type: 'radioGroup',
                        label: 'Do you want Bridgelinx to handle Delivery Order (DO) issuance?',
                        name: 'doIssuance',
                        required: true,
                        message: 'Answer is required.',
                        dataObject: {
                            yes: {key: 'yes', long: 'Yes'},
                            no: {key: 'no', long: 'No'}
                        },
                        layout: 'horizontal'
                    })
                },
                {
                    ...((props.collection==='imports') && {
                        type: 'radioGroup',
                        label: 'Is this a shifting/destuffing order?',
                        name: 'orderCategory',
                        required: true,
                        message: 'Answer is required.',
                        dataObject: {
                            normal: {key: 'normal', long: 'Direct Seal Method'},
                            shifting: {key: 'shifting', long: 'Shifting'},
                            destuffing: {key: 'destuffing', long: 'Destuffing'},
                        },
                        layout: 'horizontal'
                    })
                },
                {
                    ...(props.collection==='imports' && {
                        type: 'dependency',
                        name: 'flatbeds',
                        independent: 'orderCategory',
                        condition: ['destuffing'],
                        successSchema: [
                            [
                                {
                                    type: 'number',
                                    label: 'Enter number of flatbeds',
                                    name: 'numberFlatbeds',
                                    required: true,
                                    message: 'Number of flatbeds is required.',
                                    // inputType: 'text',
                                },
                            ]
                        ],
                        failureSchema: [
                            {
                            }
                        ]
                    })
                },
                {
                    ...(props.collection==='imports' && {
                        type: 'dependency',
                        name: 'localMovementDependency',
                        independent: 'orderCategory',
                        condition: ['normal'],
                        successSchema: [
                            [
                                {
                                    type: 'radioGroup',
                                    label: 'Is this a local movement order?',
                                    name: 'localMovement',
                                    required: true,
                                    message: 'Answer is required.',
                                    dataObject: {
                                        yes: {key: true, long: 'Yes'},
                                        no: {key: false, long: 'No'},
                                    },
                                    layout: 'horizontal'
                                }
                            ]
                        ],
                        failureSchema: [
                            {
                            }
                        ]
                    })
                },
                {
                    ...(props.collection==='bulk' && {
                    type: 'radioGroup',
                    label: 'Is this a lot order?',
                    name: 'lotOrder',
                    required: true,
                    message: 'Answer is required.',
                    dataObject: {
                        yes: {key: 'yes', long: 'Yes'},
                        no: {key: 'no', long: 'No'}
                    },
                    layout: 'horizontal'})
                },
                {
                    ...(props.collection==='bulk' && {
                        type: 'dependency',
                        name: 'vehiclesDependency',
                        independent: 'lotOrder',
                        condition: ['no'],
                        successSchema: [
                            [
                                {
                                    type: 'number',
                                    label: 'Enter number of vehicles',
                                    name: 'numberVehicles',
                                    required: true,
                                    message: 'Number of vehicles is required.',
                                    minValue: 1,
                                    // inputType: 'text',
                                },
                            ]
                        ],
                        failureSchema: [
                            {
                            }
                        ]
                    })
                },
                {
                    type: 'input',
                    label: 'Other Requests',
                    name: 'otherRequests',
                    inputType: 'textArea',
                    rows: 5
                },
            ]
        },
    ]
}