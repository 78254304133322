import React, { useEffect, useState } from 'react'
import { Layout, Button, Card, Form } from 'antd'
import { Breadcrumb } from 'antd'
import { firestore } from '../../firebase/firebaseConfig'

import { useRecoilValue } from 'recoil'

import { Loader, CurrentCollection, NumberedCardHeader } from '../../helpers/Functions'
import { loaderAtom } from '../../recoil/atoms'

import { RenderSchema } from '../../components/FormRenderer'

import ModalButton from '../../components/ModalButton'

import { BulkShipmentFormSchema, AdditionalRequestsSchema } from '../../components/FormSchemas'
import { VehicleSubtypeEnum, VehiclesEnum, VehicleTypeEnum } from '../../helpers/Functions'
import SummaryModal from '../../components/BulkOrders/SummaryModal'
import { suggestionsSelector } from '../../recoil/selectors'
import Sprite from '../../assets/icons/Sprite.svg'

const { Header, Content } = Layout

const NewBulkOrder = (props)  => {
	const collection = CurrentCollection(props?.location?.pathname)
	const [form] = Form.useForm()

    const loader = useRecoilValue(loaderAtom)
	const Suggestions = useRecoilValue(suggestionsSelector(collection))

	const [documentId, setDocumentId] = useState('')
	const [loadingLocations, setLoadingLocations] = useState([])
	const [dropoffLocations, setDropoffLocations] = useState([])
	const [vehicleCategory, setVehicleCategory] = useState([])
	const [clearingAgents, setClearingAgents] = useState([])
	const emails = Suggestions ? Suggestions.emails : []

	const [summaryModalData, setSummaryModalData] = useState({visible: false, data: {}, modalSize: 'RectangleModal'})
	const [errorList, setErrorList] = useState({
		Locations : {key: 'Locations', value: false, type: 'array'},
		ContainersRelatedInfo: {key: 'ContainersRelatedInfo', value: false, type: 'form', checkValues: ['freightType', 'freightWeight', 'loadingDT']},
		vehicleCategory: {key: 'VehicleCategory', value: false, type: 'array', checkValues: ['vehicleCategory']},
		Emails: {key: 'Emails', value: false, type: 'form', checkValues: ['selectedEmails', 'fastLane', 'doIssuance']}
	})

	useEffect(() => {
        const document = firestore.collection('long_haul').doc()
        setDocumentId(document.id)
		props?.history?.location?.state && PopulateDuplicateData(props.history.location.state.DuplicateData)
    },[]) // eslint-disable-line

	// const prepareContactsData = (contacts) => (
	// 	contacts.map(poc => {return ( {...poc, contactPhone: parseInt(poc.contactPhone.replace('+92', ''))} )})
	// )
	const prepareLocationsData = (data, type) => {
		const Locations = data[type] ? Object.values(data[type]).sort((a,b) => a.number - b.number) : []
		return Locations.map(location => {
			const Location = {...location}
			if(Location?.newAddress) {
				Location.millAddress = Location.formattedAddress
				Location.millName = Location.additionalDetails
				Location.formattedAddress = 'Other'
			}
			const PointOfContact = []
			if(type==='loading') {
				PointOfContact.push(...[{contactName: location.contactName, contactPhone: location.contactPhone}])
			} else if (type==='dropOff') {
				(PointOfContact.push(...[{contactName: location.contactName, contactPhone: location.contactPhone}]))
			}
			return { ...Location , pointOfContact: (PointOfContact || [])}
		})
	}
	const PopulateDuplicateData = (DuplicateData) => {
		setLoadingLocations(prepareLocationsData(DuplicateData, 'loading'))
		setDropoffLocations(prepareLocationsData(DuplicateData, 'dropOff'))
		vehicleCategory.push({vehicleType: DuplicateData.vehicleInfo.vehicleType})
		DuplicateData.vehicleSubtype!=='' && vehicleCategory.push({vehicleSubtype: DuplicateData.vehicleInfo.vehicleSubtype})
		form.setFieldsValue({selectedEmails: DuplicateData?.clientEmail})
	}

	const BulkLocationsCard = () => (
		<Card 
			title={NumberedCardHeader('Loading and Dropoff Locations', 'Enter information for dropoff and all your loading locations.', 1)} 
			className={`SixtyFourtyWidth MarginBottom ${errorList.Locations.value===true ? 'Error' : ''}`}
		>
			<div className='Padding'>
				{	loadingLocations.map((location, index) =>
						<ModalButton
							key={index}
							buttonText={loadingLocations.length===1 ? 'Loading Location' : `Loading Location # ${index+1}`} 
							data={loadingLocations} 
							setdata={setLoadingLocations}
							additionalData={{clearingAgents: clearingAgents, setClearingAgents: setClearingAgents}}
							targetIndex={index} 
							type='export_loading'
							action='edit'
							deleteOption={true}
							collection={collection}
						/>
				)}
				{	dropoffLocations.map((location, index) =>
						<ModalButton
							key={index}
							buttonText={dropoffLocations.length===1 ? 'Dropoff Location' : `Dropoff Location # ${index+1}`}
							data={dropoffLocations} 
							setdata={setDropoffLocations}
							targetIndex={index} 
							type='import_dropoff'
							action='edit'
							deleteOption={true}
							collection={collection}
						/>
				)}
				{	loadingLocations.length===0 &&
					<ModalButton
						key={'loading_add'}
						data={loadingLocations} 
						setdata={setLoadingLocations}
						targetIndex={loadingLocations.length}
						type='export_loading'
						action='add'
						collection={collection}
					/>
				}
				{	loadingLocations.length>0 && 
					<ModalButton
						key={'dropoff_add'}
						data={dropoffLocations} 
						setdata={setDropoffLocations}
						targetIndex={dropoffLocations.length} 
						type='import_dropoff'
						action='add'
						collection={collection}
					/>
				}
			</div>
		</Card>
	)

	const ShipmentDetailsCard = () => (
		<Card
			title={NumberedCardHeader('Freight Details', 'Enter all information related to freight.', 2)}
			className={`SixtyFourtyWidth MarginBottom ${errorList.ContainersRelatedInfo.value===true ? 'Error' : ''}`}
		>
			<div className='Padding' style={{backgroundColor: '#FAFAFA'}}>
				{RenderSchema(BulkShipmentFormSchema({collection: collection, documentId: documentId}))}
			</div>
		</Card>
	)

	const VehicleCategoryCard = () => (
		<Card 
			title={NumberedCardHeader('Vehicle Category', 'Select vehicle type (and sub-type).', 3)}
			className={`FullHeightBody SixtyFourtyWidth MarginBottom ${errorList.vehicleCategory.value===true ? 'Error' : ''}`}
		>
			<div className='FullHeight Padding FlexColumnBox FlexColumnSpace'>
				<div></div>
				{	vehicleCategory.length===0 ? 
					<div className='FlexColumnBox'>
						<svg className='FourtySixtyWidth'><use href={Sprite + '#novehicle'}/></svg>
						<p className='Heading HalfMargin'>No Vehicle Selected</p>
					</div> :
					<div className='FlexColumnBox MarginBottom'>
						<svg className='QuarterHeight FourtySixtyWidth'>
							<use href={ 
								vehicleCategory[1]?.vehicleSubtype ?
								Sprite + VehiclesEnum[vehicleCategory[0]?.vehicleType]?.[vehicleCategory[1]?.vehicleSubtype].icon :
								Sprite + VehicleTypeEnum[vehicleCategory[0]?.vehicleType]?.icon
							}/>
						</svg>
						<p className='BoldFont FontSize18 HalfMarginTop'>{VehicleTypeEnum[vehicleCategory[0]?.vehicleType]?.long} {(vehicleCategory[1]?.vehicleSubtype && vehicleCategory[1]?.vehicleSubtype.toString().length>0) ? `- ${VehicleSubtypeEnum[vehicleCategory[1]?.vehicleSubtype]?.text}` : ''}</p>
					</div>
				}

				<ModalButton
					key={vehicleCategory.length===0 ? 'vehicleType_add' : 'vehicleType_edit'}
					data={vehicleCategory}
					setdata={setVehicleCategory}
					type='vehicle'
					action={vehicleCategory.length===0 ? 'add' : 'edit'}
					showLeftIcon={false}
					showRightIcon={false}
					centerText = {true}
					showSummary={false}
					collection={collection}
				/>
			</div>
		</Card>
	)

	const EmailsCard = () => (
		<Card 
			id='EmailsCard'
			title={NumberedCardHeader('Additional Requests', 'Enter or select emails and provide special request information.', 4)}
			className={`SixtyFourtyWidth MarginBottom ${errorList.Emails.value===true ? 'Error' : ''}`}
		>
			<div className='PaddingExceptBottom' style={{backgroundColor: '#FAFAFA'}}>
				{RenderSchema(AdditionalRequestsSchema({collection: collection, vehicleCategory: vehicleCategory, emails: emails}))}
			</div>
		</Card>
	)

	const ErrorChecks = (errorFields) => {
		let Flags = JSON.parse(JSON.stringify(errorList))
		Object.values(Flags).forEach((flag, index, FlagsCallback)=> {
			if(flag.type==='array') {
				if(flag.key==='Locations') {
					FlagsCallback[index].value = (loadingLocations.length===0 || dropoffLocations.length===0)
				}else if(flag.key==='VehicleCategory') {
					FlagsCallback[index].value = (vehicleCategory.length===0)
				}
			} else if (flag.type==='form') {
				FlagsCallback[index].value = errorFields?.some( error => flag.checkValues.includes(error['name'][0]) ) || false //Raise flag if even one of checkvalues' key is in errorFields
			}
		})
		if (Object.values(Flags).some((flag, index) => flag.value!==Object.values(errorList)[index].value)) {
			setErrorList(Flags)
			form.submit()
		}
		// console.log(Flags)
		return Object.values(Flags).every(flag => !flag.value) // returns true if all flags are false (no problem/flag raised)
	}

	const onFinish = (values) => {
		if(ErrorChecks()) {
			let shipmentDetails = {...values.shipmentInfo, freightCategory: values.freightCategory}
			let specialRequests={
				reefer: values.reefer==='reefer',
				emails: values.selectedEmails,
				lotOrder: values.lotOrder,
				numberVehicles: values.numberVehicles,
				otherRequests: values.otherRequests
			}

			const ModalData = {
				loadingLocations: loadingLocations,
				dropoffLocations: dropoffLocations,
				shipmentDetails: shipmentDetails,
				vehicleCategory: vehicleCategory,
				specialRequests: specialRequests,
				user: props.user,
			}

			setSummaryModalData({
				visible: true,
				collection: collection, 
				data: ModalData,
				modalSize: 'RectangleModal'
			})
		}
	}

	return (
        <Layout>
			<Header>
                <Breadcrumb separator='>'>
                    <Breadcrumb.Item className='Heading'><a href={`/${collection}`}>{'Bulk Orders'}</a></Breadcrumb.Item>
                    <Breadcrumb.Item className='Heading'>Create</Breadcrumb.Item>
                </Breadcrumb>
                <Button className='Button PrimaryButton' onClick={() => {form.submit()}}> Submit </Button>
			</Header>
			{	loader ? <Loader/> :
				<Content>
					<Form
						layout='vertical'
						form={form}
						onFinish={onFinish}
						onFinishFailed={({errorFields}) => ErrorChecks(errorFields)}
					>
						<div className='FlexColumnBox'>
							<BulkLocationsCard/> 
							<ShipmentDetailsCard/>
							<VehicleCategoryCard/>
							<EmailsCard/>
						</div>
					</Form>
				</Content>
			}
			<SummaryModal {...props} modalData={summaryModalData} setModalData={setSummaryModalData}/>
		</Layout>
	)
}

export default NewBulkOrder