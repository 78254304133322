import { check, ReadableString, VehicleTypeEnum, VehicleSubtypeEnum, ContainerDimensionsEnum, LocalOrderStatus, ExportOrderStatus, DriverJobStatus } from './Functions'
import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'

export const OrderNumber = (collection) => {
    return {	
        title: 'Order',
        dataIndex: 'orderNumber',
        align: 'center',
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'descend',
        width: '300px',
        sorter: (a, b) => a.orderNumber && b.orderNumber && (parseInt(a.orderNumber) - parseInt(b.orderNumber)),
        render: (text, record) => {
            return  record.subOrders ? 
                    <>
                        <p className='BoldFont'><span className='OrderNumberLink'>{record.orderNumber}</span></p> 
                        {(record?.specialRequests?.orderCategory==='shifting' || record?.specialRequests?.orderCategory==='destuffing') && <p className={`Capitalize BoldFont ${record?.specialRequests?.orderCategory==='destuffing' ? 'MustardColor' : 'SuccessColor'}`}>{record?.specialRequests?.orderCategory} Order</p>}
                        <p><span className='BoldFont'>Creation Date: </span>{moment(record?.subOrders[0]?.createdAt, 'x').format('DD-MM-YYYY hh:mm:ss')}</p>
                        {collection==='exports' && <p><span className='BoldFont BlackColor'>Vessel Cutoff Date: </span>{moment(record?.subOrders[0]?.vesselCutoffDT, 'x').format('DD-MM-YYYY')}</p>}
                    </>
                    :
                    <>
                        <Tooltip placement='right' color='#1C75BC' title='View Details'>
                            <Link className='OrderNumberLink OrderNumberLinkHover' to={`/${collection}/${record._id}/view`}>{record.subOrderNumber || record.orderNumber}</Link>
                        </Tooltip>
                        {(record?.specialRequests?.orderCategory==='shifting' || record?.specialRequests?.orderCategory==='destuffing') && <p className={`Capitalize BoldFont ${record?.specialRequests?.orderCategory==='destuffing' ? 'MustardColor' : 'SuccessColor'}`}>{record?.specialRequests?.orderCategory} Order</p>}
                        <p><span className='BoldFont'>Creation Date: </span>{moment(record?.createdAt, 'x').format('DD-MM-YYYY hh:mm:ss')}</p>
                        {collection==='exports' && <p><span className='BoldFont BlackColor'>Vessel Cutoff Date: </span>{moment(record?.vesselCutoffDT, 'x').format('DD-MM-YYYY')}</p>}
                    </>
        }
    }
}

export const LocalLocation = (type) => {
    return {	
        title: type==='pickup' ? 'Pickup Location' : 'Last Dropoff Location',
        key: type,
        render: (text, record) => 
            <p className='LineLimit3'>{
                type==='pickup' ? 
                    Object.values(record.clientCoordinates.pickUp)[0].formattedAddress: 
                    Object.values(record.clientCoordinates.dropOff).sort((a,b) => b.number - a.number)[0].formattedAddress
            }</p>
    }
}

export const LocalDriverDetails = {	
    title: 'Driver',
    key: 'driver',
    width: '228px',
    render: (text, record) => (
        <div className='Capitalize'>
            {	record.assignedDriverType==='corporate' &&
                record?.driver?.profile ? 
                    <>
                        <p className='TableLinks'>{`${record.driver.profile.firstName} ${record.driver.profile.lastName}`}</p>
                        <p className=' RegularFont'>{record.driver.profile.phoneNumber}</p>
                    </> : 
                    <>
                        <p className='TableLinks'>Bridgelinx Driver</p>
                        <p className=' TitleDescription'>Contact Bridgelinx Support</p>
                    </>
            }
            {	record?.driver?.profile && 
                record.driverStatus && 
                <p className='BoldFont SecondaryShadeColor'>● {ReadableString(record.driverStatus.status)}</p> 
            }
        </div>
    )
}

export const VehicleDetails = (collection) => { 
    return {
        title: collection==='orders' ? 'Vehicle' : 'Assigned Vehicle',
        key: 'vehicle',
        width: '168px',
        render: (text, record) => (
            <div className='Capitalize'>
                { record.vehicle && <p className='TableLinks'>{record.vehicle.registrationNumber}</p> }
                { record.vehicleType && <p className='RegularFont'>{`${ VehicleTypeEnum[record.vehicleType]?.text} ${record.vehicleSubtype && (' - ' + record.vehicleSubtype)}`}</p> }
            </div>
        )
    }
}

export const LocalJobStatus = {
    title: 'Job Status',
    key: 'jobStatus',
    align: 'center',
    width: '144px',
    render: (text, record) => ( LocalOrderStatus(record.jobStatus?.status) ),
}

export const ImportExportLocation = (collection, type) => {
    return {
        title:  type==='pickup' ? 
                    (collection==='imports' ? 'Release Terminal' : 'First Loading Location') : 
                    (collection==='imports' ? 'Last Dropoff Location' : 'Dropoff Location'),
        key: type,
        render: (text, record) => 
            <p className='LineLimit3'>{
                type==='pickup' ? 
                Object.values(record.loading).sort((a,b) => a.number - b.number)[0].formattedAddress :
                Object.values(record.dropOff).sort((a,b) => b.number - a.number)[0].formattedAddress
            }</p>
    }
}

export const LongHaulLocation = (type) => {
    return {
        title:  type==='pickup' ? 
                    ('Loading Location') : 
                    ('Dropoff Location'),
        key: type,
        render: (text, record) => 
            <p className='LineLimit3'>{
                type==='pickup' ? 
                record?.loading && Object.values(record?.loading)[0].formattedAddress && Object.values(record.loading).sort((a,b) => a.number - b.number)[0].formattedAddress :
                record?.dropOff && Object.values(record?.dropOff)[0].formattedAddress && Object.values(record.dropOff).sort((a,b) => b.number - a.number)[0].formattedAddress
            }
            </p>
    }
}

export const ImportExportDriver = {	
    title: 'Driver',
    key: 'driver',
    width: '228px',
    render: (text, record) => (
        record.subOrders ? 
            <>
                <p className='TableLinks'>Multiple</p>
                <p className=' RegularFont FontSize12'>Click on + sign for details</p>
            </> :
            record.driver?.driverPhone &&
            <div className='Capitalize'>
                <p className='TableLinks'>{record.driver.driverName}</p>
                <p className=' RegularFont'>{record.driver.driverPhone}</p>
                <p className='RegularFont'>{record.driver.driverVehicleRegistration}</p>
                {   record.driverStatusHistory && record.driverStatusHistory[record.driverStatusHistory.length-1]?.status &&
                    <p className='BoldFont SecondaryShadeColor'>● {ReadableString(record.driverStatusHistory[record.driverStatusHistory.length-1].status.replace('driver', ''))}</p>
                }
            </div>
    )
}

export const ImportExportContainer = {
    title: 'Container',
    key: 'container',
    width: '168px',
    render: (text, record) => (
        (record.subOrders || record.linkedImportOrders) ? 
            <>
                <p className='TableLinks'>Multiple</p>
                <p className=' RegularFont FontSize12'>Click on + sign for details</p>
            </> :
            <div className='Capitalize'>
                <p className='TableLinks'>
                    {	check(record.containerInfo?.containerDimensions) ?
                            record.containerInfo.containerNumber :
                            record.containerInfo && Object.values(record.containerInfo)?.[0]?.containerNumber
                    }
                </p>
                <p className='TableLinks'>
                    {	check(record.containerInfo?.containerDimensions) ?
                            record.containerInfo.containerNumber :
                            record.containerInfo && Object.values(record.containerInfo)?.[1]?.containerNumber
                    }
                </p>
                <p className='RegularFont'>
                    {	check(record.containerInfo?.containerDimensions) ?
                            ContainerDimensionsEnum[record.containerInfo.containerDimensions].long :
                            record.containerInfo && ContainerDimensionsEnum[Object.values(record.containerInfo)[0].containerDimensions].long
                    }
                </p>
            </div>
    )
}

export const ImportExportStatus = {
    title: 'Status',
    dataIndex: 'orderStatus',
    align: 'center',
    width: '144px',
    render: (text, record) => ( 
        record.subOrders? 
            <p className='TableLinks'>Multiple</p> :
            record?.orderStatus?.status && ExportOrderStatus(record.orderStatus.status) 
    ),
}

export const DriversDetails = (setDriverModal) => {
    return {
        title: 'Driver Details',
        dataIndex: 'driverDetails',
        align: 'center',
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.orderNumber && b.orderNumber && (parseInt(a.orderNumber) - parseInt(b.orderNumber)),
        render: (text, record) => 
            <div className='Capitalize'>
                <Tooltip placement='right' color='#1C75BC' title='View Details'>
                    <div
                        className='TableLinks PrimaryColor CursorPointer' 
                        onClick={()=>setDriverModal({visible: true, action: 'edit', data: record})}
                    >
                        {`${record?.profile?.firstName} ${record?.profile?.lastName}`}
                    </div>
                </Tooltip>
                <p className='RegularFont'>{record?.primaryPhoneNumber}</p>
            </div>
    }
}

export const DriversStatus = {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    width: '144px',
    render: (text, record) => ( DriverJobStatus(record.currentJob) ),
}

export const ShipmentDetails = {	
    title: 'Freight Details',
    key: 'shipment',
    width: '200px',
    render: (text, record) => {
        var sum = 0
        record?.subOrders?.forEach(subOrder=> {
            if(subOrder?.orderStatus?.status!=='orderCancelled'){
                sum = sum + (record?.shipmentInfo?.freightWeight ? parseFloat(subOrder?.shipmentInfo?.freightWeight) : parseFloat(subOrder?.shipmentInfo?.freightCount))
            } 
        })
        return  record.subOrders ? 
                    <div className='Capitalize'>
                        <p><span className='BoldFont'>Type: </span>{record?.shipmentInfo?.freightType}</p>
                        {/* {console.log(record)} */}
                        <p><span className='BoldFont'>Total {record?.shipmentInfo?.freightWeight ? ' Weight: ' : ' Count: '}</span>{record?.shipmentInfo?.freightWeight ? record?.shipmentInfo?.freightWeight+' Tons' : record?.shipmentInfo?.freightCount}</p>
                        {/* {console.log(record?.subOrders.reduce((prev, cur) => parseFloat(prev?.shipmentInfo?.freightWeight) + parseFloat(cur?.shipmentInfo?.freightWeight)))} */}
                        {record?.subOrders && record?.subOrders.length>0 && <p><span className='BoldFont'>{record?.shipmentInfo?.freightWeight ? 'Remaining Weight: ' : 'Remaining Count: '}</span>{record?.shipmentInfo?.freightWeight ? (parseFloat(record?.shipmentInfo?.freightWeight) - sum+' Tons'):(parseFloat(record?.shipmentInfo?.freightCount) - sum)}</p>}
                    </div> :
                record?.shipmentInfo &&
                    <div className='Capitalize'>
                        <p><span className='BoldFont'>Type: </span>{record?.shipmentInfo?.freightType}</p>
                        <p><span className='BoldFont'>{record?.shipmentInfo?.freightWeight ? 'Weight: ' : 'Count: '}</span>{record?.shipmentInfo?.freightWeight ? record?.shipmentInfo?.freightWeight+' Tons' : record?.shipmentInfo?.freightCount}</p>
                        {/* {record?.shipmentInfo?.freightCount && <p><span className='BoldFont'>Count: </span>{record?.shipmentInfo?.freightCount}</p>} */}
                    </div>
    }
}

export const VehicleCategory = {	
    title: 'Vehicle Category',
    key: 'vehicleCategory',
    width: '200px',
    render: (text, record) => (            
        record?.vehicleInfo &&
        <div className='Capitalize'>
            {   record?.vehicleInfo && 
                    <p className='BoldFont FontSize16 PrimaryColor'>{VehicleTypeEnum[record?.vehicleInfo?.vehicleType]?.long} {record?.vehicleInfo?.vehicleSubtype ? `- ${VehicleSubtypeEnum[record?.vehicleInfo?.vehicleSubtype]?.text}` : ''}</p>
            }
            {/* {   VehiclesEnum[record?.vehicleInfo?.vehicleType] &&
                <p><span className='BoldFont'>Tonnage: </span>
                    {record?.vehicleInfo?.vehicleSubtype ? VehiclesEnum[record?.vehicleInfo?.vehicleType]?.[record?.vehicleInfo?.vehicleSubtype].tonnage : VehiclesEnum[record?.vehicleInfo?.vehicleType]?.tonnage} Tons
                </p>
            }
            {   
                VehiclesEnum[record?.vehicleInfo?.vehicleType] &&
                <p><span className='BoldFont'>Dimensions: </span>
                    {record?.vehicleInfo?.vehicleSubtype ? VehiclesEnum[record?.vehicleInfo?.vehicleType]?.[record?.vehicleInfo?.vehicleSubtype].dimensions : VehiclesEnum[record?.vehicleInfo?.vehicleType]?.dimensions}
                </p>
            } */}
        </div>
    )
}