import React, { useEffect, useState } from 'react'
import { Select, Layout, Button, Card, Form, Radio} from 'antd'
import { Breadcrumb } from 'antd'
import moment from 'moment'
import { firestore } from '../../firebase/firebaseConfig'
import { GoogleMap, Marker } from '@react-google-maps/api'

import { useRecoilValue } from "recoil"

import { CaretRightOutlined } from '@ant-design/icons'
import { FormRender } from '../../components/FormRenderer';


import { PakistanPoints, BoundsZoomLevel, Loader, CurrentCollection, VehicleTypeEnum, NumberedCardHeader, VehicleSubtypeEnum, VehiclesEnum, range} from '../../helpers/Functions'
import { loaderAtom } from '../../recoil/atoms'

import ModalButton from '../../components/ModalButton'

import { corporateDriversSelector } from '../../recoil/selectors';
import SummaryModal from '../../components/LocalOrders/SummaryModal'

import Sprite from '../../assets/icons/Sprite.svg'

const { Header, Content } = Layout

const NewOrder = (props)  => {
	const collection = CurrentCollection(props.location.pathname)
    const loader = useRecoilValue(loaderAtom)

	const [newOrderForm] = Form.useForm()
	const [documentId, setDocumentId] = useState('')
	const driversForJob = useRecoilValue(corporateDriversSelector)

	const [loadingLocations, setLoadingLocations] = useState([])
	const [dropoffLocations, setDropoffLocations] = useState([])
	const [vehicleCategory, setVehicleCategory] = useState([])
	const [scheduledSelected, setScheduledSelected] = useState(false)
	const [ownDriverSelected, setOwnDriverSelected] = useState(false)

	const bounds = new window.google.maps.LatLngBounds()
	const [boundsCenter, setBoundsCenter] = useState({lat: 31.5204489, lng : 74.3586184, zoomlevel: 10})

	const checks = {
		Locations: false, 
		VehicleCategory: false, 
		Times: false,
		DriverType: false
	}
	const [errorList, setErrorList] = useState(checks)
	const [summaryModalData, setSummaryModalData] = useState({visible: false, data: {}, modalSize: 'RectangleModal'})

	useEffect ( () => {
		PakistanPoints.map(point => bounds.extend({lat: parseFloat(point.lat), lng: parseFloat(point.lng)}))
		setBoundsCenter({lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: BoundsZoomLevel(bounds)})
        const document = firestore.collection('import_export').doc()
        setDocumentId(document.id)
		if(props.history.location.state) {
			PopulateDuplicateData(props.history.location.state.DuplicateData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

	useEffect ( () => {
		loadingLocations.map(location => bounds.extend({lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}))
		dropoffLocations.map(location => bounds.extend({lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}))
		setBoundsCenter({lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: BoundsZoomLevel(bounds)})
		// eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadingLocations, dropoffLocations])

	const PopulateDuplicateData = (DuplicateData) => {
		// console.log('Duplicate', DuplicateData)
		
		const AllPickupCoordinates = Object.values(DuplicateData?.clientCoordinates?.pickUp).sort((a,b) => a.number - b.number)
		const ClientImages = Object.values(DuplicateData?.clientImages)
        const PickupLocationsInfo = AllPickupCoordinates.map(location => {
            return {
				...location,
				coordinates: {lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)},
				contactPhone: parseInt(location.contactPhone.replace('+92', '')),
				...{
					...DuplicateData?.packageInfo[location._id],
					freightDetails: ['cement', 'coal', 'wood', 'baggas', 'steel'].includes(DuplicateData?.packageInfo[location._id]?.freightDetails.toLowerCase()) ?
									DuplicateData?.packageInfo[location._id]?.freightDetails : 'others'
				},
				clientUpload:	(ClientImages?.length>0 && ClientImages[0] && ClientImages[0][0]) ?
									[{ uid: '1', name: 'Document', status: 'done', url: ClientImages[0][0]}] : [],
			}
        })
        setLoadingLocations(PickupLocationsInfo)

		const AllDropoffCoordinates = Object.values(DuplicateData?.clientCoordinates?.dropOff).sort((a,b) => a.number - b.number)
        const DropoffLocationsInfo = AllDropoffCoordinates.map(location => {
            return {
				...location, 
				coordinates: {lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)},
				contactPhone: parseInt(location.contactPhone.replace('+92', '')),
				...{...DuplicateData?.packageInfo[location._id]}
			}
        })
        setDropoffLocations(DropoffLocationsInfo)

		vehicleCategory.push({vehicleType: DuplicateData.vehicleType})
		DuplicateData.vehicleSubtype!=='' && vehicleCategory.push({vehicleSubtype: DuplicateData.vehicleSubtype})

		// console.log({PickupLocationsInfo, DropoffLocationsInfo})
	}


	const LocationsCard = () => (
		<Card 
			title={NumberedCardHeader('Pickup and Dropoff Locations', 'Enter information of all your pickup and dropoff points.', 1)} 
			className={`FullWidth MarginBottom ${errorList.Locations===true ? 'Error' : ''}`}
		>
			<div className='DisplayFlex'>
                <div className='HalfWidth ThreeFourthSquareRatio'>
                    <div className='SolidifyRatio Padding OverflowScroll'>
						{	loadingLocations.map((location, index) =>
								<ModalButton
									key={index}
									buttonText={loadingLocations.length===1 ? 'Loading Location' : `Loading Location # ${index+1}`} 
									data={loadingLocations} 
									setdata={setLoadingLocations}
									targetIndex={index} 
									type='local_pickup'
									action='edit'
									map={true}
								/>
                        )}
                        {	dropoffLocations.map((location, index) =>
								<ModalButton
									key={index}
									buttonText={dropoffLocations.length===1 ? 'Dropoff Location' : `Dropoff Location # ${index+1}`}
									data={dropoffLocations} 
                                    setdata={setDropoffLocations}
									targetIndex={index} 
									type='local_dropoff'
									action='edit'
									map={true}
									deleteOption={true}
								/>
                        )}
                        {	loadingLocations.length===0 &&
							<ModalButton
								key={'pickup_add'} 
								data={loadingLocations} 
								setdata={setLoadingLocations}
								targetIndex={loadingLocations.length}
								additionalData={{documentId:documentId}}
								type='local_pickup'
								action='add'
								map={true}
							/>
						}
                        {	loadingLocations.length>0 &&
                            <ModalButton
                                key={'dropoff_add'}
                                data={dropoffLocations}
                                setdata={setDropoffLocations}
                                targetIndex={dropoffLocations.length}
                                type='local_dropoff'
                                action='add'
                                map={true}
                            />
                        }
						{/* {console.log({loadingLocations, dropoffLocations})} */}
                    </div>
                </div>
                <div className='HalfWidth ThreeFourthSquareRatio'>
                    <div className='SolidifyRatio'>
						<GoogleMap
							mapContainerClassName='FullWidthHeight'
							bounds={bounds}
							zoom={boundsCenter && boundsCenter.zoomlevel}
							center={{ lat: boundsCenter && boundsCenter.lat, lng: boundsCenter &&  boundsCenter.lng }}
							options={{ disableDefaultUI: true }}
						>
							{	loadingLocations.map((location, index) =>
								<Marker
									key={`Marker-${index}`}
									position={{lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}}
									icon={{ url: "https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-4.png?alt=media&token=c4b999ad-a6ab-49c8-ac7c-dba8a24eab9e" }}
								/>
							)}
							{	dropoffLocations.map((location, index) =>
								<Marker
									key={`Marker-${index}`}
									position={{lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}}
									icon={{ url: "https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-7.png?alt=media&token=26622ba5-db20-4e8d-8f17-2c75d83e9998" }}
								/>
							)}
						</GoogleMap>
                    </div>
                </div>
			</div>
		</Card>
	)
	
	const VehicleCategoryCard = () => (
		<Card 
			title={NumberedCardHeader('Vehicle Category', 'Select vehicle type (and sub-type).', 2)}
			className={`FullHeightBody HalfWidth HalfMarginRight ${errorList.VehicleCategory===true ? 'Error' : ''}`}
		>
			<div className='FullHeight Padding FlexColumnBox FlexColumnSpace'>
				<div></div>
				{	vehicleCategory.length===0 ? 
					<div className='FlexColumnBox'>
						<svg className='HalfHeight'><use href={Sprite + '#novehicle'}/></svg>
						<p className='Heading HalfMarginTop'>No Vehicle Selected</p>
					</div> :
					<div className='FlexColumnBox'>
						<svg className='QuarterHeight'>
							<use href={ 
								vehicleCategory[1]?.vehicleSubtype ?
								Sprite + VehiclesEnum[vehicleCategory[0]?.vehicleType]?.[vehicleCategory[1]?.vehicleSubtype].icon :
								Sprite + VehicleTypeEnum[vehicleCategory[0]?.vehicleType]?.icon
							}/>
						</svg>
						<p className='BoldFont FontSize18 HalfMarginTop'>{VehicleTypeEnum[vehicleCategory[0]?.vehicleType]?.long} {vehicleCategory[1]?.vehicleSubtype.toString().length>0 ? `- ${VehicleSubtypeEnum[vehicleCategory[1]?.vehicleSubtype]?.text}` : ''}</p>
					</div>
				}

				<ModalButton
					key={vehicleCategory.length===0 ? 'vehicleType_add' : 'vehicleType_edit'}
					data={vehicleCategory}
					setdata={setVehicleCategory}
					collection={collection}
					type='vehicle'
					action={vehicleCategory.length===0 ? 'add' : 'edit'}
					showLeftIcon={false}
					showRightIcon={false}
					centerText = {true}
					showSummary={false}
				/>
			</div>
		</Card>
	)

	const TimesCard = () => (
		<Card
			title={NumberedCardHeader('Times', 'Select order times.', 3)} 
			className={`HalfWidth HalfMarginLeft ${errorList.Times===true ? 'Error' : ''}`}
		>
			<div className='PaddingExceptBottom' style={{backgroundColor: '#FAFAFA'}}>
				<Form.Item name={'time'} initialValue={'instant'}>
					<Radio.Group
						defaultValue='instant'
						className='FullWidth'
						onChange={(event)=> setScheduledSelected(event.target.value==='scheduled')}
					>
						<Radio 
							value='instant'
							className='FullWidth Border BorderRadius Padding MarginBottom RadioOption'
						>
							<p className='Heading PrimaryColor HalfMarginBottom'>Instant Order</p>
							<p>Your order will picked up between</p>
							<p>
								<strong>{moment().add(1,'hours').minutes(0).format('hh:mm a')}</strong>
								<span> to </span>
								<strong>{moment().add(5,'hours').minutes(0).format('hh:mm a')}</strong>
							</p>
						</Radio>
						<Radio
							value='scheduled'
							className='FullWidth Border BorderRadius PaddingExceptBottom RadioOption'
						>
							<p className='Heading PrimaryColor'>Schedule Order</p>
							<p className='TitleDescription HalfMarginBottom'>Schedule order for future date and time.</p>
							{	FormRender(
									{
										type: 'dateTime',
										name: 'loadingTime',
										required: scheduledSelected,
										message: 'Data and time is required to schedule order.',
										format: 'DD-MM-YYYY HH:mm',
										disabled: !scheduledSelected,
										disabledDate: current=> current && current < moment().subtract(1,'days').endOf('day'),
										disabledTime: date =>	{ return {
																	disabledHours: () => range(0, 24).splice(0, (!date || date.isSame(moment(), 'day')) ? moment().hours()+5 : 0),
																	disabledMinutes: () => range(0, 60).splice(0, (!date || (date.isSame(moment(), 'day') && date.isSame(moment().add(5, 'hours'), 'hours'))) ? moment().minutes() : 0)
																}},
										showNow: false,
										defaultTimeValue: moment().add(5, 'hours')
									},
									'edit',
									'loadingLocation'
								)
							}
						</Radio>
					</Radio.Group>
				</Form.Item>
			</div>
		</Card>
	)

	const DriverTypeCard = () => (
		<Card
			title={NumberedCardHeader('Driver Type', 'Select type of driver you want assign to job.', 4)}
			className={`HalfWidth HalfMarginRight ${errorList.DriverType===true ? 'Error' : ''}`}
		>
			<div className='PaddingExceptBottom' style={{backgroundColor: '#FAFAFA'}}>
				<Form.Item name={'driverType'} initialValue={'bridgelinx'}>
					<Radio.Group
						defaultValue='bridgelinx'
						className='FullWidth'
						onChange={(event)=> setOwnDriverSelected(event.target.value==='own')}
					>
						<div className={`FullWidth Border BorderRadius Padding MarginBottom RadioOption ${!ownDriverSelected ? 'Checked' : ''}`}>
							<Radio value='bridgelinx'  className='FullWidthHeight'>
								<p className='Heading PrimaryColor'>Bridgelinx Driver</p>
								<p className='TitleDescription'>Use bridgelinx assigned drivers.</p>
							</Radio>
						</div>
						<div className={`FullWidthHeight Border BorderRadius PaddingExceptBottom RadioOption ${ownDriverSelected ? 'Checked' : ''}`}>
							<Radio value='own'>
								<p className='Heading PrimaryColor'>My Drivers</p>
								<p className='TitleDescription HalfMarginBottom'>I will use my own drivers.</p>
							</Radio>
							<Form.Item
								name={'driversList'}
								rules={[{ required: ownDriverSelected, message: 'Select driver from this list of you drivers.' }]}
							>
								<Select
									disabled={!ownDriverSelected}
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
									showSearch={true}
									placeholder='Select driver'
									className='SelectField'
									suffixIcon={ () => <CaretRightOutlined rotate={90}/>}
								>
									{	driversForJob.filter(driver=> 
											(driver.currentJob===null && driver.isOnline && driver.vehicleId!==null && !driver.driverBlocked) &&
											driver?.vehicle?.vehicleType===vehicleCategory?.[0]?.vehicleType && 
											(vehicleCategory?.[1]?.vehicleSubtype==='' ? true : driver?.vehicle?.vehicleSubtype===vehicleCategory?.[1]?.vehicleSubtype )
										).map((option, index) =>
											<Select.Option key={option._id} value={JSON.stringify(option)}>{`${option.profile.firstName} ${option.profile.lastName} _ ${option.primaryPhoneNumber}`}</Select.Option>
									)}
								</Select>
							</Form.Item>
						</div>
					</Radio.Group>
				</Form.Item>
			</div>
		</Card>
	)

	const ErrorChecks = (errorFields) => {
		let allDone = false
		let locationsFlag = errorList.Locations
		let vehicleCategoryFlag = errorList.VehicleCategory
		let timesFlag = errorList.Times
		let driverTypeFlag = errorList.DriverType

		if(loadingLocations.length===0 || dropoffLocations.length===0) { locationsFlag=true }
		else { locationsFlag = false }
		if(vehicleCategory.length===0) { vehicleCategoryFlag = true }
		else { vehicleCategoryFlag = false }
		if (errorFields && errorFields.find(error => ['loadingTime'].includes(error['name'][0]))) { timesFlag=true }
		else { timesFlag=false }
		if (errorFields && errorFields.find(error => ['driversList'].includes(error['name'][0]))) { driverTypeFlag=true } 
		else { driverTypeFlag=false }

		if(	locationsFlag!==errorList.Locations ||
			vehicleCategoryFlag!==errorList.VehicleCategory ||
			timesFlag!==errorList.Times ||
			driverTypeFlag!==errorList.DriverType
		) {
			setErrorList({
				Locations: locationsFlag, 
				VehicleCategory: vehicleCategoryFlag, 
				Times: timesFlag, 
				DriverType: driverTypeFlag
			})
			newOrderForm.submit()
		}
		// console.log({locationsFlag, vehicleCategoryFlag, timesFlag, driverTypeFlag})

		if(!locationsFlag && !vehicleCategoryFlag && !timesFlag && !driverTypeFlag) {
			allDone = true
		} else { allDone = false }
		// console.log(allDone)
		return allDone
	}

	const onFinish = (values) => {
		// console.log(values)

		if(ErrorChecks()) {
			setSummaryModalData({
				visible: true, 
				data: {
					documentId: documentId,
					form: values,
					loadingLocations: loadingLocations,
					dropoffLocations: dropoffLocations,
					vehicleCategory: vehicleCategory,
					user: props.user
				},
				modalSize: 'RectangleModal'
			})
		}
	}

	const onFinishFailed = ({errorFields}) => {
		ErrorChecks(errorFields)
	}

	return (
        <Layout>
			<Header>
                <Breadcrumb separator='>'>
                    <Breadcrumb.Item className='Heading'><a href={`/${collection}`}>Local Orders</a></Breadcrumb.Item>
                    <Breadcrumb.Item className='Heading'>Create</Breadcrumb.Item>
                </Breadcrumb>
                <Button className='Button PrimaryButton' onClick={() => newOrderForm.submit()}> Submit </Button>
			</Header>
			{	loader ? <Loader/> :
				<Content>
					<Form
						layout={'vertical'}
						form={newOrderForm}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>
                        {LocationsCard()}
                        <div className='DisplayFlex MarginBottom'>
                            {VehicleCategoryCard()}
							{TimesCard()}
                        </div>
						<div className='DisplayFlex'>
							{DriverTypeCard()}
                            <div className='HalfWidth HalfMarginLeft'></div>
                        </div>
					</Form>
				</Content>
			}
			<SummaryModal {...props} modalData={summaryModalData} setModalData={setSummaryModalData}/>
		</Layout>
	)
}

export default NewOrder