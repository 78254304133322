import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'antd'

import { RenderSchema } from './FormRenderer';
import { CardHeader, BoundsZoomLevel } from '../helpers/Functions'
import { GoogleMap, Marker } from '@react-google-maps/api'

import { userAtom } from '../recoil/atoms'
import { suggestionsSelector } from '../recoil/selectors'
import { useRecoilValue } from "recoil"

import Sprite from '../assets/icons/Sprite.svg'

import { LocalPickupSchema, LocalDropoffSchema, PortLocationSchema, LocationFormSchema, ReleaseTerminalFormSchema, ClearingAgentFormSchema, ContainerDimensionsFormSchema, DriverFormSchema } from './FormSchemas'
import { v4 } from 'uuid'
import '../App.css'

const FormModal = (props) => {
    const {  key, data, setdata, additionalData, targetIndex, type, action, map, deleteOption, collection } = props

    const bounds = new window.google.maps.LatLngBounds()
	const [boundsCenter, setBoundsCenter] = useState({lat: 31.5204489, lng : 74.3586184, zoomlevel: 10})

    const userData = useRecoilValue(userAtom)
    const Suggestions = useRecoilValue(suggestionsSelector(collection))

    const [locationCoordinates, setLocationCoordinates] = useState({lat: null, lng: null})
    const [form] = Form.useForm()

    useEffect ( () => {
        if( props.viewModal===true && (type==='local_pickup' || type==='local_dropoff') && action==='edit' && data[targetIndex]?.coordinates) {
            const coordinates = data[targetIndex].coordinates
            setLocationCoordinates({lat: coordinates.lat, lng: coordinates.lng})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.viewModal])

    useEffect ( () => {
        if(locationCoordinates.lat && locationCoordinates.lng) {
            bounds.extend({lat: parseFloat(locationCoordinates.lat), lng: parseFloat(locationCoordinates.lng)})
            setBoundsCenter({lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: BoundsZoomLevel(bounds)})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[locationCoordinates])
    
    const ModalSchema = {
        local_pickup: {
            add: { title: 'Add Pickup Location', description: 'Add Pickup Location description' },
            edit: { title: 'Edit Pickup Location', description: 'Edit Loading Location description' },
            renderSchema: LocalPickupSchema({
                formReference: form, 
                coordinates: locationCoordinates, 
                setLocationCoordinates: setLocationCoordinates, 
                documentId: additionalData?.documentId,
                fillData: { contactName: `${userData?.profile?.firstName} ${userData?.profile?.lastName}`, contactPhone: parseInt(userData?.phoneNumber.toString().replace('+92', '')) },
                clearData: { contactName: '', contactPhone: undefined }
            }),
        },
        local_dropoff: {
            add: { title: 'Add Dropoff Location', description: 'Edit Dropoff Location description' },
            edit: { title: 'Edit Dropoff Location', description: 'Edit Dropoff Location description' },
            renderSchema: LocalDropoffSchema({
                formReference:form, 
                coordinates: locationCoordinates, 
                setLocationCoordinates: setLocationCoordinates,
                fillData: { contactName: `${userData?.profile?.firstName} ${userData?.profile?.lastName}`, contactPhone: parseInt(userData?.phoneNumber.toString().replace('+92', '')) },
                clearData: { contactName: '', contactPhone: undefined }
            }),
        },
        import_loading: {
            add: { title: 'Add Port', description: 'The information is required to identify port of unlading' },
            edit: { title: 'Edit Port', description: 'The information is required to identify port of unlading' },
            renderSchema: PortLocationSchema({formReference: form, options: Suggestions?.loadingLocations}),
        },
        import_dropoff: {
            add: { title: 'Add Dropoff Location', description: 'Add Dropoff Location description' },
            edit: { title: 'Edit Dropoff Location', description: 'Edit Dropoff Location description' },
            renderSchema: LocationFormSchema({
                collection: collection,
                formReference: form, 
                options: Suggestions?.dropoffLocations,
                fillData: { contactName: `${userData?.profile?.firstName} ${userData?.profile?.lastName}`, contactPhone: parseInt(userData?.phoneNumber.toString().replace('+92', '')) },
                clearData: { contactName: '', contactPhone: undefined }
            }),
        },
        export_loading: {
            add: { title: 'Add Loading Location', description: 'Add Loading Location description' },
            edit: { title: 'Edit Loading Location', description: 'Edit Loading Location description' },
            renderSchema: LocationFormSchema({
                collection: collection,
                formReference: form, 
                options: Suggestions?.loadingLocations,
                fillData: { contactName: `${userData?.profile?.firstName} ${userData?.profile?.lastName}`, contactPhone: parseInt(userData?.phoneNumber.toString().replace('+92', '')) },
                clearData: { contactName: '', contactPhone: undefined }
            }),
        },
        export_dropoff: {
            add: { title: 'Add Dropoff Location', description: 'Edit Dropoff Location description' },
            edit: { title: 'Edit Dropoff Location', description: 'Edit Dropoff Location description' },
            renderSchema: PortLocationSchema({formReference: form, options: Suggestions?.dropoffLocations}),
        },
        release_terminal: {
            add: { title: 'Add Container Release Terminal Location', description: 'Edit Dropoff Location description' },
            edit: { title: 'Edit Container Release Terminal Location', description: 'Edit Dropoff Location description' },
            renderSchema: ReleaseTerminalFormSchema(),
        },
        clearingAgent: {
            add: { title: 'Add Clearing Agent Location', description: 'Add Clearing Agent Location description' },
            edit: { title: 'Edit Clearing Agent Location', description: 'Edit Clearing Agent Location description' },
            renderSchema: ClearingAgentFormSchema({...additionalData, collection: collection, formReference: form, options: Suggestions?.clearingAgents}),
        },
        containerDimensions: {
            add: { title: 'Select Container Dimensions', description: 'Add Clearing Agent Location description' },
            edit: { title: 'Edit Container Dimensions', description: 'Edit Clearing Agent Location description' },
            renderSchema: ContainerDimensionsFormSchema(),
        },
        driver: {
            add: { title: 'Add New Driver', description: 'Register your drivers.' },
            edit: { title: 'Edit Driver Details', description: 'Edit Clearing Agent Location description' },
            renderSchema: DriverFormSchema(),
        }
    }
    
    const onFinish = (values) => {
        const DataCopy = [...data]
        let NewData = {}
        if(action==='add') {
            if(type==='local_pickup') {
                NewData = {...values, _id: `pickup-${v4()}`, coordinates: locationCoordinates, type: 'pickup'}
            } else if (type==='local_dropoff') {
                NewData = {...values, _id: `dropoff-${v4()}`, coordinates: locationCoordinates, type: 'dropoff'}
            } else if (type==='export_loading' || type==='import_loading') {
                NewData = {...values, _id: `loading-${v4()}`, type: 'loading'}
            } else if (type==='export_dropoff' || type==='import_dropoff') {
                NewData = {...values, _id: `dropoff-${v4()}`, type: 'dropoff'}
            } else {
                NewData = {...values}
            }
        } else {
            if(type==='local_pickup' || type==='local_dropoff') {
                NewData = {...DataCopy[targetIndex], ...values, coordinates: locationCoordinates}
            } else {
                NewData = {...DataCopy[targetIndex], ...values}
            }
        }
        // console.log({DataCopy, NewData})
        DataCopy.splice(targetIndex, 1, NewData)
        setdata(DataCopy)
        onCancel()
    }

    const onCancel = () => {
        form.resetFields()
        props.setViewModal(false)
    }

    const onDelete = () => {
        const DataCopy = [...data]
        if (type==='export_loading') {
            const ClearingAgentsCopy = additionalData.clearingAgents.filter(clearingAgent => clearingAgent.loadingLocation!==DataCopy[targetIndex]._id)
            additionalData.setClearingAgents(ClearingAgentsCopy)
        }
        DataCopy.splice(targetIndex, 1)
        setdata(DataCopy)
        onCancel()
    }

    const ModalFooter = () => (
        <div className={`FullWidth ${deleteOption ? 'FlexRowSpace' : ''}`}>
            {   deleteOption &&
                <Button
                    className='FullWidthHeight Button DeleteButton CancelButton FlexRowBox' 
                    icon={<svg className='MiniIcon' style={{fill: '#F44336'}}><use href={Sprite + '#Delete'}/>
                </svg>}
                    onClick={onDelete}
                ></Button>
            }
            <div>
                <Button className='Button ModalFooterButton CancelButton' onClick={onCancel}>CANCEL</Button>
                <Button className='Button ModalFooterButton SubmitButton' onClick={()=>form.submit()}>SUBMIT</Button>
            </div>
        </div>
        
    )

    return (
        <Modal
            key={`Modal_${key}`}
            visible= {props.viewModal}
            title= {CardHeader(ModalSchema[type][action].title, ModalSchema[type][action].description)}
            closable={false}
            className={map ? 'RectangleModal' : 'SquareModal'}
            footer={<ModalFooter/>}
            getContainer={false}
        >
            <div className={map ? 'DisplayFlex FullHeight' : ''}>
                <div className={`Padding ${map ? 'HalfWidth OverflowScroll' : ''}`}>
                    <Form
                        key={`Form_${key}`}
                        layout={'vertical'}
                        form={form}
                        initialValues={{...data[targetIndex]}}
                        onFinish={onFinish}
                        scrollToFirstErrors
                    >
                        {/* {console.log(ModalSchema[type].renderSchema)} */}
                        {RenderSchema(ModalSchema[type].renderSchema)}
                    </Form>
                </div>
                { map &&
                    <div className='HalfWidth MapContainer'>
                        <GoogleMap
                            mapContainerClassName='FullWidthHeight'
                            bounds={bounds}
							zoom={boundsCenter && boundsCenter.zoomlevel}
							center={{ lat: boundsCenter && boundsCenter.lat, lng: boundsCenter &&  boundsCenter.lng }}
							options={{ disableDefaultUI: true }}
                        >
                            <Marker
                                key={`Marker-${key}`}
                                position={{lat: parseFloat(locationCoordinates.lat), lng: parseFloat(locationCoordinates.lng)}}
                                icon={{ url: "https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-4.png?alt=media&token=c4b999ad-a6ab-49c8-ac7c-dba8a24eab9e" }}
                            />
                        </GoogleMap>
                    </div>
                }
            </div>
        </Modal>
    )
}

export default FormModal