/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import {check} from '../helpers/Functions'

const SearchBar = (props) => {
	const [keyword, setKeyword] = useState('')
	const { data, setFilterData, filterProperty, collection } = props

	useEffect(() => {
		setKeyword('')
	}, [collection])
	
	useEffect(() => {
		setFilterData({data: searchFields(), collection: collection})
	},[data, keyword])

	const searchFields = () => {
		if(keyword.length>0) {
			if(typeof filterProperty === "object") {
				const AllPropertiesData = filterProperty.map( prop => {
					if(typeof prop === "string") {
						return data.filter((singleObj) => {
							if( check(singleObj[prop]) ) {
								return (singleObj[prop] + '').toLowerCase().includes(keyword.toLowerCase())
							}
						})
					} 
					else if (typeof prop === "object") {
						return data.filter((singleObj) => filterObject(singleObj,prop,0))
					}
				})
				let FilteredData = []
				for(let i = 0; i < AllPropertiesData.length; i++) {
					FilteredData = FilteredData.concat(AllPropertiesData[i])
				}
				const UniqueFilteredData = [...new Set(FilteredData)]
				return UniqueFilteredData
			} else {
				const FilteredData = data.filter((singleObj) => {
					if( check(singleObj[filterProperty]) ) {
						return (singleObj[filterProperty] + '').toLowerCase().includes(keyword.toLowerCase())
					}
				})
				return FilteredData
			}
		} else { return data }
	}

	const filterObject = (singleObj, prop, index) => {
		let flag = false
		if(singleObj[prop[index]] !== null && singleObj[prop[index]] !== undefined) {
			if(typeof singleObj[prop[index]] === 'object') {
				flag = filterObject(singleObj[prop[index]], prop, index + 1)
			} else if (typeof singleObj[prop[index]] === "string" || typeof singleObj[prop[index]] === "number"){
				return (singleObj[prop[index]] + '').toLowerCase().includes(keyword.toLowerCase())
			}
		}
		return flag
	}

	return ( <Input className='InputField MarginBottom BoldFont' type='text' value={keyword} placeholder={'Search'} onChange={(e) => setKeyword(e.target.value)} /> )
}

export default SearchBar