import React, { useEffect, useState } from 'react'
import { Layout, Button, Card, Form, Divider, InputNumber } from 'antd'
import { Breadcrumb } from 'antd'
import { firestore } from '../../firebase/firebaseConfig'

import { useRecoilValue } from 'recoil'

import { Loader, CurrentCollection, NumberedCardHeader, ContainerDimensionsEnum, SidebarEnum } from '../../helpers/Functions'
import { loaderAtom } from '../../recoil/atoms'

import { RenderSchema } from '../../components/FormRenderer'

import ModalButton from '../../components/ModalButton'

import { ContainersRelatedFormSchema, AdditionalRequestsSchema } from '../../components/FormSchemas'
import SummaryModal from '../../components/ImportExportOrders/SummaryModal'

import { suggestionsSelector } from '../../recoil/selectors'
import Sprite from '../../assets/icons/Sprite.svg'

const { Header, Content } = Layout

const NewOrder = (props)  => {
	const collection = CurrentCollection(props?.location?.pathname)
	const [form] = Form.useForm()

    const loader = useRecoilValue(loaderAtom)
	const Suggestions = useRecoilValue(suggestionsSelector(collection))

	const [documentId, setDocumentId] = useState('')
	const [releaseTerminalLocations, setReleaseTerminalLocations] = useState([])
	const [loadingLocations, setLoadingLocations] = useState([])
	const [dropoffLocations, setDropoffLocations] = useState([])
	const [clearingAgents, setClearingAgents] = useState([])
	const emails = Suggestions?.emails || []

	const [summaryModalData, setSummaryModalData] = useState({visible: false, data: {}, modalSize: 'RectangleModal'})
	const [errorList, setErrorList] = useState({
		Locations : {key: 'Locations', value: false, type: 'array'},
		ShipmentDetails: {key: 'ShipmentDetails', value: false, type: 'form', checkValues: ['blNumber', 'croNumber', 'blDocument', 'croDocument', 'shippingLine', 'loadingTime', 'vesselCutoffDT', 'freeDays']},
		ContainersSpecifics: {key: 'ContainersSpecifics', value: false, type: 'form', checkValues: ['containersSpecifics']},
		AdditionalRequests: {key: 'AdditionalRequests', value: false, type: 'form', checkValues: ['selectedEmails', 'fastLane', 'doIssuance']},
	})

	useEffect(() => {
        const document = firestore.collection(collection==='imports' ? 'pending_import_export' : 'import_export').doc()
        setDocumentId(document.id)
		props?.history?.location?.state && PopulateDuplicateData(props.history.location.state.DuplicateData)
    },[]) // eslint-disable-line

	const prepareContactsData = (contacts) => (
		contacts.map(poc => {return ( {...poc, contactPhone: parseInt(poc.contactPhone.replace('+92', ''))} )})
	)
	const prepareLocationsData = (data, type) => {
		const Locations = data[type] ? Object.values(data[type]).sort((a,b) => a.number - b.number) : []
		return Locations.map(location => {
			const Location = {...location}
			if(Location.newAddress) {
				Location.millAddress = Location.formattedAddress
				Location.millName = Location.additionalDetails
				Location.formattedAddress = 'Other'
			}
			const PointOfContact = []
			if(type==='loading') {
				collection==='imports' ? PointOfContact.push(...data?.clearingAgents) : PointOfContact.push(...[{contactName: location.contactName, contactPhone: location.contactPhone}, ...(location?.additionalContacts || [])])
			} else if (type==='dropOff') {
				collection==='imports' ? PointOfContact.push(...[{contactName: location.contactName, contactPhone: location.contactPhone}, ...(location?.additionalContacts || [])]) : PointOfContact.push(...data?.clearingAgents)
			}
			return { ...Location, pointOfContact: prepareContactsData(PointOfContact || []) }
		})
	}
	const PopulateDuplicateData = (DuplicateData) => {
		setLoadingLocations(prepareLocationsData(DuplicateData, 'loading'))
		setDropoffLocations(prepareLocationsData(DuplicateData, 'dropOff'))
		form.setFieldsValue({selectedEmails: DuplicateData?.clientEmail})
	}

	const ImportLocationsCard = () => (
		<Card 
			key={'ImportLocationsCard'}
			title={NumberedCardHeader('Port and Dropoff Locations', 'Enter information of port and all dropoff points.', 1)} 
			className={`SixtyFourtyWidth MarginBottom ${errorList.Locations.value===true ? 'Error' : ''}`}
		>
			<div className='Padding'>
				{	loadingLocations.map((location, index) =>
						<ModalButton
							key={index}
							buttonText='Port'
							data={loadingLocations} 
							setdata={setLoadingLocations}
							targetIndex={index} 
							type='import_loading'
							action='edit'
							deleteOption={true}
							collection={collection}
						/>
				)}
				{	dropoffLocations.map((location, index) =>
						<ModalButton
							key={index}
							buttonText={dropoffLocations.length===1 ? 'Dropoff Location' : `Dropoff Location # ${index+1}`}
							data={dropoffLocations} 
							setdata={setDropoffLocations}
							targetIndex={index} 
							type='import_dropoff'
							action='edit'
							deleteOption={true}
							collection={collection}
						/>
				)}
				{	loadingLocations.length===0 && 
					<ModalButton
						key={'loading_add'}
						data={loadingLocations} 
						setdata={setLoadingLocations}
						targetIndex={loadingLocations.length}
						type='import_loading'
						action='add'
						collection={collection}
					/>
				}
				{	loadingLocations.length>0 && 
					<ModalButton
						key={'dropoff_add'}
						data={dropoffLocations} 
						setdata={setDropoffLocations}
						targetIndex={dropoffLocations.length} 
						type='import_dropoff'
						action='add'
						collection={collection}
					/>
				}
			</div>
		</Card>
	)

	const ExportLocationsCard = () => (
		<Card 
			title={NumberedCardHeader('Release Terminal, Loading and Dropoff Locations', 'Enter information of container release terminal, dropoff port and all your loading locations.', 1)} 
			className={`SixtyFourtyWidth MarginBottom ${errorList.Locations.value===true ? 'Error' : ''}`}
		>
			<div className='Padding'>
				{	releaseTerminalLocations.map((location, index) =>
						<ModalButton
							key={index}
							buttonText={'Release Terminal Location'} 
							data={releaseTerminalLocations} 
							setdata={setReleaseTerminalLocations}
							targetIndex={index} 
							type='release_terminal'
							action='edit'
							deleteOption={true}
							collection={collection}
						/>
				)}
				{	loadingLocations.map((location, index) =>
						<ModalButton
							key={index}
							buttonText={loadingLocations.length===1 ? 'Loading Location' : `Loading Location # ${index+1}`} 
							data={loadingLocations} 
							setdata={setLoadingLocations}
							additionalData={{clearingAgents: clearingAgents, setClearingAgents: setClearingAgents}}
							targetIndex={index} 
							type='export_loading'
							action='edit'
							deleteOption={true}
							collection={collection}
						/>
				)}
				{	dropoffLocations.map((location, index) =>
						<ModalButton
							key={index}
							buttonText={dropoffLocations.length===1 ? 'Dropoff Location' : `Dropoff Location # ${index+1}`}
							data={dropoffLocations} 
							setdata={setDropoffLocations}
							targetIndex={index} 
							type='export_dropoff'
							action='edit'
							deleteOption={true}
							collection={collection}
						/>
				)}
				{	releaseTerminalLocations.length===0 && 
					<ModalButton
						key={'release_terminal'}
						data={releaseTerminalLocations} 
						setdata={setReleaseTerminalLocations}
						targetIndex={releaseTerminalLocations.length}
						type='release_terminal'
						action='add'
						collection={collection}
					/>
				}
				{	releaseTerminalLocations.length>0 && 
					<ModalButton
						key={'loading_add'}
						data={loadingLocations} 
						setdata={setLoadingLocations}
						targetIndex={loadingLocations.length}
						type='export_loading'
						action='add'
						collection={collection}
					/>
				}
				{	loadingLocations.length>0 && 
					dropoffLocations.length===0 && 
					<ModalButton
						key={'dropoff_add'}
						data={dropoffLocations} 
						setdata={setDropoffLocations}
						targetIndex={dropoffLocations.length} 
						type='export_dropoff'
						action='add'
						collection={collection}
					/>
				}
			</div>
		</Card>
	)

	const ShipmentDetailsCard = () => (
		<Card
			title={NumberedCardHeader('Shipment Details', 'Enter all information related to shipment.', 2)}
			className={`SixtyFourtyWidth MarginBottom ${errorList.ShipmentDetails.value===true ? 'Error' : ''}`}
		>
			<div className='Padding' style={{backgroundColor: '#FAFAFA'}}>
				{RenderSchema(ContainersRelatedFormSchema({collection: collection, documentId: documentId}))}
			</div>
		</Card>
	)

	const ContainersSpecificsCard = () => (
		<Card
			id='ContainerCard'
			title={NumberedCardHeader('Container(s) Specifics', `Increase quantity of container dimensions to match it in ${collection==='imports' ? 'BL' : 'CRO'} Document.`, 3)}
			className={`SixtyFourtyWidth MarginBottom ${errorList.ContainersSpecifics.value===true ? 'Error' : ''}`}
		>
			<div className='Padding' style={{backgroundColor: '#FAFAFA'}}>
				<Form.Item
					name='containersSpecifics'
					rules={[{
						required: true,
						validator: async () => Object.keys(ContainerDimensionsEnum).some(container => parseInt(form.getFieldValue(['containersSpecifics', container]))>0) ? 
											Promise.resolve() : Promise.reject(new Error('Container specifics are required'))
					}]}
					className='NoMargin'
				>
					{	Object.values(ContainerDimensionsEnum).map((container, index) => {
							const TotalContainerType = Object.values(ContainerDimensionsEnum).length
							return <>
								<div className='FlexTopRow NoMargin'>
									<Form.Item
										name={['containersSpecifics', container.key]}
										className='QuarterWidth NoMargin'
										initialValue={0}
										rules={[{ required: true, message: 'Quantity is required.' }]}
									>
										<InputNumber
											className='FullWidth'
											addonBefore='Quantity: '
											min={0}
										/>
									</Form.Item>
									<div className='ThreeFourthWidth FlexRow PaddingBottom'>
										<p className='QuarterWidth BoldFont CenterAlign'>x</p>
										<p className='FullWidth BoldFont'>{container.long}</p>
										<svg style={{height: 36}}><use href={Sprite + container.icon}/></svg>
									</div>
								</div>
								{index!==TotalContainerType-1 && <Divider className='NoMargin MarginBottom'/>}
							</>
					})}
				</Form.Item>
			</div>
		</Card>
	)

	const AdditionalRequestsCards = () => (
		<Card 
			id='AdditionalRequestsCards'
			title={NumberedCardHeader('Additional Requests', 'Enter or select emails and provide special request information.', 4)}
			className={`SixtyFourtyWidth MarginBottom ${errorList.AdditionalRequests.value===true ? 'Error' : ''}`}
		>
			<div className='PaddingExceptBottom' style={{backgroundColor: '#FAFAFA'}}>
				{RenderSchema(AdditionalRequestsSchema({collection: collection, emails: emails}))}
			</div>
		</Card>
	)

	const ErrorChecks = (errorFields) => {
		let Flags = JSON.parse(JSON.stringify(errorList))
		Object.values(Flags).forEach((flag, index, FlagsCallback)=> {
			if(flag.type==='array') {
				if(flag.key==='Locations') {
					FlagsCallback[index].value = (loadingLocations.length===0 || dropoffLocations.length===0 || (collection==='exports' && releaseTerminalLocations.length===0))
				}
			} else if (flag.type==='form') {
				FlagsCallback[index].value = errorFields?.some( error => flag.checkValues.includes(error['name'][0]) ) || false //Raise flag if even one of checkvalues' key is in errorFields
			}
		})
		if (Object.values(Flags).some((flag, index) => flag.value!==Object.values(errorList)[index].value)) {
			setErrorList(Flags)
			form.submit()
		}
		// console.log(Flags)
		return Object.values(Flags).every(flag => !flag.value) // returns true if all flags are false (no problem/flag raised)
	}

	const onFinish = (values) => {
		if(ErrorChecks()) {
			// console.log(values)
			let shipmentDetails = {}
			let specialRequests={}

			if(collection==='imports') {
				shipmentDetails = {
					blNumber: values.blNumber,
					blDocument: values.blDocument,
					shippingLine: values.shippingLine,
					loadingTime: values.loadingTime,
					freeDays: values.freeDays
				}
				specialRequests={
					reefer: values.reefer,
					emails: values.selectedEmails,
					doIssuance: values.doIssuance,
					fastLane: values.fastLane,
					orderCategory: values.orderCategory,
					...(values.numberFlatbeds && {numberFlatbeds: values.numberFlatbeds}),
					...(values.localMovement!==undefined && {localMovement: values.localMovement}),
					otherRequests: values.otherRequests
				}
			} else if (collection === 'exports') {
				shipmentDetails = {
					croNumber: values.croNumber,
					croDocument: values.croDocument,
					shippingLine: values.shippingLine,
					loadingTime: values.loadingTime,
					vesselCutoffDT: values.vesselCutoffDT,
					freightType: values.freightType,
					freightWeight: values.freightWeight,
				}
				specialRequests = {
					reefer: values.reefer,
					emails: values.selectedEmails,
					fastLane: values.fastLane,
					otherRequests: values.otherRequests
				}
			}

			const ModalData = {
				loadingLocations: loadingLocations,
				dropoffLocations: dropoffLocations,
				...(collection==='exports' && {releaseTerminalLocations: releaseTerminalLocations}),
				shipmentDetails: shipmentDetails,
				containersSpecifics: values.containersSpecifics,
				specialRequests: specialRequests,
				documentId: documentId,
				redoJobId: props?.history?.location?.state?.DuplicateData?._id,
				user: props.user,
			}
			// console.log({ModalData})

			setSummaryModalData({
				visible: true,
				collection: collection, 
				data: ModalData,
				modalSize: 'RectangleModal'
			})
		}
	}

	return (
        <Layout>
			<Header>
                <Breadcrumb separator='>'>
                    <Breadcrumb.Item className='Heading'><a href={`/${collection}`}>{SidebarEnum[collection]?.name}</a></Breadcrumb.Item>
                    <Breadcrumb.Item className='Heading'>Create</Breadcrumb.Item>
                </Breadcrumb>
                <Button className='Button PrimaryButton' onClick={() => {form.submit()}}> Submit </Button>
			</Header>
			{	loader ? <Loader/> :
				<Content>
					<Form
						layout='vertical'
						form={form}
						onFinish={onFinish}
						onFinishFailed={({errorFields}) => ErrorChecks(errorFields)}
					>
						<div className='FlexColumnBox'>
							{collection==='imports' ? <ImportLocationsCard/> : <ExportLocationsCard/>}
							<ShipmentDetailsCard/>
							<ContainersSpecificsCard/>
							<AdditionalRequestsCards/>
						</div>
					</Form>
				</Content>
			}
			<SummaryModal {...props} modalData={summaryModalData} setModalData={setSummaryModalData}/>
		</Layout>
	)
}

export default NewOrder