import React, { useEffect, useState } from 'react'
import { Layout, Menu, Button, Select } from 'antd'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { CaretRightOutlined } from '@ant-design/icons'

import * as AllAtoms from '../recoil/atoms'
import { firestore, Auth } from '../firebase/firebaseConfig'
import { SidebarEnum, CurrentPageAction } from '../helpers/Functions'

import Sprite from '../assets/icons/Sprite.svg'

const { Sider } = Layout

const Sidebar = (props) => {
	const { history, location: {pathname} } = props

	const [currentPage, setCurrentPage] = useState('dashboard')
	const PageAction = CurrentPageAction(pathname)

	const [activeBusinessCode, setActiveBusinessCode] = useRecoilState(AllAtoms.activeBusinessCodeAtom)
	const userData = useRecoilValue(AllAtoms.userAtom)

	const setLoaderAtom = useSetRecoilState(AllAtoms.loaderAtom)
	const setLocalOrdersAtom = useSetRecoilState(AllAtoms.localOrdersAtom)
	const setImportOrdersAtom = useSetRecoilState(AllAtoms.importOrdersAtom)
	const setLongHaulOrdersAtom = useSetRecoilState(AllAtoms.longHaulOrdersAtom)
	const setExportOrdersAtom = useSetRecoilState(AllAtoms.exportOrdersAtom)
	const setDriversAtom = useSetRecoilState(AllAtoms.driversAtom)
    const setProfilesAtom = useSetRecoilState(AllAtoms.profilesAtom)
    const setVehiclesAtom = useSetRecoilState(AllAtoms.vehiclesAtom)
	const setBaseLocationsAtom = useSetRecoilState(AllAtoms.baseLocationsAtom)
	const setPortsAtom = useSetRecoilState(AllAtoms.portsAtom)
	const setLanesAtom = useSetRecoilState(AllAtoms.lanesAtom)
	const setVicinitiesAtom = useSetRecoilState(AllAtoms.vicinitiesAtom)
	const [corporatesAtom, setCorporatesAtom] = useRecoilState(AllAtoms.corporatesAtom)

	useEffect(() => {
		setCurrentPage(pathname.split('/')[1])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[pathname])

	useEffect(()=> {
		populateAllAtoms()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeBusinessCode])

	const populateAllAtoms = async () => {
		setLoaderAtom(true)

		const LocalOrdersQuery = firestore.collection('jobs').where('businessCode', '==', activeBusinessCode).orderBy('orderNumber', 'desc').limit(1000).get()
		const ImportExportOrdersQuery = firestore.collection('import_export').where('businessCode', '==', activeBusinessCode).get()
		const LongHaulOrdersQuery = firestore.collection('long_haul').where('businessCode', '==', activeBusinessCode).get()
		const PendingImportExportOrdersQuery = firestore.collection('pending_import_export').where('businessCode', '==', activeBusinessCode).get()
		const DriversQuery = firestore.collection('drivers').where('businessCode', '==', activeBusinessCode).get()
		const ProfilesQuery = firestore.collection('profiles').where('userType', '==', 'driver').get()
		const VehiclesQuery = firestore.collection('vehicles').where('businessCode', '==', activeBusinessCode).get()
		const BaseLocationsQuery = firestore.collection('base_locations').where('businessCode', '==', activeBusinessCode).get()
		const CorporatesQuery = firestore.collection('corporates').get()
		const LanesQuery = firestore.collection('lanes').get()
		const VicinitiesQuery = firestore.collection('vicinities').get()
		const PortsConfigQuery = firestore.collection('config').doc('portVicinities').get()

		const [
			LocalOrdersQuerySnapshot,
			ImportExportOrdersQuerySnapshot,
			LongHaulOrdersQuerySnapshot,
			PendingImportExportOrdersQuerySnapshot,
			DriversQuerySnapshot,
			ProfilesQuerySnapshot,
			VehiclesQuerySnapshot,
			BaseLocationsQuerySnapshot,
			CorporatesQuerySnapshot,
			LanesQuerySnapshot,
			VicinitiesQuerySnapshot,
			PortsConfigQuerySnapshot
		] = await Promise.all([
			LocalOrdersQuery,
			ImportExportOrdersQuery,
			LongHaulOrdersQuery,
			PendingImportExportOrdersQuery,
			DriversQuery,
			ProfilesQuery,
			VehiclesQuery,
			BaseLocationsQuery,
			CorporatesQuery,
			LanesQuery,
			VicinitiesQuery,
			PortsConfigQuery
		])

		const LocalOrdersData = LocalOrdersQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const ImportExportOrdersData = ImportExportOrdersQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const LongHaulOrdersData = LongHaulOrdersQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const PendingImportExportOrdersData = PendingImportExportOrdersQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const DriversData = DriversQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const ProfilesData = ProfilesQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const VehiclesData = VehiclesQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const BaseLocationsData = BaseLocationsQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const CorporatesData = CorporatesQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const LanesData = LanesQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const VicinitiesData = VicinitiesQuerySnapshot.docs.map(doc => ({...doc.data()}))
		const PortsData = PortsConfigQuerySnapshot.data()

		const PendingImportOrdersFilter = PendingImportExportOrdersData.filter(Order => Order.type==='import')
		const PendingExportOrdersFilter = PendingImportExportOrdersData.filter(Order => Order.type==='export')
		const ImportOrdersFilter = ImportExportOrdersData.filter(Order => Order.type==='import')
		const ExportOrdersFilter = ImportExportOrdersData.filter(Order => Order.type==='export')

		setLocalOrdersAtom(LocalOrdersData)
		setImportOrdersAtom([...PendingImportOrdersFilter, ...ImportOrdersFilter])
		setExportOrdersAtom([...PendingExportOrdersFilter, ...ExportOrdersFilter])
		setLongHaulOrdersAtom(LongHaulOrdersData)
		setDriversAtom(DriversData)
		setProfilesAtom(ProfilesData)
		setVehiclesAtom(VehiclesData)
		setBaseLocationsAtom(BaseLocationsData)
		setLanesAtom(LanesData)
		setVicinitiesAtom(VicinitiesData)
		setPortsAtom(PortsData.ports)

		const UserBusinessCodes =  userData?.businessCode
        const MappedBusinessCodes = []
        if(UserBusinessCodes) {
            if (typeof UserBusinessCodes === 'object') {
                UserBusinessCodes.forEach( businessCode => {
                    const businessCodeData = CorporatesData.find(corporate => corporate.businessCode===businessCode)
                    businessCodeData && MappedBusinessCodes.push(businessCodeData)
                })
            } else {
                const businessCodeData = CorporatesData.find(corporate => corporate.businessCode===UserBusinessCodes)
                businessCodeData && MappedBusinessCodes.push(businessCodeData)
            }
        }
		setCorporatesAtom(MappedBusinessCodes)
		
		// console.log({LocalOrdersData, ImportOrdersFilter, ExportOrdersFilter, DriversData, ProfilesData, VehiclesData, BaseLocationsData, MappedBusinessCodes})
		setLoaderAtom(false)
	}

	return (
		<Sider width={192}>
			<div className='FullHeight FlexColumnSpace'>
				<Menu mode="inline" selectedKeys={currentPage}>
					{	Object.values(SidebarEnum).map(Item =>
						<Menu.Item 
							key={Item.key}
							icon={<svg className='SidebarIcon'><use href={Sprite + Item.icon}/></svg>}
							onClick={() => history.push(`/${Item.key}`)}
						>
							<Link to={`/${Item.key}`}>{Item.name}</Link>
						</Menu.Item>
					)}
				</Menu>

				<div className='Padding'>
					{	corporatesAtom.length>0 &&
						<>
							<p className='BoldFont FontSize12'>Active Business Name</p>
							<Select
								defaultValue={`${activeBusinessCode} - ${corporatesAtom.find(businessCode => businessCode.businessCode===activeBusinessCode)?.businessName}`}
								className='SelectField FullWidth MarginBottom'
								suffixIcon={ () => <CaretRightOutlined rotate={90}/> }
								onChange={ value => setActiveBusinessCode(value.split(' - ')[0]) }
								disabled={PageAction==='create' || PageAction==='view'}
								dropdownMatchSelectWidth={false}
								showSearch
							>
								{	corporatesAtom.map (businessCode =>
										<Select.Option key={businessCode.businessCode} value={`${businessCode.businessCode} - ${businessCode.businessName}`}>{`${businessCode.businessName} (${businessCode.businessCode})`}</Select.Option>
								)}
							</Select>
						</>
					}
					<Button className='Button ProfileButton CancelButton' onClick={()=>Auth.signOut()}> Logout </Button>
				</div>
			</div>
		</Sider>
	)
}

export default withRouter(Sidebar)