import React, {useEffect, useState} from 'react'
import { Modal, Timeline, Divider, notification } from 'antd'
import { check, CardHeader, IconDescription } from '../../helpers/Functions'
import { firestore } from '../../firebase/firebaseConfig'
import '../../App.css'
import moment from 'moment'
import {Loader, sendDriverNotification, VehicleTypeEnum, VehicleSubtypeEnum, VehiclesEnum} from '../../helpers/Functions'
import { useRecoilValue } from 'recoil'
import { activeBusinessCodeAtom } from '../../recoil/atoms'

import Sprite from '../../assets/icons/Sprite.svg'

const SummaryModal = (props) => {
    const { visible, data, modalSize } = props.modalData

    const activeBusinessCode = useRecoilValue(activeBusinessCodeAtom)

    const [loading, setLoading] = useState(false)
    const [driverInfo, setDriverInfo] = useState({_id: null, vehicleId: null})
    const [pricing, setPricing] = useState(0)

    useEffect(() => {
        setPricing(0)
        if(visible) {
            data.form.driversList && 
            setDriverInfo(JSON.parse(data.form.driversList))

            data.loadingLocations.length>0 && 
            data.dropoffLocations.length>0 &&
            data.vehicleCategory[0]?.vehicleType &&
            !['superx', 'xl20ft', 'xl40ft'].includes(data.vehicleCategory[0]?.vehicleType) &&
            calculatePricing(data.loadingLocations, data.dropoffLocations, data.vehicleCategory)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const calculatePricing = async (loadingLocations, dropoffLocations, vehicleCategory) => {
        setLoading(true)

        const AllLocations = [...loadingLocations, ...dropoffLocations]
        // console.log({AllLocations})

        const distances = []
        for (let i=0; i< AllLocations.length-1; i++) {
            const distanceMatrixResponse = await fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/distanceMatrixAPICall`,{
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    origin: `${AllLocations[i].coordinates.lat},${AllLocations[i].coordinates.lng}`,
                    destination: `${AllLocations[i+1].coordinates.lat},${AllLocations[i+1].coordinates.lng}`
                })
            })
            const result = await distanceMatrixResponse.json()
            // console.log({result})
            distances.push(result.distanceMatrixResult.rows[0].elements[0].distance.value)
        }
        // console.log({distances})

        let unloadingTime = {}
        dropoffLocations.forEach(location => {
            unloadingTime = {
                ...unloadingTime,
                [location._id]:{
                    start: moment().add(5, 'hours').minutes(0).format('x'),
                    end: moment().add(6, 'hours').minutes(0).format('x'),
                }
            }
        })
        // console.log({unloadingTime})

        const pricingQuery = await fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/pricingAlgo`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                distance: distances.reduce((a, b) => a+b) / 1000,
                loadingTime: {
                    [loadingLocations[0]._id]: {
                        start: moment().minutes(0).format('x'),
                        end: moment().add(1,'hours').minutes(0).format('x'),
                    }
                },
                unloadingTime: unloadingTime,
                vehicleType: vehicleCategory[0]?.vehicleType
            })
        })

        const pricingQueryResponse = await pricingQuery.json()
        setPricing(pricingQueryResponse.finalAmount)
        // console.log("pricing", pricingQueryResponse.finalAmount)

        setLoading(false)
    }

    const getDbValue = (value, falseCase) => {
        return check(value) ? value : falseCase
    }

    const preparePackageInfo = (loadingLocations, dropoffLocations) => {
        let PackageInfoObject = {
            [loadingLocations[0]._id] : {
                noOfUnits: getDbValue(loadingLocations[0].noOfUnits, 0),
                freightDetails: getDbValue(loadingLocations[0].freightDetails, ''),
                typeOfFreight: getDbValue(loadingLocations[0].typeOfFreight, ''),
                customFreightType:"",
                freightWeight: parseInt((getDbValue(loadingLocations[0].freightWeight, 0))),
                freightWeightUnit: getDbValue(loadingLocations[0].freightWeightUnit, ''),
            }
        }
        dropoffLocations.forEach(dropoff => {
            PackageInfoObject = {
                ...PackageInfoObject,
                [dropoff._id] : {
                    noOfUnits: getDbValue(dropoff.noOfUnits, 0),
                    freightDetails: getDbValue(loadingLocations[0].freightDetails, ''),
                    typeOfFreight: getDbValue(loadingLocations[0].typeOfFreight, ''),
                    customFreightType:"",
                    freightWeight: parseInt(getDbValue(dropoff.freightWeight, 0)),
                    freightWeightUnit: getDbValue(dropoff.freightWeightUnit, ''),
                }
            }
        })
        return PackageInfoObject
    }

    const preparePickupLocations = (loadingLocations) => {
        let PickupLocationsObject = {
            [loadingLocations[0]._id] : {
                _id: loadingLocations[0]._id,
                address: getDbValue(loadingLocations[0].additionalDetails, ''),
                contactName: getDbValue(loadingLocations[0].contactName, ''),
                contactPhone: loadingLocations[0].contactPhone ? `+92${loadingLocations[0].contactPhone}` : '',
                coordinates:{
                    lat: loadingLocations[0].coordinates.lat.toString(),
                    lng: loadingLocations[0].coordinates.lng.toString(),
                },
                formattedAddress: loadingLocations[0].formattedAddress,
                number: 1,
                timestamp: moment().format('x'),
                type: 'pickup',
            }
        }
        return PickupLocationsObject
    }

    const prepareDropoffLocations = (dropoffLocations, pickupLocationsCount) => {
        let DropoffLocationsObject = {}
        dropoffLocations.map ((dropoff, index) => 
            DropoffLocationsObject = {
                ...DropoffLocationsObject,
                [dropoff._id]: {
                    _id: dropoff._id,
                    address: getDbValue(dropoff.additionalDetails, ''),
                    contactName: getDbValue(dropoff.contactName, ''),
                    contactPhone: dropoff.contactPhone ? `+92${dropoff.contactPhone}` : '',
                    coordinates:{
                        lat: dropoff.coordinates.lat.toString(),
                        lng: dropoff.coordinates.lng.toString(),
                    },
                    formattedAddress: dropoff.formattedAddress,
                    number: pickupLocationsCount+index+1,
                    timestamp: moment().format('x'),
                    type: 'dropoff',
                }
            }
        )
        return DropoffLocationsObject
    }

    const prepareClientImages = (loadingLocations) => {
        const uploadList = []
        loadingLocations[0]?.clientUpload?.map(file => uploadList.push(file.url) )
        return { [loadingLocations[0]?._id] : uploadList }
    }

    const preparePriceInfo = () => {
        let PriceInfoObject = {
            cashCollected: '0.0',
            estimatedAmount: Math.ceil(pricing),
            finalAmount: null,
            paymentStatus: 'unpaid',
            paymentType: null,
            discount: null,
            flatRate: 0.0,
            surcharge: null,
            variableRate: null
        }

        return PriceInfoObject
    }

    const prepareJobStatus = (driverType, vehicleType) => {
        let JobStatusObject = {
            status: driverType==='bridgelinx' ? 'jobInit' : 'jobOngoing',
            timestamp: moment().format('x')
        }
        // if(['superx', 'xl20ft', 'xl40ft'].includes(vehicleType)) {
        //     JobStatusObject.status = 'jobCriticalSuper'
        // }
        return JobStatusObject
    }

    // const prepareJobType = (packageInfo, vehicleType) => {
    //     const FreightType = Object.values(packageInfo)?.[0]?.freightDetails
    //     let JobType = 'intraCity'
    //     if(['cement', 'coal', 'wood', 'baggas', 'steel'].includes(FreightType) || ['superx', 'xl20ft', 'xl40ft'].includes(vehicleType)) {
    //         JobType='interCity'
    //     }
    //     return JobType
    // }

    
    const onFinish = async () => {
        // console.log({data})
        
        setLoading(true)

        const response = await fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/jobCountUpdateTransaction`)
        const count = await response.json()

        const ClientImagesObject = prepareClientImages (data.loadingLocations)
        const PickupLocationsObject = preparePickupLocations(data.loadingLocations)
        const DropoffLocationsObject = prepareDropoffLocations(data.dropoffLocations, Object.keys(PickupLocationsObject).length)
        const PackageInfoObject = preparePackageInfo(data.loadingLocations, data.dropoffLocations)
        const PriceInfoObject = preparePriceInfo()
        const JobStatusObject = prepareJobStatus(data.form.driverType, data.vehicleCategory[0]?.vehicleType)
        // const JobTypeResult = prepareJobType(PackageInfoObject, data.vehicleCategory[0]?.vehicleType)
        
        const JobObject = {
            _id: data.documentId,
            assignedDriverType: data.form.driverType === 'bridgelinx' ? '' : 'corporate',
            biggerTruckSent: false,
            businessCode: activeBusinessCode,
            cashForGoods: 0,
            clientCoordinates: { pickUp : PickupLocationsObject, dropOff : DropoffLocationsObject },
            clientId: props.user.clientId,
            clientImages: ClientImagesObject,
            createdAt: moment().format('x'),
            createdBy: 'web',
            driverCurrentCoordinates: null,
            driverId: data.form.driverType === 'bridgelinx' ? null : driverInfo._id,
            driverImages: null,
            driverStatus: { status: 'driverInit', timestamp: moment().format('x') },
            driverStatusHistory: null,
            endTime: data.form.time==='instant' ? moment().add(5,'hours').minutes(0).seconds(0).milliseconds(0).format('x') : data.form.loadingTime.add(5,'hours').minutes(0).seconds(0).milliseconds(0).format('x'),
            isScheduled : data.form.time === 'scheduled' ? true : false,
            jobStatus: JobStatusObject,
            jobStatusHistory: null,
            jobType: 'intraCity',
            numberOfLoaders: null,
            numberOfUnloaders: null,
            orderModifyProcessed: null,
            orderModifyRequested: null,
            orderNumber: count.platformOrders,
            packageInfo: PackageInfoObject,
            pickupTime: data.form.time==='instant' ? moment().add(1,'hours').minutes(0).seconds(0).milliseconds(0).format('x') : data.form.loadingTime.add(1,'hours').minutes(0).seconds(0).milliseconds(0).format('x'),
            priceInfo: PriceInfoObject,
            startTime: data.form.time==='instant' ? moment().minutes(0).seconds(0).milliseconds(0).format('x') : data.form.loadingTime.minutes(0).seconds(0).milliseconds(0).format('x'),
            vehicleId: data.form.driverType === 'bridgelinx' ? null : driverInfo.vehicleId,
            vehicleType: data.vehicleCategory[0]?.vehicleType,
            vehicleSubtype: getDbValue(data.vehicleCategory[1]?.vehicleSubtype, '')
        }

        // console.log({JobObject})

        const batch = firestore.batch()
        const JobDocRef = firestore.collection('jobs').doc(data.documentId)
        batch.set(JobDocRef, {...JobObject} , {merge:true})


        if(data.form.driverType === 'own') {
            const DriverDocRef = firestore.collection('drivers').doc(driverInfo._id)
            batch.set(DriverDocRef, {currentJob: data.documentId} , {merge:true})
            sendDriverNotification( 'New Job', 'Accept this job for crazy ride.', data.documentId, driverInfo._id )
        }

        await batch.commit()

        setLoading(false)
        props.history.push('/orders')

        notification['success']({
            message: 'Order Placed',
            description: `Your Order has been placed with Order No. ${count && count.platformOrders}`,
            duration: 5,
        })
    }

    const onCancel = () => {
        props.setModalData({...props.modalData, visible: false})
    }

    const TimelineItem = (location, index, type, multiple) => (
        <Timeline.Item key={index} color={`${type==='dropoff' ? '#009298' : '#15588D'}`}>
            <Divider className={type==='dropoff' ? 'SecondarySectionTitle' : 'SectionTitle'} orientation='left' plain style={{paddingTop: 2}}>
                { type==='dropoff' ? 
                    multiple ? `Dropoff Location # ${index+1}`: 'Dropoff Location' :
                    multiple ? `Pickup Location # ${index+1}`: 'Pickup Location'
                }
            </Divider>

            <p className='Title' style={{color: `${type==='dropoff' ? '#009298' : '#15588D'}`}}>Location Information</p>
            <p className='RegularFont'>{location.formattedAddress}</p>
            {location.additionalDetails && <p className='TitleDescription'>{location.additionalDetails}</p>}
            {location.contactName && IconDescription('#Avatar', `${location.contactName}`)}
            {location.contactPhone && IconDescription('#Phone', `+92${location.contactPhone}`)}

            <p className='Title' style={{paddingTop: 8, color: `${type==='dropoff' ? '#009298' : '#15588D'}`}}>Freight Information</p>
            {   type!=='dropoff' &&  <>
                    <p><span className='BoldFont'>Freight Type: </span>{location.freightDetails}</p>
                    <p className='Capitalize'><span className='BoldFont'>Packaging Type: </span>{location.typeOfFreight}</p>
                </>
            }
            <p><span className='BoldFont'>No of Units (Count): </span>{location.noOfUnits}</p>
            <p className='Capitalize'><span className='BoldFont'>Freight Weight: </span>{location.freightWeight} {location.freightWeightUnit}</p>
        </Timeline.Item>
    )

    const HightlightInfoPill = (Title, Data, Background, Color) => (
        <div className=' FlexRow MarginRight'>
            {Title.length>0 && <p className='BoldFont HalfMarginRight'>{Title}</p> }
            <p className='HightlightPillInfo' style={{backgroundColor: Background ? Background : '#FFFFFF', color: Color ? Color: '#333333'}}>{Data}</p>
        </div>
    )

    const LocationsSummary = (LoadingLocations, DropoffLocations) => ( <>
        <Timeline>
            {   check(LoadingLocations) && LoadingLocations.length>0 &&
                LoadingLocations.map ((location, index) =>
                    TimelineItem(location, index, 'pickup', LoadingLocations.length>1)) 
            }
            {   check(DropoffLocations) && DropoffLocations.length>0 && 
                DropoffLocations.map ((location, index) =>
                    TimelineItem(location, index, 'dropoff', DropoffLocations.length>1)) 
            }
        </Timeline>
    </>)

    const VehicleInformation = (vehicleCategory) => ( <>
        <Divider className='SectionTitle' orientation='left' plain>Vehicle Information</Divider>
        <div className='FlexRow FullWidth'>
            <div className='HalfWidth'>
                {   data.vehicleCategory?.length>0 && 
                    <p className='BoldFont FontSize16 PrimaryColor'>{VehicleTypeEnum[vehicleCategory[0]?.vehicleType]?.long} {vehicleCategory[1]?.vehicleSubtype.toString().length>0 ? `- ${VehicleSubtypeEnum[vehicleCategory[1]?.vehicleSubtype]?.text}` : ''}</p>
                }
                {   vehicleCategory?.length>0 &&  
                    VehiclesEnum[vehicleCategory[0]?.vehicleType] &&
                    <p><span className='BoldFont'>Tonnage: </span>
                        {VehiclesEnum[vehicleCategory[0]]?.vehicleType?.[vehicleCategory[1]?.vehicleSubtype] ? VehiclesEnum[vehicleCategory[0]?.vehicleType]?.[vehicleCategory[1]?.vehicleSubtype]?.tonnage : VehiclesEnum[vehicleCategory[0]?.vehicleType]?.tonnage}
                    </p>
                }
                {   vehicleCategory?.length>0 &&  
                    VehiclesEnum[vehicleCategory[0]?.vehicleType] &&
                    <p><span className='BoldFont'>Dimensions: </span>
                        {VehiclesEnum[vehicleCategory[0]]?.vehicleType?.[vehicleCategory[1]?.vehicleSubtype] ? VehiclesEnum[vehicleCategory[0]?.vehicleType]?.[vehicleCategory[1]?.vehicleSubtype]?.dimensions : VehiclesEnum[vehicleCategory[0]?.vehicleType]?.dimensions}
                    </p>
                }
            </div>
            <div className='HalfWidth'>
                    {   vehicleCategory?.length>0 && 
                        <svg className='FullWidth VehicleIcon'>
                            <use href={ 
                                vehicleCategory[1]?.vehicleSubtype ?
                                Sprite + VehiclesEnum[vehicleCategory[0]?.vehicleType]?.[vehicleCategory[1]?.vehicleSubtype].icon :
                                Sprite + VehicleTypeEnum[vehicleCategory[0]?.vehicleType]?.icon
                            }/>
                        </svg>
                    }
            </div>
        </div>
    </>)

    const DriverInformation = () => ( <>
        <Divider className='SectionTitle HalfPaddingTop' orientation='left' plain>Driver Information</Divider>
        {   data?.form?.driverType==='own' ? 
            <>
                <p><span className='BoldFont'>Driver Type: </span>Corporate (Private) Driver</p>
                <p><span className='BoldFont'>Driver Name: </span>{`${driverInfo?.profile?.firstName} ${driverInfo?.profile?.lastName}`}</p>
                <p><span className='BoldFont'>Contact Number: </span>{driverInfo?.profile?.phoneNumber}</p>
                <p><span className='BoldFont'>Vehicle Number: </span>{driverInfo?.vehicle?.registrationNumber}</p>
            </> :
            <>
                <p><span className='BoldFont'>Driver Type: </span>Bridgelinx Driver</p>
                <p><span className='BoldFont'>Driver Information: </span>To be provided by Bridgelinx team.</p>
            </>
        }
        
    </>)

    return (
        <Modal
            visible= {visible}
            title= {CardHeader('Order Summary', 'Verify and confirm this order information.')}
            closable={false}
            className={modalSize}
            okText={'CONFIRM'}d
            cancelText='CANCEL'
            okButtonProps={{className: 'Button ModalFooterButton SubmitButton', onClick: onFinish}}
            cancelButtonProps={{className: 'Button ModalFooterButton CancelButton', onClick: onCancel}}
        >
            {loading && <Loader/>}
            <div className='FlexColumn FullWidthHeight'>
                <div className='HightlightInfo'>
                    {data?.form?.driverType==='bridgelinx' && HightlightInfoPill('Cost:', pricing!==0 ? `${Math.ceil(pricing)} Rs.` : 'Contact Support Team')}
                    {data?.form?.time==='scheduled' ? HightlightInfoPill('Time:', data.form.loadingTime.format('DD-MM-YYYY hh:mm A')) : HightlightInfoPill('Time:', moment().format('DD-MM-YYYY hh:mm A')) }
                </div>
                <div className='DisplayFlex FullWidth SummaryInfo'>
                    <div className='HalfWidth Padding OverflowScroll'>
                        {LocationsSummary(data.loadingLocations, data.dropoffLocations)}
                    </div>
                    <div className='HalfWidth Padding OverflowScroll' style={{backgroundColor: '#EDEDED'}}>
                        {VehicleInformation(data.vehicleCategory)}
                        {DriverInformation()}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SummaryModal