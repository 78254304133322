import React from 'react'
import { Form, Input, Select, DatePicker, Upload, Divider, Radio, Cascader, Checkbox , Button } from 'antd'
import { storage } from './../firebase/firebaseConfig'

import { check, checkArray } from '../helpers/Functions'

import { PaperClipOutlined, CaretDownOutlined, PlusOutlined, MinusCircleTwoTone } from '@ant-design/icons'

import { Autocomplete} from '@react-google-maps/api'

import Sprite from '../assets/icons/Sprite.svg'

const { Option } = Select

const normFile = (e) => {
	if (Array.isArray(e)) { return e }
	return e && e.fileList
}

export const FormRender = (field, action, index) => {
	const { type } = field

	const DocumentUpload = async ({ file, onProgress, onSuccess, onError }) => {
		// console.log(field.uploadLink)
		const response = storage.ref().child(`public/images/import_export/${field.uploadLink}/client/${file.name}`).put(file)
		response.on(
			"state_changed",
			(snapshot) => onProgress({ percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }),
			(error) => onError(error),
			() => onSuccess(null, response.metadata_)
		)
	}

	const ChangeFileList = async ({fileList}) => {
		if( fileList.length>0) {
			fileList.forEach((file, index) => {
				if(!check(file.url) && file.status==='done') {
					const response = storage.ref().child(`public/images/import_export/${field.uploadLink}/client/${file.name}`)
					response.getDownloadURL().then(result => fileList[index].url = result)
				}
			})
		}
	}

	if (type === 'input') {
        return	<Form.Item
					key={index}
					label={field.label}
					name={field.name}
					rules={[{ required: field.required, message: field.message }]}
					tooltip={field.tooltip}
					initialValue={field.initialValue}
				>
					{ field.inputType==='textArea' ?
						<Input.TextArea
							className='InputField TextAreaField'
							rows={field.rows}
						/> :
						<Input
							className='InputField AddOn'
							type={field.inputType} 
							disabled={(action === 'view' || field.disabled === true) ? true : false}
							addonBefore={field.addonBefore}
							addonAfter={field.addonAfter}
						/>
					}
				</Form.Item>
    } 

	else if (type === 'number') {
        return	<Form.Item
					key={index}
					label={field.label}
					name={field.name}
					rules={[{
						required: field.required,
						validator: (rule, value = '') => {
							if(value.toString().length>0) {
								if (value<0) { return Promise.reject(new Error(`This value cannot be negative.`)) }
								else if(parseInt(value)>field.maxValue) { return Promise.reject(new Error(`This value cannot be greater than ${field.maxValue}`)) }
								else if(parseInt(value)<field.minValue) { return Promise.reject(new Error(`This value cannot be less than ${field.minValue}`)) }
								else { return Promise.resolve() }
							} else if (!field.required) {
								return Promise.resolve(value)
							} else {
								return Promise.reject(new Error(field.message))
							}
						}
					}]}
					tooltip={field.tooltip}
					initialValue={field.initialValue}
				>
					<Input
						className='InputField AddOn'
						type='number'
						onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
						disabled={(action === 'view' || field.disabled === true) ? true : false}
						addonBefore={field.addonBefore}
						addonAfter={field.addonAfter}
					/>
				</Form.Item>
    }

	else if (type === 'autocomplete') {
        return	<Autocomplete
					className='AutocompleteInputField'
					onLoad={(auto) => { window.autocomplete = auto }}
					options={{componentRestrictions: {country: 'pk'} }}
					onPlaceChanged={()=>{
						if(window.autocomplete !== undefined) {
							const place = window.autocomplete.getPlace()
							if (place) { field.formReference.setFieldsValue ({ formattedAddress: place.formatted_address }) }
							place && place.geometry && field.setLocationCoordinates({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
						}
					}}
				>
					<Form.Item
						key={index}
						label={field.label}
						name={field.name}
						rules={[{ required: field.required, message: field.message }]}
						tooltip={field.tooltip}
					>
						<Input type="text" placeholder="Search Place" className='InputField' style={{paddingLeft: 12}} />
					</Form.Item>
				</Autocomplete>
    }

	else if (type === 'phoneNumber') {
		return	<Form.Item
					key={index}
					label={field.label}
					name={field.name}
					rules={[{
						required: field.required,
						validator: (rule, value = '') => {
							if(value.toString().length>0) {
								if (value<0) { return Promise.reject(new Error(`Phone Number cannot be negative.`)) }
								else if(value.toString().length!==10) { return Promise.reject(new Error(`Contact must be of 10 digits.`)) }
								else { return Promise.resolve() }
							} else if (!field.required) {
								return Promise.resolve(value)
							} else {
								return Promise.reject(new Error(field.message))
							}
						}
					}]}
					tooltip={field.tooltip}
				>
					<Input
						className='InputField AddOn'
						type='number'
						onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
						disabled={(action === 'view' || field.disabled === true) ? true : false}
						addonBefore={field.addonBefore}
						addonAfter={field.addonAfter}
					/>
				</Form.Item>
	}

	else if (type === 'upload') {
        return <Form.Item
					key={index}
					label={field.label}
					name={field.name}
					rules={[{ required: field.required, message: field.message }]}
					tooltip={field.tooltip}
					valuePropName='fileList'
					getValueFromEvent={normFile}
				>
					<Upload.Dragger
						listType='picture'
						maxCount={1}
						customRequest = {DocumentUpload}
						onChange = {ChangeFileList}
						className='UploadImages'>
						<p className='ant-upload-drag-icon NoMargin'><PaperClipOutlined style={{fontSize: 36, color: '#1C75BC' }}/></p>
						<p className='ant-upload-text SectionTitle HalfPaddingTop'>Upload Document</p>
					</Upload.Dragger>
				</Form.Item>
    }

	else if (type === 'radioGroup') {
		return <Form.Item
					key={index}
					name={field.name}
					label={field.label}
					rules={[{ required: field.required, message: field.message }]}
					tooltip={field.tooltip}
				>
					{	field.radioButtonView===true ?
						<Radio.Group className='FormRadioGroup'>
							{	Object.values(field.dataObject).map(type =>
								<Radio.Button className='AutoHeight' value={type.key}>
									<div className='FlexColumnBox'>
										<svg className='FullWidth VehicleIcon'><use href={Sprite + type.icon}/></svg>
										<p className='BoldFont HalfMarginTop'>{type.long}</p>
									</div>
								</Radio.Button>
							)}
						</Radio.Group> :
						<Radio.Group>
							{	Object.values(field.dataObject).map(type =>
									<Radio value={type.key}>{type.long}</Radio>
							)}
						</Radio.Group>
					}
				</Form.Item>
	}

	else if (type === 'dateTime') {
		return <Form.Item
					key={index}
					label={field.label}
					name={field.name}
					rules={[{ required: field.required, message: field.message }]}
					tooltip={field.tooltip}
					dependencies={field.dependencies}
				>
					<DatePicker 
						className='InputField'
						disabled={field.disabled===true ? true : false}
						disabledDate={field.disabledDate}
						disabledTime={field.disabledTime}
						format={field.format}
						showNow={field.showNow}
						defaultPickerValue={field.defaultPickerValue}
						showTime={field.showTime!==false ? { defaultValue: field.defaultTimeValue } : false}
					/>
				</Form.Item>
	}

	else if (type === 'select') {
		return <Form.Item
					key={index}
					label={field.label}
					name={field.name}
					rules={[{ required: field.required, message: field.message }]}
					tooltip={field.tooltip}
				>
					<Select
						className='SelectField'
						suffixIcon={() => <CaretDownOutlined/>}
						mode={field.mode}
						disabled={(action === 'view' || field.disabled === true) ? true : false}
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						showSearch={field.showSearch}
						placeholder={field.placeholder}
						maxTagCount= 'responsive'
						tokenSeparators={field.tokenSeparators}
					>
						{	field.options?.map((option, index) =>
								(field.valueProperty && field.displayProperty) ?
									<Option key={`select-${field.name}-${index}`} value={option[field.valueProperty]}>{option[field.displayProperty]}</Option>: 
									<Option key={option} value={option}>{option}</Option>
						)}
					</Select>
				</Form.Item>
	}

	else if (type === 'recentSelect') {
		return <Form.Item
					key={index}
					label={field.label}
					name={field.name}
					rules={[{ required: field.required, message: field.message }]}
					tooltip={field.tooltip}
				>
					<Select
						className='SelectField'
						suffixIcon={() => <CaretDownOutlined/>}
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())>=0 }
						showSearch={field.showSearch}
						placeholder={field.placeholder}
						onChange={ event => {
							const SelectedOption = JSON.parse(event)
							let NewObject = {}
							field.setFields?.forEach(setField => {
								if(SelectedOption[setField]) {
									NewObject = { ...NewObject, [setField] : SelectedOption[setField]}
								}
							})
							if(field.selfValueProperty) {
								NewObject = { ...NewObject, [field.name] : SelectedOption[field.selfValueProperty]}
							}
							field.formReference.setFieldsValue(NewObject)
						}}
					>
						{	field.options?.map((option, index) =>
							<Option key={index} value={JSON.stringify(option)}>{option[field.displayProperty]}</Option>
						)}
					</Select>
				</Form.Item>
	}

	else if (type === 'emails') {
		return <Form.Item
					key={index}
					label={field.label}
					name={field.name}
					tooltip={field.tooltip}
					rules={[{
						required: true,
						validator: (_, value) => {
							const expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
							if(value) {
								const LastEntered = value[value.length-1]
								if (expression.test(LastEntered)) {
									return Promise.resolve()
								} else {
									return Promise.reject(new Error('Please enter valid emails.'))
								}
							} else {
								return Promise.reject(new Error(field.message))
							}
						}
					}]}
				>
					<Select
						className='SelectField'
						suffixIcon={() => <CaretDownOutlined/>}
						mode={field.mode}
						disabled={(action === 'view' || field.disabled === true) ? true : false}
						showSearch={field.showSearch}
						placeholder={field.placeholder}
						maxTagCount= 'responsive'
						tokenSeparators={field.tokenSeparators}
						onChange={value => {
							const expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
							if (value && !expression.test(value[value.length-1])) {
								value.pop()
							}
						}}
					>
						{	field.options?.map((option, index) =>
								(field.valueProperty && field.displayProperty) ?
									<Option key={`select-${field.name}-${index}`} value={option[field.valueProperty]}>{option[field.displayProperty]}</Option>: 
									<Option key={option} value={option}>{option}</Option>
						)}
					</Select>
				</Form.Item>
	}

	else if (type === 'cascader') {
		return <Form.Item
					key={index}
					label={field.label}
					name={field.name}
					rules={[{ required: field.required, message: field.message }]}
					tooltip={field.tooltip}
				>
					<Cascader
						className='CascaderField'
						options={field.options}
						expandTrigger='hover'
					/>
				</Form.Item>
	}

	else if (type === 'checkbox') {
		return <Form.Item
					key={index}
					name={field.name}
					rules={[{ required: field.required, message: field.message }]}
					tooltip={field.tooltip}
					className='HalfMarginBottom'
				>
					<Checkbox 
						onChange={(e)=> {
							if(field.fillData && Object.values(field.fillData).length>0) {
								if(e.target.checked) { field.formReference.setFieldsValue(field.fillData) }
								else { field.formReference.setFieldsValue(field.clearData) }
							}
						}}
					>{field.label}</Checkbox>
				</Form.Item>
	}

	else if (type === 'dynamicFields') {
		return	<Form.List
					key={index}
					name={field.name}
					label={field.label}
					initialValue={field.initialValue}
				>
					{(fields, { add, remove }) =>
						<>
							{fields.map(singleEntryField =>
								<div key={singleEntryField.key} className='FlexRow'>
									{	field.fields.map( (item, index) => 
											<div className={`FullWidth ${index!==0 ? 'MarginLeft' : ''}`}>
												{FormRender({...item, name: [singleEntryField.name, item.name]}) }
											</div>
									)}
									{fields.length>field.minEntries && <MinusCircleTwoTone twoToneColor="#FF0000" className='MarginLeft' onClick={() => {remove(singleEntryField.name)}}/>}
								</div>
							)}
							<Form.Item noStyle>
								<Button icon={<PlusOutlined />} className='Button DashedButton' onClick={() => add()} block>
									{fields.length===0 ? 'Add ' : 'Add Another'} {field.label}
								</Button>
							</Form.Item>
						</>
					}
				</Form.List>
				
	}

	else if (type==='dependency') {
		return	<Form.Item
					key={field.name + 'dummy'}
					shouldUpdate={(prevValues, currentValues) => prevValues[field.independent] !== currentValues[field.independent]}
					noStyle
				>
					{({ getFieldValue }) => field.condition.includes(getFieldValue(field.independent)) ? RenderSchema(field.successSchema) : RenderSchema(field.failureSchema)}
				</Form.Item>
	}
}

export const RenderSchema = (Schema) => (
	Schema.map ( SchemaObject =>
		SchemaObject.fields ?
		<>
			<Divider className='SectionTitle' orientation='left' plain>{SchemaObject.title}</Divider>
			<p className='TitleDescription HalfMarginBottom'>{SchemaObject.description}</p>
			{SchemaObject.fields.map((Field, index) => checkArray(Field) ? RowRenderer (Field) : FormRender(Field, 'edit', index) )}
		</> :
		checkArray(SchemaObject) ? RowRenderer (SchemaObject) : FormRender(SchemaObject, 'edit', SchemaObject.name)
	)
)

export const RowRenderer = (Fields) => (
    <div className='FlexRow'>
        {  Fields.map( (Field, index)=> <div className={`FullWidth ${index!==0 ? 'MarginLeft' : ''}`}>{FormRender(Field, 'edit', index)}</div> )}
    </div>
)