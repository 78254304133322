import React, { useEffect, useState } from 'react'
import { Timeline, Layout, Card,Divider, Collapse, Statistic, Button } from 'antd'
import { Breadcrumb } from 'antd'
import moment from 'moment'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { firestore } from '../../firebase/firebaseConfig'

import { useRecoilState, useRecoilValue } from "recoil"

import { CaretRightOutlined } from '@ant-design/icons'
import { check, Loader, CurrentPageId, CurrentCollection, ExportOrderStatusEnum, ReadableString, CardHeader, VehiclesEnum, VehicleTypeEnum, IconDescription, BoundsZoomLevel } from '../../helpers/Functions'
import { loaderAtom, longHaulOrdersAtom } from '../../recoil/atoms'

import { singleLongHaulOrderSelector } from '../../recoil/selectors';

import Sprite from '../../assets/icons/Sprite.svg'

const { Header, Content, Sider } = Layout

const ViewExportOrder = (props)  => {
    const JobId = CurrentPageId(props.location.pathname)
	const collection = CurrentCollection(props.location.pathname)
    const loader = useRecoilValue(loaderAtom)

    const JobData = useRecoilValue(singleLongHaulOrderSelector({job: JobId}))

    const [longHaulOrders, setLongHaulOrdersAtom] = useRecoilState(longHaulOrdersAtom)

    const bounds = new window.google.maps.LatLngBounds()
    const [boundsCenter, setBoundsCenter] = useState({lat: 31.5204489, lng : 74.3586184, zoomlevel: 10})

	const [loadingLocations, setLoadingLocations] = useState([])
	const [dropoffLocations, setDropoffLocations] = useState([])
    

    useEffect( () => {
        const unsubscribe = firestore.collection('long_haul').doc(JobId).onSnapshot(querySnapshot => {
            if(querySnapshot.data()) {
                const AtomCopy = [...longHaulOrders] 
                const IndexToReplace = AtomCopy.findIndex(order=> order._id===JobId)
                AtomCopy[IndexToReplace] = querySnapshot.data()
                setLongHaulOrdersAtom(AtomCopy)
            }
        }, err => { console.log(`Encountered error: ${err}`) })
        return () => unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
	useEffect ( () => {
        if(JobData?.loading && Object.values(JobData?.loading)[0].formattedAddress){
            const AllLoadingCoordinates= Object.values(JobData?.loading)
            AllLoadingCoordinates.sort((a,b) => a.number - b.number)
            setLoadingLocations(AllLoadingCoordinates)
        }

        if(JobData?.dropOff && Object.values(JobData?.dropOff)[0].formattedAddress){
            const AllDropoffCoordinates = Object.values(JobData?.dropOff)
            AllDropoffCoordinates.sort((a,b) => a.number - b.number)
            setDropoffLocations(AllDropoffCoordinates)
        }

        if(bounds.getCenter().lat()!==0 &&  bounds.getCenter().lng()!==-180) {
			setBoundsCenter({lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: BoundsZoomLevel(bounds)})
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JobData]) 

    const TimelineItem = (location, index, type, multiple) => (
        <Timeline.Item key={index} color={`${type==='dropoff' ? '#009298' : '#15588D'}`}>
            <Divider className={type==='dropoff' ? 'SecondarySectionTitle' : 'SectionTitle'} orientation='left' plain style={{paddingTop: 2}}>
                { type==='dropoff' ? 
                    multiple ? `Dropoff Location # ${index+1}`: 'Dropoff Location' :
                    multiple ? `Pickup Location # ${index+1}`: 'Loading Location'
                }
            </Divider>
            <p className='RegularFont'>{location.formattedAddress ? location.formattedAddress : 'To be provided.'}</p>
            {location.additionalDetails && <p className='TitleDescription'>{location.additionalDetails}</p>}
            {location.contactName && IconDescription('#Avatar', `${location.contactName}`)}
            {location.contactPhone && IconDescription('#Phone', `${location.contactPhone}`)}
        </Timeline.Item>
    )

    const LocationsSummary = (loadingLocations, dropoffLocations) => (
        <>
            <Timeline>
                {   check(loadingLocations) && loadingLocations.length>0 &&
                    loadingLocations.map ((location, index) =>
                        TimelineItem(location, index, 'loading', loadingLocations.length>1)) 
                }
                {   check(dropoffLocations) && dropoffLocations.length>0 &&
                    dropoffLocations.map ((location, index) =>
                        TimelineItem(location, index, 'dropoff', dropoffLocations.length>1)) 
                }
            </Timeline>
            {   JobData?.specialRequests && 
                <>
                    <Divider className='SectionTitle HalfPaddingTop' orientation='left' plain>Additional Requests</Divider>
                    { JobData?.specialRequests?.lotOrder!==undefined && <p><span className='BoldFont'>Lot Order: </span>{JobData?.specialRequests?.lotOrder==='yes' ? 'Yes' : 'No'}</p>}
                    <p><span className='BoldFont'>Other Requests: </span>{JobData?.specialRequests?.otherRequests || '-'}</p>
                </>
            }
        </>
    )

	const LocationsCard = () => (
		<Card 
			title={CardHeader('Pickup and Dropoff Locations')} 
			className='FullWidth MarginBottom'
		>
			<div className='DisplayFlex'>
                <div className='HalfWidth SquareRatio'>
                    <div className='SolidifyRatio Padding OverflowScroll'>
						{LocationsSummary(loadingLocations, dropoffLocations)}
                    </div>
                </div>
                <div className='HalfWidth SquareRatio'>
                    <div className='SolidifyRatio'>
						<GoogleMap
							mapContainerClassName='FullWidthHeight'
							bounds={bounds}
							zoom={boundsCenter && boundsCenter.zoomlevel}
							center={{ lat: boundsCenter && boundsCenter.lat, lng: boundsCenter &&  boundsCenter.lng }}
							options={{ disableDefaultUI: true }}
						>
							{	loadingLocations.map((location, index) =>
								<Marker
									key={`Marker-${index}`}
									position={{lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}}
									icon={{ url: "https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-4.png?alt=media&token=c4b999ad-a6ab-49c8-ac7c-dba8a24eab9e" }}
								/>
							)}
							{	dropoffLocations.map((location, index) =>
								<Marker
									key={`Marker-${index}`}
									position={{lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}}
									icon={{ url: "https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-7.png?alt=media&token=26622ba5-db20-4e8d-8f17-2c75d83e9998" }}
								/>
							)}
						</GoogleMap>
                    </div>
                </div>
			</div>
		</Card>
	)

    const MovingStatusPanelHeader = (status, type) => (
        <div className='FlexColumn'>
            <p className={`BoldFont ${type==='Moving' ? 'PrimaryShadeColor' : 'SecondaryShadeColor'}`}>{ReadableString(status.status.replace('driver', ''))}</p>
            {   type==='Moving' && 
                parseInt(status.time)>moment().format('x') ?
                    <>
                        <div className='FlexRow'>
                            <svg className='MiniIcon MarginRight8'><use href={Sprite + '#EstimatedTime'}/></svg>
                            <p className='BoldFont BlackColor'>ETA:</p>
                        </div>
                        <p className='GrayColor MarginLeft'>
                            {moment(parseInt(status.time),'x').format('DD-MM-YYYY hh:mm A')}
                        </p>
                        <div className='FlexRow'>
                            <svg className='MiniIcon MarginRight8'><use href={Sprite + '#RemainingTime'}/></svg>
                            <p className='BoldFont BlackColor'>Remaining Time:</p>
                        </div>
                        <p className='GrayColor MarginLeft'>
                            <Statistic.Countdown className='StatusTimer' format='D 𝖣𝖺𝗒𝗌 HH 𝖧𝗈𝗎𝗋𝗌 mm 𝖬𝗂𝗇' value={moment(status.time, 'x')}/>
                        </p>
                    </> :
                    <>
                        <div className='FlexRow'>
                            <svg className='MiniIcon MarginRight8'><use href={Sprite + '#ActualTime'}/></svg>
                            <p className='BoldFont BlackColor'>Reached Time:</p>
                        </div>
                        <p className='GrayColor MarginLeft'>
                            {moment(parseInt(status.time),'x').format('DD-MM-YYYY hh:mm A')}
                        </p>
                    </>
            }
        </div>
    )

    const EmailsAndSpecialRequestsCard = () => (
		<Card
			title={CardHeader('Emails')} 
			className='HalfWidth HalfMarginRight'
		>
            <div className='HalfSquareRatio'>
                <div className='SolidifyRatio FullHeight Padding'>
                {   JobData &&
                    <>
                        <Divider className='SectionTitle' orientation='left'>Emails</Divider>
                        <div className='SummaryTable MarginBottom'>
                            {   JobData?.clientEmail && 
                                JobData.clientEmail.map (Email => IconDescription('#Email', Email, ''))
                            }
                        </div>
                        {JobData?.specialRequests?.otherRequests!=='' && <div>
                            <Divider className='SectionTitle' orientation='left'>Special Requests</Divider> 
                            <div className='SummaryTable'> 
                                <p>{JobData?.specialRequests?.otherRequests}</p>
                            </div>
                        </div>}
                    </>
                }
                </div>
            </div>
		</Card>
	)

    const DriverCard = () => (
		<Card
			title={CardHeader('Driver')} 
			className='HalfWidth HalfMarginTop'
		>
            <div className='HalfSquareRatio'>
                <div className='SolidifyRatio FullHeight Padding'>
                    <div className='SummaryTable MarginBottom'>
                        <p className='FontSize14'><span className='BoldFont'>Name: </span>{JobData?.driver?.driverName}</p>
                        <p className='FontSize14'><span className='BoldFont'>Phone: </span>{JobData?.driver?.driverPhone}</p>
                        <p className='FontSize14'><span className='BoldFont'>Vehicle Registration: </span>{JobData?.driver?.driverVehicleRegistration}</p>
                    </div>
                </div>
            </div>
		</Card>
	)

    const VehicleCategoryCard = () => (
		<Card 
			title={CardHeader('Vehicle Category and Freight Requirements')}
            className='HalfWidth HalfMarginRight'
		>
			{/* <div className='HalfSquareRatio'> */}
                <div className='FullHeight Padding FlexColumnBox'>
                    <p className='BoldFont PrimaryColor FontSize20 HalfMarginBottom'>Selected Vehicle</p>
                    <svg className='VehicleIcon'>
                        <use href={ 
                            (JobData?.vehicleInfo?.vehicleSubtype && JobData?.vehicleInfo?.vehicleSubtype.length>0) ?
                            Sprite + VehiclesEnum[JobData?.vehicleInfo?.vehicleType]?.[JobData?.vehicleInfo?.vehicleSubtype].icon :
                            Sprite + VehicleTypeEnum[JobData?.vehicleInfo?.vehicleType]?.icon
                        }/>
                    </svg>
                    <p className='BoldFont FontSize18 Capitalize'>{VehicleTypeEnum[JobData?.vehicleInfo?.vehicleType]?.long} {JobData?.vehicleInfo?.vehicleSubtype ? `- ${JobData?.vehicleInfo?.vehicleSubtype}` : ''}</p>
                    { JobData?.specialRequests?.reefer && <p><span className='BoldFont'>Container Type: </span>Reefer</p> }
                    <div className='MarginTop CenterAlign'>
                        <div className='FlexColumnBox'>
                            <p className='BoldFont PrimaryColor FontSize20'>Freight Requirements</p>
                            <p><span className='BoldFont'>Type: </span>{JobData?.shipmentInfo?.freightType} </p>
                            {JobData?.shipmentInfo?.freightCount && <p><span className='BoldFont'>Count: </span>{JobData?.shipmentInfo?.freightCount}</p>} 
                            {JobData?.shipmentInfo?.freightWeight && <p><span className='BoldFont'>Weight: </span>{parseFloat(JobData?.shipmentInfo?.freightWeight).toFixed(2)} Tons</p>}
                        </div>
{/*                         
                        { (JobData?.vehicleInfo?.vehicleSubtype) ?
                            <p className='Capitalize'>
                                Tonnage: {VehiclesEnum[JobData?.vehicleInfo?.vehicleType]?.[JobData?.vehicleInfo?.vehicleSubtype].tonnage} Tons <br/>
                                Dimensions: {VehiclesEnum[JobData?.vehicleInfo?.vehicleType]?.[JobData?.vehicleInfo?.vehicleSubtype].dimensions}
                            </p> :
                            <p className='Capitalize'>
                            Tonnage: {VehiclesEnum[JobData?.vehicleInfo?.vehicleType]?.tonnage} Tons<br/>
                            Dimensions: {VehiclesEnum[JobData?.vehicleInfo?.vehicleType].dimensions}
                        </p> 
                        } */}
                    </div>
                </div>
			{/* </div> */}
		</Card>
	)

    const DriverTrackingPanel = () => {
		return JobData?.driverStatusHistory?.length>0 ? 
			<div className='LiveTrackingPanels Padding'>
				<Collapse defaultActiveKey={JobData.driverStatusHistory.length-1} accordion expandIconPosition={'right'} expandIcon={ ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
					{   JobData.driverStatusHistory.map( (status, index) => 
                            <Collapse.Panel
                                key={index} 
                                header={MovingStatusPanelHeader(status, status.status.includes('MovingTo') ? 'Moving' : 'Reacehd')}
                                className={status.status.includes('MovingTo') ? 'PrimaryCollapsePanel' : 'SecondaryCollapsePanel'}
                                collapsible={!status?.statusJourney?.filter(subStatus=> subStatus.type!=='breakdown').length>0 && 'disabled'}
                                showArrow={status?.statusJourney?.filter(subStatus=> subStatus.type!=='breakdown').length>0 ? true : false}
                            >
                                <Timeline>
                                    {	status?.statusJourney?.filter(subStatus=> subStatus.type!=='breakdown')?.map(subStatus =>
                                            subStatus.type!=='breakdown' &&
                                            <Timeline.Item color={'#15588D'}>
                                                <p className='BoldFont Capitalize PrimaryShadeColor'> {ReadableString(subStatus.status)} </p>
                                                <p className='TitleDescription'>{moment(parseInt(subStatus.time)).format('DD-MM-YYYY hh:mm A')}</p>
                                            </Timeline.Item>
                                        )
                                    }
                                </Timeline> 
                            </Collapse.Panel>
                    )}
				</Collapse>
			</div>  :
			<p className='TitleDescription Padding CenterAlign'>Driver status will<br/>be updated shortly.</p>
	}

	return (
        <Layout>
			<Header>
                <Breadcrumb separator='>'>
                    <Breadcrumb.Item className='Heading'><a href={`/${collection}`}>{'Long Haul Orders'}</a></Breadcrumb.Item>
                    <Breadcrumb.Item className='Heading'>Order No. {JobData.subOrderNumber || JobData.orderNumber}</Breadcrumb.Item>
                </Breadcrumb>
                {   parseInt(JobData.createdAt)>1631646000000 && // 15 September 2021 00:00 AM Onwards
                    <Button
                        className='Button DuplicateOrderButton' 
                        onClick={() => 
                            props.history.push({
                                pathname: `/${collection}/create`,
                                state: { DuplicateData: JobData }
                            })
                        }
                    > Duplicate Order </Button>
                }
			</Header>
			{	loader ? <Loader/> :
                <Layout>
                    <Layout>
                        <Header className='NoPadding'>
                            {   JobData?.orderStatus?.status && 
                                <div className='FullWidthHeight FlexRowSpace PaddingLeft PaddingRight' 
                                    style={{backgroundColor: ExportOrderStatusEnum[JobData.orderStatus.status].background}}
                                >
                                    <p 
                                        className='OrderStatusStyle' 
                                        style={{
                                            color: ExportOrderStatusEnum[JobData.orderStatus.status].color, 
                                            WebkitTextStroke: `1px ${ExportOrderStatusEnum[JobData.orderStatus.status].color}`,
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        {ExportOrderStatusEnum[JobData.orderStatus.status].text}
                                    </p>
                                    <div className='FlexRow'>
                                        {   JobData?.finalizedRate &&
                                            <div className='OrderStickyInfo HalfMarginRight' style={{border: `1px solid ${ExportOrderStatusEnum[JobData.orderStatus.status].color}`}}>
                                                <div className='FullWidthHeight FlexColumnBox'>
                                                    <p className='BoldFont' style={{color: ExportOrderStatusEnum[JobData.orderStatus.status].color}}>Cost</p>
                                                    <p className='TitleDescription'>Rs. {JobData.finalizedRate}</p>
                                                </div>
                                            </div>
                                        }
                                        <div className='OrderStickyInfo' style={{border: `1px solid ${ExportOrderStatusEnum[JobData.orderStatus.status].color}`}}>
                                            <div className='FullWidthHeight FlexColumnBox'>
                                                <p className='BoldFont' style={{color: ExportOrderStatusEnum[JobData.orderStatus.status].color}}>Creation Date</p>
                                                <p className='TitleDescription'>{moment(JobData?.createdAt,'x').format('DD-MM-YYYY hh:mm A')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Header>
                        <Content>
                            {LocationsCard()}
                            <div className='DisplayFlex'>
                                {VehicleCategoryCard()}
                                {EmailsAndSpecialRequestsCard()}
                            </div>
                            {JobData?.driver && JobData?.driver?.driverPhone && JobData?.driver?.driverVehicleRegistration && DriverCard()}
                            
                        </Content>
                    </Layout>
                    <Sider width={288}>
                        <div>
                            <div className='DriverTrackingHeader BorderBottom'>
                                <div className='FullWidthHeight FlexRow'>
                                    <p className='BoldFont PrimaryColor FontSize16'>Driver Tracking</p>
                                </div>
                            </div>
                            {DriverTrackingPanel()}
                        </div>
                    </Sider>
                </Layout>
			}
		</Layout>
	)
}

export default ViewExportOrder