import React, { useEffect, useState } from 'react'
import { Timeline, Layout, Card,Divider, Collapse, Row, Col, Image, Button} from 'antd'
import { Breadcrumb } from 'antd'
import moment from 'moment'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { firestore } from '../../firebase/firebaseConfig'

import { useRecoilState, useRecoilValue } from "recoil"

import { CaretRightOutlined, FileTwoTone } from '@ant-design/icons'
import { check, BoundsZoomLevel, Loader, CurrentPageId, CurrentCollection, LocalOrderStatusEnum, ReadableString, VehicleTypeEnum, CardHeader, VehiclesEnum, IconDescription} from '../../helpers/Functions'
import { loaderAtom, localOrdersAtom } from '../../recoil/atoms'

import { singleLocalOrderSelector } from '../../recoil/selectors';

import Sprite from '../../assets/icons/Sprite.svg'


const { Header, Content, Sider } = Layout

const ViewLocalOrder = (props)  => {
    const JobId = CurrentPageId(props.location.pathname)
	const collection = CurrentCollection(props.location.pathname)
    const loader = useRecoilValue(loaderAtom)

    const JobData = useRecoilValue(singleLocalOrderSelector(JobId))
    const [localOrders, setLocalOrdersAtom] = useRecoilState(localOrdersAtom)

    const bounds = new window.google.maps.LatLngBounds()
	const [boundsCenter, setBoundsCenter] = useState({lat: 31.5204489, lng : 74.3586184, zoomlevel: 10})

	const [loadingLocations, setLoadingLocations] = useState([])
	const [dropoffLocations, setDropoffLocations] = useState([])
    const [journey, setJourney] = useState([])

    useEffect(()=> {
        const unsubscribe = firestore.collection('jobs').doc(JobId).onSnapshot(querySnapshot => {
            const AtomCopy = [...localOrders]
            const IndexToReplace = AtomCopy.findIndex(order=> order._id===JobId)
            AtomCopy[IndexToReplace] = querySnapshot.data()
            setLocalOrdersAtom(AtomCopy)
        }, err => { console.log(`Encountered error: ${err}`) })
        return () => unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	useEffect ( () => {
        const AllPickupCoordinates = []
        Object.values(JobData?.clientCoordinates?.pickUp).map(pickup => AllPickupCoordinates.push(pickup))
        AllPickupCoordinates.sort((a,b) => a.number - b.number)
        const PickupLocationsInfo = AllPickupCoordinates.map(location => {
            return {...location, ...{...JobData?.packageInfo[location._id]}}
        })
        setLoadingLocations(PickupLocationsInfo)

        const AllDropoffCoordinates = []
        Object.values(JobData?.clientCoordinates?.dropOff).map(dropoff => AllDropoffCoordinates.push(dropoff))
        AllDropoffCoordinates.sort((a,b) => a.number - b.number)
        const DropoffLocationsInfo = AllDropoffCoordinates.map(location => {
            return {...location, ...{...JobData?.packageInfo[location._id]}}
        })
        setDropoffLocations(DropoffLocationsInfo)

        PickupLocationsInfo.map(location => bounds.extend({lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}))
        DropoffLocationsInfo.map(location => bounds.extend({lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}))
		setBoundsCenter({lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: BoundsZoomLevel(bounds)})

        let Journey = []
        Journey = JobData?.journey?.flow && Object.values(JobData.journey.flow).sort((a,b) => a.number - b.number)
        setJourney(Journey)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JobData])

    const CheckDriverStatus = () => {
        return ( JobData && JobData.driverStatus.status !== 'driverInit' && JobData.driverStatus.status !== 'driverAccepted' && JobData.driverStatus.status !== 'driverMovingToPickup')
    }

    const TimelineItem = (location, index, type, multiple) => (
        <Timeline.Item key={index} color={`${type==='dropoff' ? '#009298' : '#15588D'}`}>
            <Divider className={type==='dropoff' ? 'SecondarySectionTitle' : 'SectionTitle'} orientation='left' plain style={{paddingTop: 2}}>
                { type==='dropoff' ? 
                    multiple ? `Dropoff Location # ${index+1}`: 'Dropoff Location' :
                    multiple ? `Pickup Location # ${index+1}`: 'Pickup Location'
                }
            </Divider>

            <p className='Title' style={{color: `${type==='dropoff' ? '#009298' : '#15588D'}`}}>Location Information</p>
            <p className='RegularFont'>{location.formattedAddress}</p>
            {location.additionalDetails && <p className='TitleDescription'>{location.additionalDetails}</p>}
            {location.contactName && IconDescription('#Avatar', `${location.contactName}`)}
            {location.contactPhone && IconDescription('#Phone', `${location.contactPhone}`)}

            <p className='Title' style={{paddingTop: 8, color: `${type==='dropoff' ? '#009298' : '#15588D'}`}}>Freight Information</p>
            {   type!=='dropoff' &&  <>
                    <p><span className='BoldFont Capitalize'>Freight Type: </span>{location.freightDetails}</p>
                    <p className='Capitalize'><span className='BoldFont'>Packaging Type: </span>{location.typeOfFreight}</p>
                </>
            }
            <p><span className='BoldFont Capitalize'>No of Units (Count): </span>{location.noOfUnits}</p>
            <p className='Capitalize'><span className='BoldFont'>Freight Weight: </span>{location.freightWeight} {location.freightWeightUnit}</p>
        </Timeline.Item>
    )

    const LocationsSummary = (loadingLocations, dropoffLocations) => (
        <Timeline>
            {   check(loadingLocations) && loadingLocations.length>0 &&
                loadingLocations.map ((location, index) =>
                    TimelineItem(location, index, 'pickup', loadingLocations.length>1)) 
            }
            {   check(dropoffLocations) && dropoffLocations.length>0 &&
                dropoffLocations.map ((location, index) =>
                    TimelineItem(location, index, 'dropoff', dropoffLocations.length>1)) 
            }
        </Timeline>
    )

	const LocationsCard = () => (
		<Card 
			title={CardHeader('Pickup and Dropoff Locations')} 
			className='FullWidth MarginBottom'
		>
			<div className='DisplayFlex'>
                <div className='HalfWidth SquareRatio'>
                    <div className='SolidifyRatio Padding OverflowScroll'>
						{LocationsSummary(loadingLocations, dropoffLocations)}
                    </div>
                </div>
                <div className='HalfWidth SquareRatio'>
                    <div className='SolidifyRatio'>
						<GoogleMap
							mapContainerClassName='FullWidthHeight'
							bounds={bounds}
							zoom={boundsCenter && boundsCenter.zoomlevel}
							center={{ lat: boundsCenter && boundsCenter.lat, lng: boundsCenter &&  boundsCenter.lng }}
							options={{ disableDefaultUI: true }}
						>
							{	loadingLocations.map((location, index) =>
								<Marker
									key={`Marker-${index}`}
									position={{lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}}
									icon={{ url: "https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-4.png?alt=media&token=c4b999ad-a6ab-49c8-ac7c-dba8a24eab9e" }}
								/>
							)}
							{	dropoffLocations.map((location, index) =>
								<Marker
									key={`Marker-${index}`}
									position={{lat: parseFloat(location.coordinates.lat), lng: parseFloat(location.coordinates.lng)}}
									icon={{ url: "https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-7.png?alt=media&token=26622ba5-db20-4e8d-8f17-2c75d83e9998" }}
								/>
							)}
                            {	!['jobFinished', 'jobCancelled'].includes(JobData.jobStatus.status) &&
                                JobData?.driverCurrentCoordinates?.coordinates?.lat &&
                                JobData?.driverCurrentCoordinates?.coordinates?.lng &&
								<Marker
									key={`DriverLocationMarker`}
									position={{lat: parseFloat(JobData?.driverCurrentCoordinates?.coordinates?.lat), lng: parseFloat(JobData?.driverCurrentCoordinates?.coordinates?.lng)}}
									icon={{ url: "https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FGroup%2015%20(1).png?alt=media&token=0d77c9fb-cfc9-4b55-a557-c8a4fc0dcfb4" }}
								/>
							}
						</GoogleMap>
                    </div>
                </div>
			</div>
		</Card>
	)
	
	const VehicleCategoryCard = () => (
		<Card 
			title={CardHeader('Vehicle Category')}
            className='HalfWidth HalfMarginRight'
		>
			<div className='HalfSquareRatio'>
                <div className='SolidifyRatio FullHeight Padding FlexColumnBox'>
                    <svg className='VehicleIcon'>
                        <use href={ 
                            (JobData?.vehicleSubtype && JobData?.vehicleSubtype.length>0) ?
                            Sprite + VehiclesEnum[JobData?.vehicleType]?.[JobData?.vehicleSubtype].icon :
                            Sprite + VehicleTypeEnum[JobData?.vehicleType]?.icon
                        }/>
                    </svg>
                    <div className='MarginTop CenterAlign'>
                        <p className='BoldFont FontSize18 Capitalize'>{VehicleTypeEnum[JobData?.vehicleType]?.long} {JobData?.vehicleSubtype ? `- ${JobData?.vehicleSubtype}` : ''}</p>
                        { JobData?.vehicleSubtype &&
                            <p className='Capitalize'>
                                Tonnage: {VehiclesEnum[JobData?.vehicleType]?.[JobData?.vehicleSubtype].tonnage} <br/>
                                Dimensions: {VehiclesEnum[JobData?.vehicleType]?.[JobData?.vehicleSubtype].dimensions}
                            </p>
                        }
                    </div>
                </div>
			</div>
		</Card>
	)

	const DocumentsCard = () => (
		<Card
			title={CardHeader('Documents')} 
			className='HalfWidth HalfMarginLeft'
		>
            <div className='HalfSquareRatio'>
                <div className='SolidifyRatio FullHeight Padding OverflowScroll'>
                {   JobData &&
                    <div className='CollapsePanels'>
                        <Collapse accordion expandIconPosition={"right"} expandIcon={ ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                            <Collapse.Panel header='Client Upload' key='ClientUploads' className='CollapseHeader'> 
                                <Row className='HalfPadding'>
                                    {	JobData?.clientImages && Object.values(JobData?.clientImages)?.[0]?.length>0 &&
                                        <div className='UploadImageBox'>
                                            <Col style={{margin: '0px 1em'}}>
                                                {   /.jpg|.jpeg|.jpe .jif|.jfif|.jfi|.png|.gif|.webp|.raw|.arw|.cr2|.nrw|.k25|.bmp|.dib|.jp2|.j2k|.jpf|.jpx|.jpm|.mj2/.test(Object.values(JobData?.clientImages)[0][0]) ?
                                                    <Image width={70} height={70} src={Object.values(JobData?.clientImages)[0][0]} className='UploadImage'/> :
                                                    <FileTwoTone className='CursorPointer' style={{ fontSize: '70px' }} twoToneColor="#1C75BC" onClick={()=>  window.open(Object.values(JobData?.clientImages)[0][0], '_blank').focus()}/>
                                                }
                                            </Col>
                                        </div>
                                    }
                                </Row>
                            </Collapse.Panel>
                            {	JobData.driverImages && 
                                journey.map( (journeyInstance, index) => (
                                    JobData.driverImages[journeyInstance._id] && 
                                    <Collapse.Panel header={index===0 ? "Pickup" : `Drop-off # ${index}`} key={index} className='CollapseHeader'> 
                                        <Row className='HalfPadding'>
                                            {Object.keys(JobData.driverImages[journeyInstance._id]).map((documentName,index) => (
                                                <div className='UploadImageBox'>
                                                    <Col key={index}>
                                                        <Image width={70} height={70} src={JobData.driverImages[journeyInstance._id][documentName]} className='UploadImage'/>
                                                    </Col>
                                                    <p className='Capitalize'>{ReadableString(documentName)}</p>
                                                </div>
                                            ))}
                                        </Row>
                                    </Collapse.Panel>
                            ))}
                        </Collapse>
                    </div>
                }
                </div>
            </div>
		</Card>
	)

    const DriverTrackingPanel = () => {
		return CheckDriverStatus () ? 
			<div className='LiveTrackingPanels Padding'>
				<Collapse accordion expandIconPosition={"right"} expandIcon={ ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
					{   // eslint-disable-next-line 
                        JobData.journey && journey.length>0 && journey.map( (jouneryInstance, index) => {
						let JourneyStatuses = []
						if (JobData?.driverStatusHistory?.[jouneryInstance._id]) {
							JourneyStatuses = [...JobData.driverStatusHistory[jouneryInstance._id]]
							if( JobData?.unscheduledStops?.[jouneryInstance._id]) {
								JourneyStatuses = [...JourneyStatuses, ...JobData.unscheduledStops[jouneryInstance._id]]
							}
							JourneyStatuses.sort((a,b) => parseInt(a.timestamp || a.startTime) - parseInt(b.timestamp || b.startTime))
                            return (
                                !JourneyStatuses?.[0]?.status.includes('Base') &&
								<Collapse.Panel
                                    key={index}
                                    header={<p className={`BoldFont ${index===0 ? 'PrimaryShadeColor' : 'SecondaryShadeColor'}`}>{index===0 ? 'Pickup' : `Dropoff # ${index}`}</p>}
                                    className={index===0 ? 'PrimaryCollapsePanel' : 'SecondaryCollapsePanel'}
                                >
									<Timeline>
										{	// eslint-disable-next-line 
                                            JourneyStatuses.map((status) => {
												if(check(status.startTime) && check(status.stopType)) {
													return (
														<Timeline.Item color='#F44336'>
															<p className='BoldFont Capitalize ErrorColor'> {ReadableString(status.stopType.replace('driver', ''))} </p>
                                                            <p className='TitleDescription' style={{color:'#757575'}}>{moment(parseInt(status.startTime)).format('DD-MM-YYYY hh:mm A')}</p>
														</Timeline.Item>
													)
												} else if (!['driverAccepted', 'driverInit', 'driverMovingToPickup', 'driverCheckout'].includes(status.status)) {
                                                    return (
                                                        <Timeline.Item color={index===0 ? '#15588D' : '#009298'}>
                                                            <p className={`BoldFont Capitalize ${index===0 ? 'PrimaryShadeColor' : 'SecondaryShadeColor'}`}> {ReadableString(status.status.replace('driver', ''))} </p>
                                                            <p className='TitleDescription'>{moment(parseInt(status.timestamp)).format('DD-MM-YYYY hh:mm A')}</p>
                                                        </Timeline.Item>
                                                    )
												}
											})
										}
									</Timeline> 
								</Collapse.Panel>
							)
					}})}
				</Collapse>
			</div>  :
			<p className='TitleDescription Padding CenterAlign'>Driver has not reached<br/>the pickup point yet.</p>
	}

	return (
        <Layout>
			<Header>
                <Breadcrumb separator='>'>
                    <Breadcrumb.Item className='Heading'><a href={`/${collection}`}>Local Orders</a></Breadcrumb.Item>
                    <Breadcrumb.Item className='Heading'>Order No. {JobData?.orderNumber}</Breadcrumb.Item>
                </Breadcrumb>
                <Button 
                    className='Button DuplicateOrderButton' 
                    onClick={() => 
                        props.history.push({
                            pathname: '/orders/create',
                            state: { DuplicateData: JobData }
                        })
                    }
                > Duplicate Order </Button>
			</Header>
			{	loader ? <Loader/> :
                <Layout>
                    <Layout>
                        <Header className='NoPadding'>
                            {   JobData?.jobStatus?.status && 
                                <div className='FullWidthHeight FlexRowSpace PaddingLeft PaddingRight' style={{backgroundColor: LocalOrderStatusEnum[JobData.jobStatus.status].background}}>
                                    <p 
                                        className='OrderStatusStyle' 
                                        style={{
                                            color: LocalOrderStatusEnum[JobData.jobStatus.status].color, 
                                            WebkitTextStroke: `1px ${LocalOrderStatusEnum[JobData.jobStatus.status].color}`,
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        {LocalOrderStatusEnum[JobData.jobStatus.status].text}
                                    </p>
                                    <div className='FlexRow'>
                                        <div className='OrderStickyInfo' style={{border: `1px solid ${LocalOrderStatusEnum[JobData.jobStatus.status].color}`}}>
                                            <div className='FullWidthHeight FlexColumnBox'>
                                                <p className='BoldFont' style={{color: LocalOrderStatusEnum[JobData.jobStatus.status].color}}>Creation Date</p>
                                                <p className='TitleDescription'>{moment(JobData?.createdAt,'x').format('DD-MM-YYYY hh:mm A')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Header>
                        <Content>
                            {LocationsCard()}
                            <div className='DisplayFlex'>
                                {VehicleCategoryCard()}
                                {DocumentsCard()}
                            </div>
                        </Content>
                    </Layout>
                    <Sider width={240}>
                        <div>
                            <div className='DriverTrackingHeader BorderBottom'>
                                <div className='FullWidthHeight FlexRow'>
                                    <p className='BoldFont PrimaryColor FontSize16'>Driver Tracking</p>
                                </div>
                            </div>
                            {DriverTrackingPanel()}
                        </div>
                    </Sider>
                </Layout>
			}
		</Layout>
	)
}

export default ViewLocalOrder