import React, {useEffect, useState} from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Layout, Card, Popover } from 'antd'

import { firestore } from './../firebase/firebaseConfig';

import { GoogleMap, Marker } from '@react-google-maps/api'

import { loaderAtom, importOrdersAtom, exportOrdersAtom, longHaulOrdersAtom, activeBusinessCodeAtom } from '../recoil/atoms'
import { dashboardLiveTrackingSelector } from '../recoil/selectors'
import { ReadableString, Loader, CardHeader, BoundsZoomLevel, PakistanPoints } from '../helpers/Functions'
import moment from 'moment';

const { Header, Content } = Layout

const Dashboard = (props) => {
	const loader = useRecoilValue(loaderAtom)
	const liveTracking = useRecoilValue(dashboardLiveTrackingSelector)
	const setImportOrdersAtom = useSetRecoilState(importOrdersAtom)
	const setExportOrdersAtom = useSetRecoilState(exportOrdersAtom)
	const setLongHaulOrdersAtom = useSetRecoilState(longHaulOrdersAtom)
	const activeBusinessCode = useRecoilValue(activeBusinessCodeAtom)
	const bounds = new window.google.maps.LatLngBounds()
	const [boundsCenter, setBoundsCenter] = useState({lat: 31.5204489, lng : 74.3586184, zoomlevel: 10})
	const [selectedRecord, setSelectedRecord] = useState()

	useEffect(()=> {
		PakistanPoints.forEach(point => bounds.extend({lat: parseFloat(point.lat), lng: parseFloat(point.lng)}))
		setBoundsCenter({lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: BoundsZoomLevel(bounds)})

		const unsubscribeImportExport = firestore.collection('import_export').where('businessCode', '==', activeBusinessCode).onSnapshot(querySnapshot => {
			const ListenerData = querySnapshot.docs.map(doc=>({...doc.data()}))
			setImportOrdersAtom(ListenerData.filter(order => order.type==='import'))
			setExportOrdersAtom(ListenerData.filter(order => order.type==='export'))
		}, err => { console.log(`Encountered error: ${err}`) })
		const unsubscribeLongHaul = firestore.collection('long_haul').where('businessCode', '==', activeBusinessCode).onSnapshot(querySnapshot => {
			const ListenerData = querySnapshot.docs.map(doc=>({...doc.data()}))
			setLongHaulOrdersAtom(ListenerData)
			
		}, err => { console.log(`Encountered error: ${err}`) })
		return () => {
			unsubscribeImportExport()
			unsubscribeLongHaul()
		}
    }, [activeBusinessCode]) // eslint-disable-line

	useEffect(() => {
		const Coordinates = selectedRecord?.driver?.driverCurrentCoordinates?.coordinates
		if(Coordinates) {
			setBoundsCenter({
				lat: parseFloat(Coordinates?.lat), 
				lng: parseFloat(Coordinates?.lng), 
				zoomlevel: 12
			})
		}
	}, [selectedRecord])

	const OrderTracking  = (order) => (
		<div
			key={order._id}
			className={`DriverData CursorPointer ${selectedRecord?._id===order?._id && 'GrayBackgroundColor'}`}
			onClick={() => setSelectedRecord(order)}
			onMouseEnter={()=>(<p>Hello</p>)}
		>
			<p className='BoldFont FontSize18'>{order?.subOrderNumber || order?.orderNumber}</p>
			<p className='BoldFont GrayColor Capitalize FontSize16'>{ReadableString(order.type)}</p>
			<p className='RegularFont'><span className=' GrayColor'>Phone No: </span>{order?.driver?.driverPhone && order?.driver?.driverPhone.includes('+92') ? order?.driver?.driverPhone : `+92${order?.driver?.driverPhone}` }</p>
			<p className='RegularFont'><span className=' GrayColor'>Vehicle No: </span>{order?.driver?.driverVehicleRegistration}</p>
		</div>
	)

	const DriverStatus = (order, index) => {
		const LastStatus = order?.driverStatusHistory[order?.driverStatusHistory?.length-1]
		const LastSubStatus = LastStatus?.statusJourney?.[LastStatus?.statusJourney?.length-1]
		return	<Popover 
					key={order._id}
					placement='right' 
					content={	order?.driverStatusHistory?.length>0 && 
						<div className='BorderRadius HalfPadding'>
							<p className='Capitalize BoldFont'>{ReadableString(LastStatus?.status)}</p>
							<p className='Capitalize'>{LastSubStatus && LastSubStatus?.type!=='breakdown' && ReadableString(LastSubStatus?.status)}</p>
							<p className='FontSize12'>{LastSubStatus?.time && moment(LastSubStatus?.time,'x').format('DD-MM-YYYY hh:mm A')}</p>
						</div>
					}
				>{OrderTracking(order)}</Popover>
	}
	return (
		<Layout>
			<Header>
				<div className='Heading'>Dashboard</div>
			</Header>
			<Content>
				{	loader ? <Loader/> : 
					<Card title={CardHeader('Live Tracking', 'Get live location of all in-progress orders')} >
						<div className='HalfSquareRatio'>
							<div className='DisplayFlex SolidifyRatio'>
								<div className='DriversList'>
									{	liveTracking?.map (order => (order?.driverStatusHistory && order?.driverStatusHistory?.length>0) ? DriverStatus(order) : OrderTracking(order))}
								</div>
								<div className='TrackingMap'>
									<GoogleMap
										mapContainerClassName='FullWidthHeight'
										bounds={bounds}
										zoom={boundsCenter?.zoomlevel}
										center={{ lat: boundsCenter?.lat, lng: boundsCenter?.lng }}
										options={{ disableDefaultUI: true }}
									>
										{ liveTracking?.map((order, index) =>
											<Marker
												key={index}
												position={{lat: parseFloat(order.driver?.driverCurrentCoordinates?.coordinates?.lat), lng: parseFloat(order.driver?.driverCurrentCoordinates?.coordinates?.lng)}}
												icon={{ url: 
													order.type==='import' ?
														"https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-4.png?alt=media&token=c4b999ad-a6ab-49c8-ac7c-dba8a24eab9e" :
															order.type==='export' ?
																"https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-7.png?alt=media&token=26622ba5-db20-4e8d-8f17-2c75d83e9998" :
																	order.type==='longHaul' &&
																		"https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2FVector-11.png?alt=media&token=4020c80f-0a3d-4c2f-a931-6da5148ae182" 
												}}
												label={order.driver?.driverVehicleRegistration && {
													text:  order.driver?.driverVehicleRegistration,
													fontFamily: 'Bold',
													className: 'CardHeaderColor BorderRadius QuarterPaddingLeftRight Capitalize'
												}}
											/>
										)}
									</GoogleMap>
								</div>
							</div>
						</div>
					</Card>
				}
			</Content>
		</Layout>
	)
}



export default Dashboard