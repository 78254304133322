import React, { useState } from 'react';
import './Auth.css';
import Logo from './../../logo.png';
import {Form, Input, Button, notification, Spin} from 'antd';
import {FlagIcon} from './../../assets'
import { Auth, firebase } from '../../firebase/firebaseConfig';

const Login = (props) => { 
    // console.log({props}, props.loading)
    const [loading, setLoading] = useState(false)
    const [registerClicked, setRegisterClicked] = useState(false)
    
    const [smsSent, setSMSSent] = useState(false)

    const onFinish = async (values) => {
        setLoading(true)
        if(!smsSent) {
            setRegisterClicked(true)
            let appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
            Auth.signInWithPhoneNumber(`+92${values.registeredNumber}`, appVerifier)
                .then( confirmationResult =>  {
                    // console.log(confirmationResult)
                    window.confirmationResult = confirmationResult
                    setSMSSent(true)
                })
                .catch(error => console.log(error))
            setLoading(false)
        } else {
            window.confirmationResult.confirm(values.verificationCode)
                .then( result => {
                    // var user = result.user;
                    setLoading(false)
                })
                .catch(error => {
                    notification['error']({
                        message: 'Verification Code',
                        description: error.message,
                        duration: 5,
                        onClick: () => { notification.close() }
                    })
                    setLoading(false)
                })
        }
    }
    // console.log({loading});
    return (
        <div className='AuthBackground'>
            <div className='AuthBox'>
                <Form onFinish={onFinish} >
                    <img className='BridgelinxLogo' src={Logo} alt=''/>
                    {   !smsSent ?
                        <>
                            <Form.Item
                                name={'registeredNumber'}
                                rules={[{ required: true, message: 'Phone Number is Required.' }]}
                            >
                                <div className='FlexRow'>
                                    <div className='AuthNumberPrefix'>
                                        <img className='FlagIcon' src={FlagIcon} alt=''/>
                                        <p className='CountryCode'>+92</p>
                                    </div>
                                    <Input className='InputAuthNumber' maxLength={10} type='number' placeholder={'3000000000'} />
                                </div>
                            </Form.Item>
                            {!registerClicked && <Button className='AuthSubmitButton' htmlType="submit">Continue</Button>}
                            <div id="recaptcha-container"/>
                        </> :
                        <>
                            <Form.Item
                                name={'verificationCode'}
                                rules={[{ required: true, message: 'Verification Code is Required.' }]}
                            >
                                <div className='FlexRow'>
                                    <Input className='VerificationCode' type='number' placeholder={'000000'} />
                                </div>
                            </Form.Item>
                            <Button className='AuthSubmitButton' htmlType="submit">Verify</Button>
                        </>
                    }
                    {!registerClicked && 
                        <p className='Shift'>Need an account?
                            <span className='ShiftSpan' onClick={() => props.history.push('/signup')}> Register </span>
                        </p>
                    }
                    {(props.loading || loading) && <Spin className='AuthBox Loading' size="large" tip="Loading" />}
                </Form>
            </div>
        </div>
    )
}
export default Login; 