import { selector, selectorFamily } from 'recoil'
import { check } from '../helpers/Functions'
import * as AllAtoms from '../recoil/atoms';

export const dynamicTableSelector = selectorFamily({
    key: 'dynamicTableSelector',
    get: (collection) => ({get}) => {
        if(collection === 'orders') { return get(localOrdersSelector) } 
        else if(['imports', 'exports'].includes(collection)) { return get(ImportExportListingSelector(collection)) } 
        else if(collection === 'drivers') { return get(corporateDriversSelector) }
        else if(collection === 'bulk') { return get(LongHaulListingSelector) } 
    }
})

export const localOrdersSelector = selector({
    key: 'localOrdersSelector',
    get: ({get}) => {
        const AllJobs =  get(AllAtoms.localOrdersAtom) 
        const MappedJobs = []
        AllJobs.forEach(job => { MappedJobs.push(get(singleLocalOrderSelector(job))) })
        return (MappedJobs)
    }
})

export const singleLocalOrderSelector = selectorFamily({
    key: 'singleLocalOrderSelector',
    get: (passedJob) => ({get}) => {
        let Job = {}
        if((typeof passedJob) === 'string') { Job = get(AllAtoms.localOrdersAtom).find(job => job._id === passedJob) } 
        else { Job = passedJob }
        const Driver = check(Job.driverId) ? get(AllAtoms.driversAtom).find(driver => driver._id === Job.driverId) : null
        const DriverProfile = check(Driver) ? get(AllAtoms.profilesAtom).find(profile => profile.profileId === Driver.profileInfo) : null
        const Vehicle = get(AllAtoms.vehiclesAtom).find(vehicle => vehicle._id === Job.vehicleId)
        return  { ...Job, driver: { ...Driver, profile: DriverProfile }, vehicle: Vehicle }
    }
})

export const ImportExportListingSelector = selectorFamily({
    key: 'ImportExportListingSelector',
    get: (passedCollection) => ({get}) => {
        const AllJobs = (passedCollection==='imports') ? get(AllAtoms.importOrdersAtom) : get(AllAtoms.exportOrdersAtom)
        const FilteredJobs = []
        const longHaulOrders = get(AllAtoms.longHaulOrdersAtom)
        AllJobs.forEach(Job=> {
            if(Job?.linkedLongHaulOrder){
                const linked = longHaulOrders?.find(order=> order?._id === Job?.linkedLongHaulOrder)
                if (linked && linked.createdAt && parseInt(linked.createdAt) >= 1635501729438){
                    Job = {...Job, driver: linked.driver, driverStatusHistory: linked.driverStatusHistory, linkedImportOrders: linked?.linkedImportOrders, subOrderNumber: linked?.subOrderNumber, vehicleInfo: linked?.vehicleInfo, type: 'longHaul', orderStatus: linked?.orderStatus, dropOff: linked?.dropOff}
                }
            }
            if(Job.orderStatus.status==='orderOngoing' && Job.orderStatus.internalStatus) {
                const LastStatus = Job?.driverStatusHistory?.find(status => status.status==='driverReachedDropoffLocation')
                const LastSubStatus = LastStatus?.statusJourney?.[LastStatus?.statusJourney.length-1]
                if(LastSubStatus?.status==='unloadingCompleted') {
                    Job = { ...Job, orderStatus: { ...Job.orderStatus, status: 'orderCompleted' } }
                }
            }
            FilteredJobs.push(Job)
        })
        const GroupedOrders = FilteredJobs.reduce((previous, current) => {
			if (!previous[current['orderNumber']]) { previous[current['orderNumber']] = [] }
			previous[current['orderNumber']].findIndex(order=> order.subOrderNumber === current.subOrderNumber)===-1 && previous[current['orderNumber']].push(current)
			return previous
		}, {})
        const MappedJobs = Object.values(GroupedOrders).map(orderGroup => {
            return  (orderGroup.length===1 && orderGroup[0]?.type!=='longHaul') ? 
                        orderGroup[0] : 
                        {
                            _id: orderGroup[0].orderNumber,
                            orderNumber: orderGroup[0].orderNumber, 
                            loading: orderGroup[0].loading,
                            dropOff: orderGroup[0].dropOff,
                            specialRequests: orderGroup[0]?.specialRequests,
                            subOrders: orderGroup.sort((a,b) => a.subOrderNumber?.localeCompare(b.subOrderNumber))
                        }
        })
        return MappedJobs
    }
})

export const LongHaulListingSelector = selector({
    key: 'LongHaulListingSelector',
    get: ({get}) => {
        const AllJobs = get(AllAtoms.longHaulOrdersAtom).filter(order=> order?.orderNumber!==undefined && order?.orderNumber!==null)
        const FilteredJobs = []
        AllJobs.forEach(Job=> {
            if(Job.orderStatus.status==='orderOngoing' && Job.orderStatus.internalStatus) {
                const LastStatus = Job?.driverStatusHistory?.find(status => status.status==='driverReachedDropoffLocation')
                const LastSubStatus = LastStatus?.statusJourney?.[LastStatus?.statusJourney.length-1]
                if(LastSubStatus?.status==='unloadingCompleted') {
                    Job = { ...Job, orderStatus: { ...Job.orderStatus, status: 'orderCompleted' } }
                }
            }
            FilteredJobs.push(Job)
        })
        const GroupedOrders = FilteredJobs.reduce((previous, current) => {
			if (!previous[current['orderNumber']]) { previous[current['orderNumber']] = [] }
			previous[current['orderNumber']].push(current)
			return previous
		}, {})
        const MappedJobs = Object.values(GroupedOrders).map(orderGroup => {
            // console.log(orderGroup)
            var index = orderGroup.length===1 ? 0 : orderGroup.findIndex(order=> !order.subOrderNumber)
            // console.log(orderGroup[index])
            if (index===-1){index = 0}
            return  orderGroup.length===1 ? 
                        orderGroup[index] : 
                        {
                            _id: orderGroup[index].orderNumber,
                            orderNumber: orderGroup[index].orderNumber, 
                            loading: orderGroup[index].loading,
                            dropOff: orderGroup[index].dropOff,
                            shipmentInfo: orderGroup[index].shipmentInfo,
                            subOrders: orderGroup.filter(order=> order.orderNumber && order.subOrderNumber).sort((a,b) => a.subOrderNumber?.localeCompare(b.subOrderNumber))
                        }
        })
        return MappedJobs
    }
})

export const importExportSingleOrderSelector = selectorFamily({
    key: 'importExportOrderSelector',
    get: (passed) => ({get}) => {
        if(passed.collection === 'imports') { return get(singleImportOrderSelector(passed.job)) } 
        else if(passed.collection === 'exports') { return get(singleExportOrderSelector(passed.job)) } 
    }
})

export const singleLongHaulOrderSelector = selectorFamily({
    key: 'singleLongHaulOrderSelector',
    get: (passedJob) => ({get}) => {
        let Job = {}
        if((typeof passedJob.job) === 'string') { Job = get(AllAtoms.longHaulOrdersAtom).find(job => job._id === passedJob.job) } 
        else { Job = passedJob }
        if(Job.orderStatus.status==='orderOngoing' && Job.orderStatus.internalStatus) {
            const LastStatus = Job?.driverStatusHistory?.find(status => status.status==='driverReachedDropoffLocation')
            const LastSubStatus = LastStatus?.statusJourney?.[LastStatus?.statusJourney.length-1]
            if(LastSubStatus?.status==='unloadingCompleted') {
                Job = { ...Job, orderStatus: { ...Job.orderStatus, status: 'orderCompleted' } }
            }
        }
        return  { ...Job }
    }
})

export const singleImportOrderSelector = selectorFamily({
    key: 'singleExportOrderSelector',
    get: (passedJob) => ({get}) => {
        let Job = {}
        if((typeof passedJob) === 'string') { Job = get(AllAtoms.importOrdersAtom).find(job => job._id === passedJob) } 
        else { Job = passedJob }
        const longHaulOrders = get(AllAtoms.longHaulOrdersAtom)
        if(Job?.linkedLongHaulOrder){
            const linked = longHaulOrders?.find(order=> order?._id === Job?.linkedLongHaulOrder)
            if (linked && linked.createdAt && parseInt(linked.createdAt) >= 1635501729438){
                Job = {...Job, driver: linked.driver, driverStatusHistory: linked.driverStatusHistory, linkedImportOrders: linked?.linkedImportOrders, subOrderNumber: linked?.subOrderNumber, vehicleInfo: linked?.vehicleInfo,  orderStatus: linked?.orderStatus, dropOff: linked?.dropOff}
            }
        }
        if(Job.orderStatus.status==='orderOngoing' && Job.orderStatus.internalStatus) {
            const LastStatus = Job?.driverStatusHistory?.find(status => status.status==='driverReachedDropoffLocation')
            const LastSubStatus = LastStatus?.statusJourney?.[LastStatus?.statusJourney.length-1]
            if(LastSubStatus?.status==='unloadingCompleted') {
                Job = { ...Job, orderStatus: { ...Job.orderStatus, status: 'orderCompleted' } }
            }
        }
        return  { ...Job }
    }
})

export const singleExportOrderSelector = selectorFamily({
    key: 'singleExportOrderSelector',
    get: (passedJob) => ({get}) => {
        let Job = {}
        if((typeof passedJob) === 'string') { Job = get(AllAtoms.exportOrdersAtom).find(job => job._id === passedJob) } 
        else { Job = passedJob }
        return  { ...Job }
    }
})

export const corporateDriversSelector = selector({
    key: 'corporateDriversSelector',
    get: ({get}) => {
        let DriversProfiles = []
        get(AllAtoms.driversAtom).filter(driver => driver.driverType==='corporate').forEach( driver => {
            const Profile = get(AllAtoms.profilesAtom).find(profile => (profile._id === driver.profileInfo || profile.profileId === driver.profileInfo))
            const Vehicle = get(AllAtoms.vehiclesAtom).find(vehicle => (vehicle._id === driver.vehicleId))
            Profile!==undefined && DriversProfiles.push({...driver, profile: Profile, vehicle: Vehicle})
        })
        return (DriversProfiles)
    }
})

export const suggestionsSelector = selectorFamily({
    key: 'suggestionsSelector',
    get: (collection) => ({get}) => {
        let LoadingData = []
        let DropoffData = []
        const TerminalData = []
        const ClearingAgents = []
        const Emails = []

        const AtomData = (collection === 'imports' || collection === 'bulk') ? get(AllAtoms.importOrdersAtom) : get(AllAtoms.exportOrdersAtom)

        const ActiveBusinessCodeData = get(AllAtoms.corporatesAtom).find(corporate => corporate.businessCode === get(AllAtoms.activeBusinessCodeAtom))
        const ActiveBusinessCodeAddresses = ActiveBusinessCodeData?.addresses
        const VicinitiesData = get(AllAtoms.vicinitiesAtom)
        if(collection==='imports') { 
            LoadingData = get(AllAtoms.portsAtom)
            DropoffData = ActiveBusinessCodeAddresses?.map(address => {
                const VicinityName = VicinitiesData.find(vicinity => vicinity._id===address.vicinity)?.vicinityName
                return {...address, additionalDetails: `${address.millName}, ${VicinityName}`}
            }) || []
            DropoffData.push({ formattedAddress: 'Other', millName: '', vicinity: '' })
        } else if (collection==='exports') { 
            LoadingData = ActiveBusinessCodeAddresses?.map(address => {
                const VicinityName = VicinitiesData.find(vicinity => vicinity._id===address.vicinity)?.vicinityName
                return {...address, additionalDetails: `${address.millName}, ${VicinityName}`}
            }) || []
            LoadingData.push({ formattedAddress: 'Other', millName: '', vicinity: '' })
            DropoffData = get(AllAtoms.portsAtom)
        } else if (collection==='bulk') {
            LoadingData = ActiveBusinessCodeAddresses?.map(address => {
                const VicinityName = VicinitiesData.find(vicinity => vicinity._id===address.vicinity)?.vicinityName
                return {...address, additionalDetails: `${address.millName}, ${VicinityName}`}
            }) || []
            LoadingData.push({ formattedAddress: 'Other', millName: '', vicinity: '' })
            DropoffData = ActiveBusinessCodeAddresses?.map(address => {
                const VicinityName = VicinitiesData.find(vicinity => vicinity._id===address.vicinity)?.vicinityName
                return {...address, additionalDetails: `${address.millName}, ${VicinityName}`}
            }) || []
            DropoffData.push({ formattedAddress: 'Other', millName: '', vicinity: '' })
        }

        AtomData.forEach( order => {
            // Object.values(order.loading).map(loading => LoadingData.push({...loading, ...(loading.contactPhone && {contactPhone: parseInt(loading.contactPhone.replace('+92', ''))})}))
            // Object.values(order.dropOff).map(dropoff => DropoffData.push({...dropoff, ...(dropoff.contactPhone && {contactPhone: parseInt(dropoff.contactPhone.replace('+92', ''))})}))
            
            if(collection==='imports') {
                const containerInfo = order.containerInfo
                const EmptyContainer = containerInfo && Object.values(containerInfo)?.[0]?.emptyContainer
                EmptyContainer && TerminalData.push(
                    EmptyContainer.contactPhone && EmptyContainer.contactPhone!=='' ? 
                    {...EmptyContainer, contactPhone: parseInt(EmptyContainer.contactPhone.toString().replace('+92', ''))} :
                    EmptyContainer
                )
            } else if (collection==='exports') {
                const containerInfo = order.containerInfo
                const ReleaseTerminal = containerInfo.containerDimensions ? containerInfo.releaseTerminal : Object.values(containerInfo)[0].releaseTerminal
                ReleaseTerminal && TerminalData.push(
                    ReleaseTerminal.contactPhone && ReleaseTerminal.contactPhone!=='' ? 
                    {...ReleaseTerminal, contactPhone: parseInt(ReleaseTerminal.contactPhone.toString().replace('+92', ''))} :
                    ReleaseTerminal
                )
            }
            
            if(check(order.clearingAgents)) {
                order.clearingAgents.forEach(clearingAgent => {
                    ClearingAgents.push({...clearingAgent, contactPhone: parseInt(clearingAgent.contactPhone.toString().replace('+92', ''))})
                })
            }

            if(check(order.clientEmail)) {
                if(typeof order.clientEmail==='object') { Emails.push(...order.clientEmail) } 
                else { Emails.push(order.clientEmail) }
            }
        })

        const UniqueLoadings = LoadingData?.length>0 ? LoadingData.filter((item, index, array) => array.findIndex(item2 => item2.formattedAddress === item.formattedAddress) === index ) : []
        const UniqueDropoffs = DropoffData?.length>0 ? DropoffData.filter((item, index, array) => array.findIndex(item2 => item2.formattedAddress === item.formattedAddress) === index) : []
        const UniqueTerminals = TerminalData.filter((item, index, array) => array.findIndex(item2 => item2.formattedAddress === item.formattedAddress) === index )
        const UniqueClearingAgents = ClearingAgents.filter((item, index, array) => array.findIndex(item2 => item2.contactPhone === item.contactPhone) === index )
        const UniqueEmails = Emails.filter((item, index, array) => array.findIndex(item2 => item2 === item) === index )

        return {loadingLocations: UniqueLoadings, dropoffLocations: UniqueDropoffs, termimals: UniqueTerminals, clearingAgents: UniqueClearingAgents, emails: UniqueEmails}
    }
})


export const dashboardLiveTrackingSelector = selector({
    key: 'dashboardLiveTrackingSelector',
    get: ({get}) => {
        const checkCondition = (driverStatusHistory) => {
            let flag = true
            let requiredStatus = driverStatusHistory?.find(driverStatus=> driverStatus?.status==='driverReachedDropoffLocation')
            if (requiredStatus){
                if (requiredStatus?.statusJourney?.find(subStatus=> subStatus?.status==='unloadingCompleted')){
                    flag = false
                }
            }
            return flag
        }
        const importTrackingOrders = get(AllAtoms.importOrdersAtom).filter(order => order.orderStatus.status==='orderOngoing' && checkCondition(order?.driverStatusHistory) && order.driver?.driverCurrentCoordinates).sort((a, b) => (a.subOrderNumber && b.subOrderNumber) ? (b.subOrderNumber.localeCompare(a.subOrderNumber, 'en', { numeric: true })) : (parseFloat(b.orderNumber) - parseFloat(a.orderNumber)))
        const exportTrackingOrders = get(AllAtoms.exportOrdersAtom).filter(order => order.orderStatus.status==='orderOngoing' && checkCondition(order?.driverStatusHistory) && order.driver?.driverCurrentCoordinates).sort((a, b) => (a.subOrderNumber && b.subOrderNumber) ? (b.subOrderNumber.localeCompare(a.subOrderNumber, 'en', { numeric: true })) : (parseFloat(b.orderNumber) - parseFloat(a.orderNumber)))
        const longHaulTrackingOrders = get(AllAtoms.longHaulOrdersAtom).filter(order => order.orderStatus.status==='orderOngoing' && checkCondition(order?.driverStatusHistory) && order.driver?.driverCurrentCoordinates).sort((a, b) => (a.subOrderNumber && b.subOrderNumber) ? (b.subOrderNumber.localeCompare(a.subOrderNumber, 'en', { numeric: true })) : (parseFloat(b.orderNumber) - parseFloat(a.orderNumber)))
        return [...importTrackingOrders, ...exportTrackingOrders, ...longHaulTrackingOrders]
    }
})