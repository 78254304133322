import { atom } from 'recoil'

export const loaderAtom = atom({ key: 'loaderAtom', default: true })

export const userAtom = atom({ key: 'userAtom', default: [] })
export const activeBusinessCodeAtom = atom({ key: 'activeBusinessCodeAtom', default: '' })

export const localOrdersAtom = atom({ key: 'localOrdersAtom', default: [] })
export const importOrdersAtom = atom({ key: 'importOrdersAtom', default: [] })
export const exportOrdersAtom = atom({ key: 'exportOrdersAtom', default: [] })
export const longHaulOrdersAtom = atom({ key: 'longHaulOrdersAtom', default: [] })
export const driversAtom = atom({ key: 'driversAtom', default: [] })
export const vehiclesAtom = atom({ key: 'vehiclesAtom', default: [] })
export const profilesAtom = atom({ key: 'profilesAtom', default: [] })
export const baseLocationsAtom = atom({ key: 'baseLocationsAtom', default: [] })
export const corporatesAtom = atom({ key: 'corporatesAtom', default: [] })
export const lanesAtom = atom({ key: 'lanesAtom', default: [] })
export const vicinitiesAtom = atom({ key: 'vicinitiesAtom', default: [] })
export const portsAtom = atom({ key: 'portsAtom', default: [] })