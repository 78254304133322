import React, { useState } from 'react';
import './Auth.css';
import Logo from './../../logo.png';
import {Form, Input, Button, notification, Spin} from 'antd';
import {FlagIcon} from './../../assets'
import { Auth , firestore, firebase } from '../../firebase/firebaseConfig';
import moment from 'moment'
import qs from 'qs';

const Register = (props) => { 
    const [loading, setLoading] = useState(false)
    const [registerClicked, setRegisterClicked] = useState(false)
    
    const queryParse = qs.parse(props.location.search)
    const [smsSent, setSMSSent] = useState(false)
    const [profileInfoFields, setProfileInfoFields] = useState(queryParse.profileInfoFields === "true" ? true :  false);
    const [user, setUser] = useState(queryParse.user !== undefined ? JSON.parse(queryParse.user) :  null);

    const onFinish = async (values) => {
        setLoading(true)
        if(!smsSent) {
            setRegisterClicked(true)
            let appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
            Auth.signInWithPhoneNumber(`+92${values.registeredNumber}`, appVerifier)
                .then( confirmationResult =>  {
                    // console.log(confirmationResult)
                    window.confirmationResult = confirmationResult
                    setSMSSent(true)
                })
                .catch(error => console.log(error))
            setLoading(false)
        } else {
            if(!profileInfoFields) {
                window.confirmationResult.confirm(values.verificationCode)
                    .then( async (result) => {
                        let userInfo = result.user;
                        setUser(result.user)
                        const response = await firestore.collection("profiles").doc(userInfo.uid).get();
                        if(response.data() === undefined) {
                            setProfileInfoFields(true)
                        } else {
                            const responseClient = await firestore.collection("clients").where('profileInfo','==',userInfo.uid).get();
                            const clientData = responseClient.docs.map((doc,index) => ({...doc.data()}));
                            if(clientData[0] !== undefined){
                                const document = await firestore.collection("clients").doc()
                                await firestore.collection("clients").doc(document.id)
                                                .set({
                                                    phoneNo: values.phoneNumber,
                                                    clientId: document.id,
                                                    profileInfo: userInfo.uid,
                                                    isVerified: true,
                                                }, {merge: true});
                            }
                        }
                        setLoading(false)
                    })
                    .catch ( error => {
                        notification['error']({
                            message: 'Verification Code',
                            description: error.message,
                            duration: 5,
                            onClick: () => { notification.close() }
                        })
                        setLoading(false)
                    })
                
            } else {
                await firestore.collection("profiles").doc(user.uid)
                                .set({
                                    _id: user.uid,
                                    cnic: null,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    phoneNumber: user.phoneNumber,
                                    joiningDate: moment().format('x'),
                                    profileImage: null,
                                    userType: 'client',
                                }, {merge: true});
                const document = firestore.collection('clients').doc();
                await firestore.collection("clients").doc(document.id)
                                .set({
                                    phoneNumber: user.phoneNumber,
                                    clientId: document.id,
                                    profileInfo: user.uid,
                                    isVerified: true,
                                    createdAt: moment().format('x')
                                }, {merge: true});
                setLoading(false)
                props.history.push('/signin');
            }
        }
    }
    
    return (
        <div className='AuthBackground'>
            <div className='AuthBox'>
                <Form onFinish={onFinish} >
                    <img className='BridgelinxLogo' src={Logo} alt=''/>
                    {   !smsSent ?
                        <>
                            <Form.Item
                                name={'registeredNumber'}
                                rules={[{ required: true, message: 'Phone Number is Required.' }]}
                            >
                                <div className='FlexRow'>
                                    <div className='AuthNumberPrefix'>
                                        <img className='FlagIcon' src={FlagIcon} alt=''/>
                                        <p className='CountryCode'>+92</p>
                                    </div>
                                    <Input className='InputAuthNumber' maxLength={10} type='number' placeholder={'3000000000'} />
                                </div>
                            </Form.Item>
                            {!registerClicked && <Button className='AuthSubmitButton' htmlType="submit">Register</Button>}
                            <div id="recaptcha-container"/>
                        </> :
                        ( !profileInfoFields ? 
                            <>
                                <Form.Item
                                    name={'verificationCode'}
                                    rules={[{ required: true, message: 'Verification Code is Required.' }]}
                                >
                                    <div className='FlexRow'>
                                        <Input className='VerificationCode' type='number' placeholder={'000000'} />
                                    </div>
                                </Form.Item>
                                <Button className='AuthSubmitButton' htmlType="submit">Verfiy</Button>
                            </>
                            : 
                            <>
                                <Form.Item
                                    name={'firstName'}
                                    rules={[{ required: true, message: 'First Name is Required' }]}
                                >
                                    <div className='FlexRow'>
                                        <Input className='InputName' placeholder={'First Name'} />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name={'lastName'}
                                    rules={[{ required: true, message: 'Last Name is Required' }]}
                                >
                                    <div className='FlexRow'>
                                        <Input className='InputName' placeholder={'Last Name'} />
                                    </div>
                                </Form.Item>
                                <Button className='AuthSubmitButton' htmlType="submit">Submit</Button>
                            </>
                        )
                    }
                    {!registerClicked && 
                        <p className='Shift'>Already have an account?
                            <span className='ShiftSpan' onClick={() => props.history.push('/signin')}> Login </span>
                        </p>
                    }
                    {loading && <Spin className='AuthBox Loading' size="large" tip="Loading" />}
                </Form>
            </div>
        </div>
    )
}
export default Register; 