import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'antd'

import { FormRender } from './FormRenderer';
import { CardHeader, VehiclesEnum, VehicleTypeEnum} from '../helpers/Functions'

import '../App.css'

const StepsModal = (props) => {
    const { 
        data,
        setdata,
        // additionalData,
        // targetIndex,
        type,
        action,
        // render,
        totalSteps,
        collection
    } = props
    const [form] = Form.useForm()
    const [currentStep, setCurrentStep] = useState(0)
    const [stepsData, setStepsData] = useState([])
    const [modalEnum, setModalEnum] = useState([])

    useEffect(()=> {
        if(props.viewModal===true) {
            let InitialValues = {}
            data.map(indexData => InitialValues={...InitialValues, ...indexData})
            // console.log({InitialValues})
            form.setFieldsValue(InitialValues)
            setStepsData([...data])
        }

        let FilteredEnum = {}
        Object.values(VehicleTypeEnum).forEach(vehicle => {
            if(collection==='bulk' && ['superx', 'xl20ft', 'xl40ft'].includes(vehicle.key)) {
                FilteredEnum = {...FilteredEnum, [vehicle.key]: {...vehicle}}
            } else if (collection==='orders') {
                FilteredEnum = {...FilteredEnum, [vehicle.key]: {...vehicle}}
            } 
        })
        setModalEnum (FilteredEnum)
        
    }, [props.viewModal]) // eslint-disable-line

    const StepMaterial = [
        {
            title: 'Select Vehicle Type',
            description: 'Select Vehicle Type',
            content:    
                FormRender(
                    {
                        type: 'radioGroup',
                        name: 'vehicleType',
                        required: true,
                        message: 'Please select a vehicle type.',
                        dataObject: modalEnum,
                        radioButtonView: true
                    },
                    'edit',
                    'vehicleType'
                )
        },
        {
            title: 'Select Vehicle Sub-Type',
            description: 'Please select subtype of vehicle',
            content:    
                form.getFieldValue('vehicleType') && VehiclesEnum[form.getFieldValue('vehicleType')] && FormRender(
                    {
                        type: 'radioGroup',
                        name: 'vehicleSubtype',
                        required: true,
                        message: 'Please select a vehicle sub-type.',
                        dataObject: Object.values(VehiclesEnum[form.getFieldValue('vehicleType')]),
                        radioButtonView: true
                    },
                    'edit',
                    'vehicleType'
                )
        }
    ]
    
    const onFinish = (values) => {
        const DataCopy = [...stepsData]
        let NewData = {}
        if(action==='add') { NewData = {...values} }
        else { NewData = {...DataCopy[currentStep], ...values} }
        DataCopy.splice(currentStep, 1, NewData)
        if(currentStep<totalSteps-1 && VehiclesEnum[form.getFieldValue('vehicleType')] && DataCopy[0].vehicleType!=='superx') {
            if(action==='edit' && DataCopy[0].vehicleType !== stepsData[0].vehicleType) {
                // console.log(DataCopy[0], stepsData[0])
                form.setFieldsValue({'vehicleSubtype': ''})
            }
            setStepsData(DataCopy)
            setCurrentStep(prevState => prevState + 1)
        } else {
            if(type==='vehicle' && DataCopy[0].vehicleType==='superx') {
                DataCopy.splice(1,1)
            }
            setdata(DataCopy)
            onCancel()
        }
    }

    const onCancel = async () => {
        props.setViewModal(false)
        setCurrentStep(0)
        form.resetFields()
    }

    const ModalFooter = () => (
		<>
            <Button className='Button ModalFooterButton CancelButton' onClick={() => onCancel()}> CANCEL </Button>
            {   currentStep!==totalSteps-1 ?
                <Button className='Button ModalFooterButton PrimaryButton ModalFooterButton' onClick={() => form.submit()}> NEXT </Button> : 
                <Button className='Button ModalFooterButton SubmitButton' onClick={() => form.submit()}> SUBMIT </Button>
            }
        </>
    )

    return (
        <Modal
            visible= {props.viewModal}
            title= {CardHeader(StepMaterial[currentStep].title, StepMaterial[currentStep].description)}
            footer={<ModalFooter/>}
            closable={false}
            className='SquareModal'
        >
            <div className='Padding'>
                <Form
                    layout={'vertical'}
                    form={form}
                    onFinish={onFinish}
                >
                    {StepMaterial[currentStep].content}
                </Form>
            </div>
        </Modal>
    )
}

export default StepsModal