import { Spin, Divider, notification} from 'antd'
import Sprite from '../assets/icons/Sprite.svg'

/* ====================================== GLOBAL FUNCTIONS ====================================== */
export const check = (Param) => Param!==null && Param!==undefined
export const checkArray = (Param) => !!Param && Param.constructor === Array
export const ReadableString = (string) => string.replace(/([A-Z])/g, " $1")

export const CurrentCollection = (path) => path.split('/')[1]
export const CurrentPageId = (path) => path.split('/')[path.split('/').length-2]
export const CurrentPageAction = (path) => path.split('/')[path.split('/').length-1]

export const Loader = () => <Spin className='Spinner' tip={<p className='SpinnerDescription'>Loading...</p>} size='large' />

/* =========================================== ENUMS =========================================== */
export const SidebarEnum = {
    // dashboard: { key: 'dashboard', icon: '#Dashboard', name: 'Dashboard'},
    orders: { key: 'orders', icon: '#LocalOrders', name: 'Local Orders'},
    // imports: { key: 'imports', icon: '#ImportOrders', name: 'Import Orders'},
    // exports: { key: 'exports', icon: '#ExportOrders', name: 'Export Orders'},
    // bulk: { key: 'bulk', icon: '#LongHaulOrders', name: 'Bulk Orders'},
    // drivers: { key: 'drivers', icon: '#Drivers', name: 'Drivers'},
}

export const LocalOrderStatusEnum = {
    jobInit: {text: 'In Process', background: '#E5F4FF', color: '#008EFF'},
    jobCritical: {text: 'In Process', background: '#E5F4FF', color: '#008EFF'},
    jobCriticalSuper: {text: 'In Process', background: '#E5F4FF', color: '#008EFF'},
    jobScheduledStatic: {text: 'In Process', background: '#E5F4FF', color: '#008EFF'},
    jobScheduled: {text: 'Scheduled', background: '#F2F2F2', color: '#808080'},
    jobUnscheduled: {text: 'Scheduled', background: '#F2F2F2', color: '#808080'},
    jobOngoing: {text: 'Ongoing', background: '#FFF4E5', color: '#FF9500'},
    jobFinished: {text: 'Finished', background: '#E5FFE7', color: '#40BF4A'},
    jobCancelled: {text: 'Cancelled', background: '#FFE5EA', color: '#FF1500'},
}

export const ExportOrderStatusEnum = {
    orderPending: {text: 'Processing', background: '#F2F2F2', color: '#808080'},
    orderProcessing: {text: 'Processing', background: '#F2F2F2', color: '#808080'},
    orderConfirmed: {text: 'Confirmed', background: '#E5F4FF', color: '#008EFF'},
    orderOngoing: {text: 'Ongoing', background: '#FFF4E5', color: '#FF9500'},
    orderCompleted: {text: 'Finished', background: '#E5FFE7', color: '#40BF4A'},
    orderCancelled: {text: 'Cancelled', background: '#FFE5EA', color: '#FF1500'},
}

export const VehiclesEnum = {
    small: {
        open: { key:'open', tonnage: '1,100 Kg', dimensions: '6 Ft. Long', icon: '#low_open', long: 'Open' },
        closed: { key:'closed', tonnage: '1,100 Kg', dimensions: '6 Ft. Long', icon: '#low_closed', long: 'Closed' }
    },
    medium: {
        open: { key:'open', tonnage: '2,000 Kg', dimensions: '9 Ft. Long', icon: '#medium_open', long: 'Open' },
        closed: { key:'closed', tonnage: '2,000 Kg', dimensions: '9 Ft. Long', icon: '#medium_closed', long: 'Closed' }
    },
    large: {
        open: { key:'open', tonnage: '10,000 Kg', dimensions: '16 Ft. Long', icon: '#high_open', long: 'Open' },
        closed: { key:'closed', tonnage: '10,000 Kg', dimensions: '16 Ft. Long', icon: '#high_closed', long: 'Closed' }
    },
    superx: {
        key:'superx', icon: '#superx', long: 'Super X' ,tonnage: 10000, dimensions: '16 Ft. Long'
    },
    xl20ft: {
        flatbed: { key:'flatbed', icon: '#20ft_flatbed', long: 'Flatbed', tonnage: 10000 ,dimensions: '16 Ft. Long' },
        framed: { key:'framed', icon: '#20ft_framed', long: 'Framed', tonnage: 10000, dimensions: '16 Ft. Long' },
        containerized: { key:'containerized', icon: '#20ft_containerized', long: 'Containerized' ,tonnage: 10000, dimensions: '16 Ft. Long'}
    },
    xl40ft: {
        flatbed: { key:'flatbed', icon: '#40ft_flatbed', long: 'Flatbed', tonnage: 10000, dimensions: '16 Ft. Long' },
        framed: { key:'framed', icon: '#40ft_framed', long: 'Framed', tonnage: 10000, dimensions: '16 Ft. Long' },
        containerized: {key:'containerized', icon: '#40ft_containerized', long: 'Containerized', tonnage: 10000, dimensions: '16 Ft. Long' }
    }
}

export const VehicleTypeEnum = {
    small: {key: 'small', text: 'Low', long: 'Low Capacity', icon: '#low_closed'},
    medium: {key: 'medium', text: 'Medium', long: 'Medium Capacity', icon: '#medium_closed'},
    large: {key: 'large', text: 'High', long: 'High Capacity', icon: '#high_closed'},
    superx: {key: 'superx', text: 'Superx', long: 'Super Capacity', icon: '#superx'},
    xl20ft: {key: 'xl20ft', text: '20 Ft', long: '20 Feet', icon: '#20ft_containerized'},
    xl40ft: {key: 'xl40ft', text: '40 Ft', long: '40 Feet', icon: '#40ft_containerized'},
}

export const VehicleSubtypeEnum = {
    open: {key: 'open', text: 'Open'},
    closed: {key: 'closed', text: 'Closed'},
    flatbed: {key: 'flatbed', text: 'Flatbed'},
    framed: {key: 'framed', text: 'Framed'},
    containerized: {key: 'containerized', text: 'Containerized'},
}

export const ContainerDimensionsEnum = {
    xl20ft: {key: 'xl20ft', text: '20 Ft', long: '20 Feet', icon: '#xl20ft'},
    xl40ft: {key: 'xl40ft', text: '40 Ft', long: '40 Feet', icon: '#xl40ft'},
    xl40ftHC: {key: 'xl40ftHC', text: '40 Ft High Cube', long: '40 Feet - High Cube', icon: '#xl40ftHC'},
    xl40ftOT: {key: 'xl40ftOT', text: '40 Ft Open Top', long: '40 Feet - Open Top', icon: '#xl40ftOT'},
    xl2020ft: {key: 'xl2020ft', text: 'Double 20 Ft', long: 'Double 20 Feet', icon: '#xl2020ft'},
    other: {key: 'other', text: 'Other', long: 'Other', icon: '#other'},
}

export const OrderCategoryEnum = {
    normal: {key: 'normal', text: 'Direct Seal'},
    shifting: {key: 'shifting', text: 'Shifting'},
    destuffing: {key: 'destuffing', text: 'Destuffing'},
}

/* ========================================= MAP STYLE ========================================= */
export const SilverMapStyles = [
    { "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }] },
    // { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
    { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] },
    { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] },
    { "featureType": "administrative", "elementType": "geometry", "stylers": [{ "visibility": "off" }] },
    { "featureType": "administrative.land_parcel", "stylers": [{ "visibility": "off" }] },
    { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] },
    { "featureType": "administrative.neighborhood", "stylers": [{ "visibility": "off" }] },
    { "featureType": "poi", "stylers": [{ "visibility": "off" }] },
    { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] },
    { "featureType": "poi", "elementType": "labels.text", "stylers": [{ "visibility": "off" }] },
    { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
    { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] },
    { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] },
    { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] },
    { "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "off" }] },
    { "featureType": "road", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
    { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
    { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] },
    { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] },
    { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] },
    { "featureType": "transit", "stylers": [{ "visibility": "off" }] },
    { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] },
    { "featureType": "transit.station", "stylers": [{ "visibility": "off" }] },
    { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] },
    { "featureType": "water", "stylers": [{ "visibility": "on" }] },
    { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#c9c9c9" }] },
    { "featureType": "water", "elementType": "labels.text", "stylers": [{ "visibility": "off" }] },
    { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }
]

/* ======================================== CARD DESIGN ======================================== */
export const CardHeader = (Title, Description) => (
    <div className={`FullHeight FlexRow ${Description ? 'Height72' : 'Height60'}`}>
        <div className='FlexColumn'>
            <p>{Title}</p>
            {Description && <p className='TitleDescription'>{Description}</p>}
        </div>
    </div>
)

export const NumberedCardHeader = (Title, Description, Number) => (
    <div className='FullWidthHeight FlexRow Height72'>
        <p className='BlackColor'>{`Step ${Number}`}</p>
        <Divider className='FullHeight MarginLeft MarginRight' type="vertical"/>
        <div className='FullWidth FlexColumn'>
            <p>{Title}</p>
            <p className='TitleDescription FullWidth OverflowEllipsis'>{Description}</p>
        </div>
    </div>
)


export const LocalOrderStatus = (status) => (
    LocalOrderStatusEnum[status] &&
    <div className='OrderStatus FlexRowBox' style={{ backgroundColor: LocalOrderStatusEnum[status].background, color: LocalOrderStatusEnum[status].color }}>
        <p style={{fontSize: '12px'}}>{LocalOrderStatusEnum[status].text}</p>
    </div>
)

export const ExportOrderStatus = (status) => (
    ExportOrderStatusEnum[status] && 
    <div className='OrderStatus FlexRowBox' style={{ backgroundColor: ExportOrderStatusEnum[status].background, color: ExportOrderStatusEnum[status].color }}>
        <p style={{fontSize: '12px'}}>{ExportOrderStatusEnum[status].text}</p>
    </div>
)

export const DriverJobStatus = (currentJobStatus) => (
    <div className='OrderStatus FlexRowBox' style={{ backgroundColor: currentJobStatus ? '#FFF4E5' : '#E5FFE7', color: currentJobStatus ? '#FF9500' : '#40BF4A' }}>
        <p style={{fontSize: '12px'}}>{currentJobStatus ? 'On Job' : 'Available'}</p>
    </div>
)



export const IconDescription = (icon, description, classes, iconClasses) => (
    <div className={`FlexRow ${classes ? classes : ''}`}>
        <svg className={`${iconClasses ? iconClasses : 'MiniIcon HalfMarginRight'}`}><use href={Sprite + icon}/></svg>
        <p className='RegularFont'>{description}</p>
    </div>
)


// FCM Notifications to Client, Carriers and Drivers
export const sendBusinessCodeNotification = (BusinessCode, Title, Message, JobID, OrderType) => {
    return fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendNotificationToBusinessCodeClient`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({
            businessCode: BusinessCode,
            message: {
                "notification": { "title": Title, "body": Message },
                "data": { "click_action": "FLUTTER_NOTIFICATION_CLICK", "jobId": JobID, "orderType": OrderType}
            }
        })
    })
}


export const sendDriverNotification = (Title, Message, JobID, DriverId) => {
    return fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendFCM`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({
            driverId: DriverId,
            message: {
                "notification": { "title": Title, "body": Message },
                "data": { "click_action": "FLUTTER_NOTIFICATION_CLICK", "jobId": JobID }
            }
        })
    })
}

export const PortalNotification = (Type, Title, Message) => {
    return notification[Type]({
        message: Message,
        description: Title,
        duration: 5,
        onClick: () => { notification.close() }
    })
}


export function BoundsZoomLevel(bounds) {
    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    function latRad(lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(450, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(450, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

export const PakistanPoints = [
    {lat: 35.4582433, lng: 80.3632033}, //East
    {lat: 29.8583772, lng: 60.8725798}, //West
    {lat: 37.0435343, lng: 74.6750703}, //North
    {lat: 23.7250199, lng: 68.0817303}, //South
]

export function range(start, end) {
    var array = new Array(end - start + 1);
    for (let i = 0; i < end - start + 1; i++) array[i] = start + i;
    return array
}