import React, { useState } from 'react';
import { Button } from 'antd'
import { check, VehicleTypeEnum, VehicleSubtypeEnum, ContainerDimensionsEnum } from '../helpers/Functions'

import Sprite from '../assets/icons/Sprite.svg'

import FormModal from './FormModal'
import StepsModal from './StepsModal'
import ContainerModal from './ContainerModal'

const ModalButton = (props) => {
	const { buttonText, data, targetIndex, type, action, showLeftIcon, showRightIcon, centerText, showSummary } = props

    const [viewModal, setViewModal] = useState(false)

    const ButtonSchema = {
        local_pickup: {
            icon: '#Pin',
            buttonSummary: type==='local_pickup' && data[targetIndex]?.formattedAddress,
            add: {title:'Add Pickup Location', style: 'AddModalButton PrimaryModalButton'},
            edit: {title:'Loading Location', style: 'EditModalButton'},
        },
        local_dropoff: {
            icon: '#Pin',
            buttonSummary: type==='local_dropoff' &&  data[targetIndex]?.formattedAddress,
            add: {title:'Add Dropoff Location', style: 'AddModalButton SecondaryModalButton'},
            edit: {title:'Dropoff Location', style: 'EditModalButton'},
        },
        import_loading: {
            icon: '#Pin',
            buttonSummary: type==='import_loading' &&  data[targetIndex]?.formattedAddress,
            add: {title:'Add Port', style: 'AddModalButton PrimaryModalButton'},
            edit: {title:'Port', style: 'EditModalButton'},
        },
        import_dropoff: {
            icon: '#Pin',
            buttonSummary: type==='import_dropoff' &&  data[targetIndex]?.formattedAddress,
            add: {title:'Add Dropoff Location', style: 'AddModalButton SecondaryModalButton'},
            edit: {title:'Dropoff Location', style: 'EditModalButton'},
        },
        export_loading: {
            icon: '#Pin',
            buttonSummary: type==='export_loading' &&  data[targetIndex]?.formattedAddress,
            add: {title:'Add Loading Location', style: 'AddModalButton PrimaryModalButton'},
            edit: {title:'Loading Location', style: 'EditModalButton'},
        },
        export_dropoff: {
            icon: '#Pin',
            buttonSummary: type==='export_dropoff' &&  data[targetIndex]?.formattedAddress,
            add: {title:'Add Dropoff Location', style: 'AddModalButton SecondaryModalButton'},
            edit: {title:'Dropoff Location', style: 'EditModalButton'},
        },
        release_terminal: {
            icon: '#Pin',
            buttonSummary: type==='release_terminal' &&  data[targetIndex]?.formattedAddress,
            add: {title:'Add Release Terminal Location', style: 'AddModalButton PrimaryModalButton'},
            edit: {title:'Dropoff Location', style: 'EditModalButton'},
        },
        clearingAgent: {
            icon: '#Avatar',
            buttonSummary: type==='clearingAgent' && `${ data[targetIndex]?.contactName} - +92${data[targetIndex]?.contactPhone}`,
            add: {title:'Add Clearing Agents', style: 'AddModalButton PrimaryModalButton'},
            edit: {title:'Clearing Agent', style: 'EditModalButton'},
        },
        vehicle: {
            icon: '#Container',
            buttonSummary: type==='vehicle' && `${VehicleTypeEnum[data[0]?.vehicleType]?.text} ${data[1]?.vehicleSubtype?.toString().length>0 ? `- ${VehicleSubtypeEnum[data[1]?.vehicleSubtype]?.text}` : ''}`,
            add: {title:'Select Vehicle Category', style: 'AddModalButton PrimaryModalButton'},
            edit: {title:'Edit Vehicle Category', style: 'EditModalButton'},
        },
        containerDimensions: {
            icon: '#Container',
            buttonSummary: type==='containerDimensions' &&  `${ContainerDimensionsEnum[data[targetIndex]?.containerDimensions]?.long}`,
            add: {title:'Select Container Dimensions', style: 'AddModalButton PrimaryModalButton'},
            edit: {title:'Edit Container Dimensions', style: 'EditModalButton'},
        },
        containerDetails: {
            icon: '#Container',
            buttonSummary: type==='containerDetails' &&  `${ContainerDimensionsEnum[data[targetIndex]?.containerDimensions]?.long} ${data[targetIndex]?.containerNumber ?  `/ ${data[targetIndex]?.containerNumber}`: ""} ${data[targetIndex]?.containerNumber2 ? '& ' + data[targetIndex]?.containerNumber2 : ''}`,
            add: {title:'Add Container Details', style: 'AddModalButton PrimaryModalButton'},
            edit: {title:'Edit Container Details', style: 'EditModalButton'},
        }
    }

	return ( <>
        <Button className={`FullWidth Button ModalButton ${ButtonSchema[type][action].style}`} onClick={()=> setViewModal(true)}>
            <div className='FlexRow FullWidth'>
                {   !check(showLeftIcon) &&
                    <svg className='MiniIcon HalfMarginRight'><use href={Sprite + ButtonSchema[type].icon}/></svg>
                }
                <div className={`FlexColumn ${centerText ? 'FullWidth CenterAlign' : 'LocationSummary LeftAlign '}`}>
                    <p>{buttonText ? buttonText : ButtonSchema[type][action].title}</p>
                    {   action==='edit' && showSummary!==false && 
                        <p className='RegularFont OverflowEllipsis FullWidth'>
                            {ButtonSchema[type].buttonSummary}
                        </p>
                    }
                </div>
                {   !check(showRightIcon) &&
                    <svg className='MiniIcon HalfMarginLeft'><use href={action==='add' ? Sprite+'#Add' : Sprite+'#Edit'}/></svg>
                }
            </div>
        </Button>
        {   type!=='vehicle' && type!=='containerDetails' ?
            <FormModal
                {...props}
                title = {ButtonSchema[type]?.[action]?.title}
                description = {ButtonSchema[type]?.[action]?.description}
                viewModal = {viewModal}
                setViewModal={setViewModal}
            /> :
            type==='vehicle' ?
            <StepsModal
                {...props}
                render= {ButtonSchema[type]?.modalRender}
                viewModal = {viewModal}
                setViewModal={setViewModal}
                totalSteps={2}
                collection = {props.collection}
            /> : 
            <ContainerModal
                {...props}
                viewModal = {viewModal}
                setViewModal={setViewModal}
                totalSteps={2}
                collection = {props.collection}
            /> 
        }  
    </>)
}

export default ModalButton